import { useContext } from "react"

//MUI
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, Toolbar, Typography } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"

// CUSTOM
import logo from "../../../CWATImages/dasadec-logo.png"
import AppContext from "../../../AppContext"

const CustomToolbar = ({ open, handleDrawerOpen, getLocalStorageData }) => {
  const context = useContext(AppContext)
  const { setSelectedOrg, allOrganizations, allFiscalCycles } = context

  const handleOrgChange = (e) => {
    localStorage.setItem("ORG", JSON.stringify(e.target.value))
    setSelectedOrg(e.target.value)
  }

  const getOptions = (arr, value) => {
    return arr.map((item) => {
      return (
        <MenuItem
          sx={{ minWidth: "150px" }}
          value={item}
        >
          {item[value]}
        </MenuItem>
      )
    })
  }

  return (
    <Toolbar>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        sx={[
          {
            marginRight: 5,
          },
          open && { display: "none" },
        ]}
      >
        <MenuIcon />
      </IconButton>
      {/* CWAT LOGO */}

      <img
        src={logo}
        style={{ height: "50px", marginRight: "10px" }}
      />
      {/* HEADER/SUBHEADER */}
      <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <Box
          className="flexColumn"
          sx={{ justifyContent: "center" }}
        >
          <Typography
            variant="pageHeader"
            className="titleTest"
          >
            Common Workforce Analysis Tool (CWAT)
          </Typography>
          <Typography variant="pageSubheader">{getLocalStorageData()?.name}</Typography>
          {/* - - - */}
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", maxHeight: "50px" }}>
          <FormControl
            sx={{
              display: "flex",
              minWidth: "150px",
              marginRight: "15px",
              maxHeight: "50px",
              color: "white",
            }}
          >
            <InputLabel sx={{ color: "white" }}>Organization</InputLabel>
            <Select
              label="Organization"
              variant="outlined"
              value={getLocalStorageData()}
              renderValue={(params) => params.name}
              onChange={handleOrgChange}
              autoWidth
              sx={{
                color: "white",
                ".MuiSelect-select": {
                  maxHeight: "100%",
                  padding: "7px 15px",
                },
                ".MuiInputBase-root": {
                  borderColor: "white",
                },
              }}
            >
              {getOptions(allOrganizations, "name")}
            </Select>
          </FormControl>
          {allFiscalCycles.length > 1 && (
            <FormControl
              sx={{
                display: "flex",
                minWidth: "150px",
                marginRight: "15px",
                maxHeight: "50px",
                color: "white",
              }}
            >
              <InputLabel sx={{ color: "white" }}>Fiscal Cycle</InputLabel>
              <Select
                label="Fiscal Cycle"
                variant="outlined"
                value={JSON.parse(localStorage.getItem("FY"))}
                renderValue={(params) => params.fiscalYear}
                onChange={(e) => localStorage.setItem("FY", JSON.stringify(e.target.value))}
                autoWidth
                sx={{
                  color: "white",
                  ".MuiSelect-select": {
                    maxHeight: "100%",
                    padding: "7px 15px",
                  },
                  ".MuiInputBase-root": {
                    borderColor: "white",
                  },
                }}
              >
                {getOptions(allFiscalCycles, "fiscalYear")}
              </Select>
            </FormControl>
          )}
          <Button
            variant="outlined"
            className="buttonTest"
            onClick={() => window.location.replace(ApplicationPaths.IdentityLogoutPath)}
            sx={{ color: "white", borderColor: "white" }}
          >
            Logout
          </Button>
        </Box>
      </Box>
    </Toolbar>
  )
}

export default CustomToolbar
