import { useState, useEffect, useContext, useRef } from "react"
import { useLocation } from "react-router-dom"

// Data
import { apiCalls } from "../../DataService"

// Context
import GlobalContext from "../../reducers/GlobalContext"

// Libraries
import { v4 as uuidv4 } from "uuid"
import { Box, Button, Card, CardHeader, Checkbox, CircularProgress, Stack, Typography } from "@mui/material"
import { DataGrid, useGridApiRef } from "@mui/x-data-grid"
import Papa from "papaparse"

// Custom
import orgIconPlaceholder from "../../../CWATImages/OrganizationIconPlaceholder.png"
import { logosArr } from "../../Organizations/OrganizationPOCs"
import { TableControls } from "../TableControls"
import { NewPEO_PMRecord } from "./NewPEO_PMRecord"
import { SubmitRFIWarningModal } from "../SubmitRFIWarningModal"
import SnackbarMessages from "../../SnackbarMessages"
import RFIGridToolbar from "../RFIGridToolbar"
import { RequestResubmissionWarningModal } from "../RequestResubmissionWarningModal"
import { PEOPMSATMOSaveChangesWarningModal } from "./PEOPMSATMOSaveChangesWarningModal"
import { SaveResultsDialog } from "./SaveResultsDialog"
import { fetchRFIDashboardObjectsFromDB, getRfiRecordCountMapping } from "../../DashboardMenu"
import UploadProgressModal from "../UploadProgressModal"

// Vars
import PEO_PM_Utils from "./PEO_PM_Utils"
import {
  CSVUploadProgressMessageTypes,
  SelectedTabToRFIMapping,
  SubmitStatus,
  UploadSubmitEnabledStatuses,
  populateGlobalContext,
} from "../../../Utils"

// Styles
import "./css/PEO_PM.css"
import AppContext from "../../../AppContext"
import CustomDataGrid from "../../elements/custom-grid/CustomDataGrid"
import { Help } from "@mui/icons-material"
import RfiStatusChip from "../../custom/RfiStatusChip"
import Base from "../../elements/layout/Base"
// import { DataGridPremium } from "@mui/x-data-grid-premium"

const PEO_PM = () => {
  const apiRef = useGridApiRef()
  const [loading, setLoading] = useState(true)

  const location = useLocation()
  const fileSelector = useRef(null)
  const renderingDBRows = useRef(true)
  const csvLinkRef = useRef()
  const numTotalTableRows = useRef(0)
  const pageSize = useRef(100)
  const pageNumber = useRef(0)
  const globalContext = useContext(GlobalContext)

  const [results, setResults] = useState("")

  const [canSavePEOPMChanges, setCanSavePEOPMChanges] = useState(false)
  const [PEOPMDataSubmitStatus, setPEOPMDataSubmitStatus] = useState(SubmitStatus.NOT_GENERATED)
  const [canSubmitRFI, setCanSubmitRFI] = useState(false)
  const [canExportRFIData, setCanExportRFIData] = useState(false)

  const [MUISortModel, setMUISortModel] = useState([])
  const [MUIFilter, setMUIFilter] = useState({})

  const [csvData, setCsvData] = useState([])
  const [saveChangesSuccessful, setSaveChangesSuccessful] = useState(false)
  const [saveChangesResults, setSaveChangesResults] = useState([])
  const [showSaveChangesListModal, setShowSaveChangesListModal] = useState(false)

  const [allPEOPMTableRows, setAllPEOPMTableRows] = useState([])
  const [selectedPEOPMTableRows, setSelectedPEOPMDataTableRows] = useState([])
  const [uploadedTableRows, setUploadedTableRows] = useState([])
  const [unassociatedRows, setUnassociatedRows] = useState([])

  const [showSnackbarSuccess, setShowSnackbarSuccess] = useState(false)
  const [showSnackbarError, setShowSnackbarError] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [showSaveChangesModal, setShowSaveChangesModal] = useState(false)
  const [showSubmitRFIWarningModal, setShowSubmitRFIWarningModal] = useState(false)
  const [showRequestResubmissionWarningModal, setShowRequestResubmissionWarningModal] = useState(false)
  const [showBusyDialog, setShowBusyDialog] = useState(false)
  const [showNewPEOPMDialog, setShowNewPEOPMDialog] = useState(false)
  const [selectedRFI, setSelectedRFI] = useState({})
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false)
  const [loadingTitle, setLoadingTitle] = useState("")

  const [disclaimerRFIModalOpen, setDisclaimerRFIModalOpen] = useState(false)

  const {
    setSelectedOrgContext,
    activeFiscalCycle,
    userObj,
    allOrgs,
    rfiOrganizationsMapping,
    rfiRecordCountMapping,
    setRfiRecordCountMappingContext,
    setRfiDashboardOrgRfiMappingContext,
    setSelectedTabRfiMappingKeyContext,
  } = globalContext
  const context = useContext(AppContext)
  let { selectedOrg } = context

  let initialState = {
    columns: {
      columnVisibilityModel: { Id: false },
    },
  }

  const PEO_PM_COLS = [
    {
      field: "Id",
      headerName: "Id",
      hide: true,
      headerClassName: "--RFI-grid-header",
      flex: 1,
    },
    {
      field: PEO_PM_Utils.PEO_PM_TableFields.USER_CASE_ID,
      headerName: "User Case ID",
      flex: 1,
      // minWidth: 150,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: PEO_PM_Utils.PEO_PM_TableFields.LINE_ID,
      headerName: "Line ID",
      headerClassName: "--RFI-grid-header",
      flex: 0.5,
    },
    {
      field: PEO_PM_Utils.PEO_PM_TableFields.MASL,
      headerName: "MASL",
      flex: 1,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: PEO_PM_Utils.PEO_PM_TableFields.MASL_DESCRIPTION,
      headerName: "MASL Description",
      flex: 3,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: PEO_PM_Utils.PEO_PM_TableFields.GENERIC_DESCRIPTION,
      headerName: "Generic Description",
      flex: 3,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: PEO_PM_Utils.PEO_PM_TableFields.PM,
      headerName: "PM",
      flex: 2,
      headerClassName: "--RFI-grid-header",
    },
    {
      field: PEO_PM_Utils.PEO_PM_TableFields.VALIDATED_WORKLOADS,
      headerName: "Associated",
      flex: 1,
      headerClassName: "--RFI-grid-header center-header",
    },
    {
      field: PEO_PM_Utils.PEO_PM_TableFields.REMOVE,
      headerName: "Remove",
      flex: 1,
      editable: true,
      headerClassName: "--RFI-grid-header center-header",

      renderCell: (params) => {
        if (!params?.row) return false
        return (
          <Checkbox
            checked={params.row.Remove && params.row.Remove.length > 0}
            onChange={() => toggleRemovePeoPmRecord(params)}
          />
        )
      },

      valueGetter: (params) => {
        return !!params?.row?.Remove
      },
    },
  ]

  // EFFECTS
  useEffect(() => {
    populateGlobalContext(globalContext)
  }, [])

  useEffect(() => {
    if (location.state?.organization?.id) {
      handleLocationChange()
    }
  }, [location])

  useEffect(() => {
    if (loading && selectedPEOPMTableRows) {
      setLoading(false)
    }
  }, [selectedPEOPMTableRows])

  useEffect(() => {
    selectedOrg?.name && setMappingKeyContext()
  }, [selectedOrg])

  useEffect(() => {
    if (selectedOrg?.id && selectedOrg?.dsamsPrepActDataAssociationsList) {
      renderingDBRows.current = true
      setShowBusyDialog(true)
      getSelectedRFI()
      // TODO - set allPEOPMTableRows
      setAllPEOPMTableRows(() => {
        if (selectedOrg?.name === "USACE") {
          return selectedOrg?.dsamsPrepActDataAssociationsList
            .concat(selectedOrg?.cisilDataDescriptionWorkloadList)
            .sort((a, b) => a[PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_ID].localeCompare(b[PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_ID]))
            .map((data) => {
              return {
                Id: data.id ? data.id : uuidv4(),
                [PEO_PM_Utils.PEO_PM_TableFields.USER_CASE_ID]: data[PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_ID],
                [PEO_PM_Utils.PEO_PM_TableFields.LINE_ID]: data[PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_LINE_NUMBER_ID],
                [PEO_PM_Utils.PEO_PM_TableFields.MASL]: data[PEO_PM_Utils.PEO_PM_DataFields.MILITARY_ARTICLE_SERVICE_CODE],
                [PEO_PM_Utils.PEO_PM_TableFields.MASL_DESCRIPTION]: data[PEO_PM_Utils.PEO_PM_DataFields.ARTICLE_DESCRIPTION_TEXT],
                [PEO_PM_Utils.PEO_PM_TableFields.REMOVE]: false,
              }
            })
        } else {
          return selectedOrg?.dsamsPrepActDataAssociationsList
            .sort((a, b) => a[PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_ID].localeCompare(b[PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_ID]))
            .map((data) => {
              return {
                Id: data.id ? data.id : uuidv4(),
                [PEO_PM_Utils.PEO_PM_TableFields.USER_CASE_ID]: data[PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_ID],
                [PEO_PM_Utils.PEO_PM_TableFields.LINE_ID]: data[PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_LINE_NUMBER_ID],
                [PEO_PM_Utils.PEO_PM_TableFields.MASL]: data[PEO_PM_Utils.PEO_PM_DataFields.MILITARY_ARTICLE_SERVICE_CODE],
                [PEO_PM_Utils.PEO_PM_TableFields.MASL_DESCRIPTION]: data[PEO_PM_Utils.PEO_PM_DataFields.ARTICLE_DESCRIPTION_TEXT],
                [PEO_PM_Utils.PEO_PM_TableFields.GENERIC_DESCRIPTION]: data[PEO_PM_Utils.PEO_PM_DataFields.GENERIC_DESCRIPTION_TEXT],
                [PEO_PM_Utils.PEO_PM_TableFields.PM]: data[PEO_PM_Utils.PEO_PM_DataFields.SUPPORTING_ORGANIZATION_TITLE_NAME],
                [PEO_PM_Utils.PEO_PM_TableFields.VALIDATED_WORKLOADS]: "Yes",
                [PEO_PM_Utils.PEO_PM_TableFields.REMOVE]: false,
              }
            })
        }
      })
    }
    if (MUISortModel.length != 0) {
      setMUISortModel([])
    }
  }, [selectedOrg, activeFiscalCycle])

  useEffect(() => {
    if (MUIFilter?.value != null) {
      pageNumber.current = 0
      updateTableRows()
    }
  }, [MUIFilter])

  useEffect(() => {
    if (MUISortModel[0]) {
      setShowBusyDialog(true)
      updateTableRows()
    }
  }, [MUISortModel])

  useEffect(() => {
    setAllPEOPMTableRows(() => {
      if (selectedOrg?.name === "USACE") {
        return uploadedTableRows
          .sort((a, b) => a[PEO_PM_Utils.PEO_PM_TableFields.USER_CASE_ID].localeCompare(b[PEO_PM_Utils.PEO_PM_TableFields.USER_CASE_ID]))
          .map((data) => {
            return {
              Id: data.id ? data.id : uuidv4(),
              [PEO_PM_Utils.PEO_PM_TableFields.USER_CASE_ID]: data[PEO_PM_Utils.PEO_PM_TableFields.USER_CASE_ID],
              [PEO_PM_Utils.PEO_PM_TableFields.LINE_ID]: data[PEO_PM_Utils.PEO_PM_TableFields.LINE_ID],
              [PEO_PM_Utils.PEO_PM_TableFields.MASL]: data[PEO_PM_Utils.PEO_PM_TableFields.MASL],
              [PEO_PM_Utils.PEO_PM_TableFields.MASL_DESCRIPTION]: data[PEO_PM_Utils.PEO_PM_TableFields.MASL_DESCRIPTION],
              [PEO_PM_Utils.PEO_PM_TableFields.REMOVE]: data[PEO_PM_Utils.PEO_PM_TableFields.REMOVE],
            }
          })
      } else {
        return uploadedTableRows
          .sort((a, b) => a[PEO_PM_Utils.PEO_PM_TableFields.USER_CASE_ID].localeCompare(b[PEO_PM_Utils.PEO_PM_TableFields.USER_CASE_ID]))
          .map((data) => {
            const rowIsAssociated = selectedOrg.dsamsPrepActDataAssociationsList.find(
              (association) =>
                association[PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_ID] === data[PEO_PM_Utils.PEO_PM_TableFields.USER_CASE_ID] &&
                association[PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_LINE_NUMBER_ID] === data[PEO_PM_Utils.PEO_PM_TableFields.LINE_ID]
            )
            return {
              Id: data.id ? data.id : uuidv4(),
              [PEO_PM_Utils.PEO_PM_TableFields.USER_CASE_ID]: data[PEO_PM_Utils.PEO_PM_TableFields.USER_CASE_ID],
              [PEO_PM_Utils.PEO_PM_TableFields.LINE_ID]: data[PEO_PM_Utils.PEO_PM_TableFields.LINE_ID],
              [PEO_PM_Utils.PEO_PM_TableFields.MASL]: data[PEO_PM_Utils.PEO_PM_TableFields.MASL],
              [PEO_PM_Utils.PEO_PM_TableFields.MASL_DESCRIPTION]: data[PEO_PM_Utils.PEO_PM_TableFields.MASL_DESCRIPTION],
              [PEO_PM_Utils.PEO_PM_TableFields.GENERIC_DESCRIPTION]: data[PEO_PM_Utils.PEO_PM_TableFields.GENERIC_DESCRIPTION],
              [PEO_PM_Utils.PEO_PM_TableFields.PM]: data[PEO_PM_Utils.PEO_PM_TableFields.PM],
              [PEO_PM_Utils.PEO_PM_TableFields.VALIDATED_WORKLOADS]: rowIsAssociated ? "Yes" : "No",
              [PEO_PM_Utils.PEO_PM_TableFields.REMOVE]: data[PEO_PM_Utils.PEO_PM_TableFields.REMOVE],
            }
          })
      }
    })
  }, [uploadedTableRows])

  useEffect(() => {
    if (csvData?.length > 0) {
      csvLinkRef.current.link.click()
      setCsvData([])
    }
    setShowLoadingSpinner(false)
  }, [csvData])

  useEffect(() => {
    getTableSubmitStatus()
    // renderingDBRows.current = true
    if (MUIFilter?.value && MUIFilter.value.length > 0) {
      setMUIFilter({
        tableField: "",
        dataField: "",
        operator: "equals",
        value: "",
      })
    }
    // Update Generated Status
    const skipStatuses = [
      SubmitStatus.NOT_SUBMITTED,
      SubmitStatus.SUBMITTED,
      SubmitStatus.REQUEST_RESUBMISSION,
      SubmitStatus.APPROVED,
      SubmitStatus.REJECTED,
    ]
    let status = selectedRFI?.status ? selectedRFI.status : SubmitStatus.NOT_GENERATED
    if (selectedOrg?.id && !skipStatuses.includes(status)) {
      if (
        (selectedOrg?.dsamsPrepActDataAssociationsList && selectedOrg.dsamsPrepActDataAssociationsList.length > 0) ||
        (selectedOrg?.cisilDataDescriptionWorkloadList && selectedOrg.cisilDataDescriptionWorkloadList.length > 0)
      ) {
        status = SubmitStatus.GENERATED
      }
      if (selectedRFI?.id) {
        apiCalls
          .put(`${PEO_PM_Utils.UrlApiTypes.rfi}/${selectedRFI.id}`, { ...selectedRFI, status })
          .then(() => {
            setPEOPMDataSubmitStatus(status)
          })
          .catch(() => {
            setPEOPMDataSubmitStatus(SubmitStatus.NOT_GENERATED)
          })
      } else {
        setPEOPMDataSubmitStatus(status)
      }
    }
  }, [selectedRFI])

  useEffect(() => {
    findUnassociatedRecords()
    setCanSavePEOPMChanges(() => {
      return allPEOPMTableRows.length > 0
    })
    updateTableRows()
  }, [allPEOPMTableRows])

  const handleLocationChange = async () => {
    let response = await apiCalls.getById(`Organizations`, location.state.organization.id)
    setSelectedOrgContext(response)
  }

  const setMappingKeyContext = () => {
    switch (selectedOrg.name) {
      case "SATMO":
        setSelectedTabRfiMappingKeyContext(SelectedTabToRFIMapping.SATMO)
        break
      case "USACE":
        setSelectedTabRfiMappingKeyContext(SelectedTabToRFIMapping.USACE)
        break

      default:
        setSelectedTabRfiMappingKeyContext(SelectedTabToRFIMapping.PEO_PM)
        break
    }
  }

  const getGridCols = () => {
    return selectedOrg?.name === "USACE" ? PEO_PM_COLS.slice(0, 5).concat(PEO_PM_COLS[PEO_PM_COLS.length - 1]) : PEO_PM_COLS
  }

  const associateRecords = async (records) => {
    let allCalls = records.map((item) => {
      let userCaseId = item["UserCaseId"] ? item["UserCaseId"] : item["userCaseId"]
      let lineId = item["LineId"] ? item["LineId"] : item["userCaseLineNumberId"]
      return apiCalls.put(`DsamsPreparingActivityDatas/${userCaseId}/${lineId}/AssociateRecord/${selectedOrg.id}`, null)
    })
    return Promise.all(allCalls)
  }

  const handleSave = async () => {
    setShowBusyDialog(true)

    // Parse records
    let recordsToDisassociate = []
    let recordsToAssociate = allPEOPMTableRows.filter((item) => {
      if (item.Remove) {
        let userCaseId = item["UserCaseId"] ? item["UserCaseId"] : item["userCaseId"]
        let lineId = item["LineId"] ? item["LineId"] : item["userCaseLineNumberId"]

        recordsToDisassociate.push({
          item1: userCaseId,
          item2: lineId,
        })
        return false
      } else if (item.ValidatedWorkloads === "Yes") {
        return false
      } else {
        return item
      }
    })

    //Associate records
    let associateResponse = await associateRecords(recordsToAssociate)
    let associationResults = getAssociationResults(associateResponse)

    //Dissociate records
    let disResponse = await apiCalls.put(`DsamsPreparingActivityDatas/DisassociateRecords/${selectedOrg.id}`, recordsToDisassociate)
    let disassociationResults = getDisassociationResults(disResponse)

    refreshSelectedOrg()
    setShowBusyDialog(false)

    setResults({
      associations: associationResults,
      disassociations: disassociationResults,
    })
    setShowSaveChangesListModal(true)
  }

  const getAssociationResults = (response) => {
    let successes = []
    let errors = []

    response.forEach((item) => {
      if (item.status !== 200) {
        errors.push(item)
      } else {
        successes.push(item.data.message)
      }
    })

    return {
      errors,
    }
  }

  const getDisassociationResults = (response) => {
    let { data } = response
    let successes = []
    let errors = []

    if (data.notRemovedWorkload.length > 0) {
      data.notRemovedWorkload.forEach((item) => {
        errors.push(item)
      })
    }

    if (data.removedWorkload.length > 0) {
      data.removedWorkload.forEach((item) => {
        successes.push(item)
      })
    }

    return {
      successes,
      errors,
    }
  }

  const refreshSelectedOrg = () => {
    apiCalls.getById(`Organizations`, selectedOrg.id).then((apiOrg) => {
      setSelectedOrgContext(apiOrg)
    })
  }

  const getSelectedRFI = async () => {
    const selectedTabRFIs = await apiCalls.getAll(PEO_PM_Utils.UrlApiTypes.rfi, {})

    const RFI = selectedTabRFIs.find((RFI) => RFI.organizationId === selectedOrg.id && RFI.fiscalCycleId === activeFiscalCycle.id)

    if (!RFI) {
      if (selectedRFI?.id == null) {
        getTableSubmitStatus()
        return
      }
      setSelectedRFI({})
      return
    }
    setSelectedRFI(RFI)
  }

  const getTableSubmitStatus = async () => {
    // Check if there is an RFI for the selected ORG & FC
    if (selectedRFI?.status) {
      setPEOPMDataSubmitStatus(selectedRFI.status)
      console.log("selected: ", selectedRFI.status)
      return
    }
    if (!selectedOrg?.id) {
      // If somehow there is no selectedOrg, default the status to NOT_GENERATED
      setPEOPMDataSubmitStatus(SubmitStatus.NOT_GENERATED)
      return
    }
    // If there is no RFIs for the selected ORG & FC, check if there are any associations for the selected ORG
    if (selectedOrg.name === "USACE") {
      // Check the dsamsPrepActDataAssociationsList & the cisilDataDescriptionWorkloadList for any associations
      if (selectedOrg.dsamsPrepActDataAssociationsList.length > 0 || selectedOrg.cisilDataDescriptionWorkloadList.length > 0) {
        setPEOPMDataSubmitStatus(SubmitStatus.GENERATED)
      } else setPEOPMDataSubmitStatus(SubmitStatus.NOT_GENERATED)
    } else {
      // Otherwise only check the dsamsPrepActDataAssociationsList for any associations
      if (selectedOrg.dsamsPrepActDataAssociationsList.length > 0) {
        setPEOPMDataSubmitStatus(SubmitStatus.GENERATED)
      } else setPEOPMDataSubmitStatus(SubmitStatus.NOT_GENERATED)
    }

    setCanSubmitRFI(() => {
      return (!selectedRFI?.status || UploadSubmitEnabledStatuses.includes(selectedRFI?.status)) && allPEOPMTableRows.length > 0 ? true : false
    })
    setCanExportRFIData(() => allPEOPMTableRows.length > 0)
  }

  const closeNewPEOPMRecordDialog = () => {
    setShowNewPEOPMDialog(false)
  }

  const handleLoadCSVDataIntoTable = (data) => {
    setShowBusyDialog(false)
    if (data[0] == null) {
      setShowSnackbarError(true)
      setSnackbarMessage("The expected headers were not detected or the uploaded file did not have any data")
      setShowLoadingSpinner(false)
      return
    }

    fileSelector.current.value = null // Clear the file input field for the next upload
    const importedHeaders = Object.keys(data[0])
      .filter((key) => key !== "__parsed_extra")
      .map((header) => header.replace(/\s/g, "").toLocaleLowerCase())
    let tableHeaders = PEO_PM_Utils.ExpectedPEOPMHeaders.map((header) => header.toLocaleLowerCase())
    // Check for warnings - for now check if any of the headers in the result data don't match a header on the table
    let badHeaders = false
    for (let i = 0; i < importedHeaders.length; i++) {
      if (!tableHeaders.includes(importedHeaders[i])) {
        badHeaders = true
      }
    }
    if (badHeaders) {
      setShowSnackbarError(true)
      setSnackbarMessage(`Some or all of the headers are incorrect or missing in the uploaded file. Please check the file and try again.`)
      setShowLoadingSpinner(false)
      return
    }
    data.forEach((RFI) => {
      delete RFI.__parsed_extra
      Object.keys(RFI).forEach((key) => {
        if (RFI[key.replace(/\s/g, "")] !== RFI[key]) {
          RFI[key.replace(/\s/g, "")] = RFI[key]
          delete RFI[key]
        }
      })
    })
    renderingDBRows.current = false
    setUploadedTableRows(data)
    setShowLoadingSpinner(false)
  }

  const persistDBData = async () => {
    await apiCalls.getAll(`${PEO_PM_Utils.UrlApiTypes.rfi}`, {}).then(async (allRFIs) => {
      const orgFCIdPairs = allRFIs.map((data) => {
        return { orgId: data.organizationId, fcId: data.fiscalCycleId }
      })
      let orgFCIdPairExists = false
      for (let i = 0; i < orgFCIdPairs.length; i++) {
        if (orgFCIdPairs[i].orgId === selectedOrg.id && orgFCIdPairs[i].fcId === activeFiscalCycle.id) {
          orgFCIdPairExists = true
          break
        }
      }
      if (!orgFCIdPairExists) {
        await apiCalls.post(`${PEO_PM_Utils.UrlApiTypes.rfi}`, {
          organizationId: selectedOrg.id,
          fiscalCycleId: activeFiscalCycle.id,
          poc: userObj.name,
        })
      }
    })
    const tmpSelectedRFI = await apiCalls
      .getAll(`${PEO_PM_Utils.UrlApiTypes.rfi}`, {})
      .then((RFIs) => RFIs.find((RFI) => RFI.organizationId === selectedOrg.id && RFI.fiscalCycleId === activeFiscalCycle.id))
    const dsamsPrepActDataAssociationsListTableData = []
    selectedOrg.dsamsPrepActDataAssociationsList.forEach((association) => {
      dsamsPrepActDataAssociationsListTableData.push({
        [PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_ID]: association[PEO_PM_Utils.PEO_PM_TableFields.USER_CASE_ID],
        [PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_LINE_NUMBER_ID]: association[PEO_PM_Utils.PEO_PM_TableFields.LINE_ID],
        [PEO_PM_Utils.PEO_PM_DataFields.MILITARY_ARTICLE_SERVICE_CODE]: association[PEO_PM_Utils.PEO_PM_TableFields.MASL],
        [PEO_PM_Utils.PEO_PM_DataFields.ARTICLE_DESCRIPTION_TEXT]: association[PEO_PM_Utils.PEO_PM_TableFields.MASL_DESCRIPTION],
        [PEO_PM_Utils.PEO_PM_DataFields.GENERIC_DESCRIPTION_TEXT]: association[PEO_PM_Utils.PEO_PM_TableFields.GENERIC_DESCRIPTION],
        [PEO_PM_Utils.PEO_PM_DataFields.SUPPORTING_ORGANIZATION_TITLE_NAME]: association[PEO_PM_Utils.PEO_PM_TableFields.PM],
        [PEO_PM_Utils.PEO_PM_DataFields.VALIDATED_WORKLOADS]: "Yes",
        [PEO_PM_Utils.UrlApiTypes.rfiId]: tmpSelectedRFI.id,
      })
    })

    apiCalls
      .post(`${PEO_PM_Utils.UrlApiTypes.record}/BulkUpload`, dsamsPrepActDataAssociationsListTableData)
      .then(() => {
        apiCalls
          .put(`${PEO_PM_Utils.UrlApiTypes.rfi}/${tmpSelectedRFI.id}`, { ...tmpSelectedRFI, status: SubmitStatus.SUBMITTED })
          .then(() => {
            setShowSnackbarSuccess(true)
            setSnackbarMessage("Successfully Submitted RFI data")
            getSelectedRFI()
            if (activeFiscalCycle?.id) {
              getRfiRecordCountMapping(activeFiscalCycle).then((mapping) => {
                setRfiRecordCountMappingContext(mapping)
                fetchRFIDashboardObjectsFromDB(rfiOrganizationsMapping, allOrgs, activeFiscalCycle, mapping).then((data) => {
                  setRfiDashboardOrgRfiMappingContext(data)
                })
              })
            }
          })
          .catch(() => {
            setShowSnackbarError(true)
            setSnackbarMessage("Failed to submit RFI data")
          })
      })
      .catch(() => {
        setShowSnackbarError(true)
        setSnackbarMessage("Failed to submit RFI data")
      })
      .finally(() => {
        setShowBusyDialog(false)
        setUnassociatedRows([])
        updateTableRows()
      })
  }

  const handleReadCSVFile = (file) => {
    setMUISortModel([])
    setMUIFilter({})
    // Ignore all other file types that are not 'text/csv'
    if (file.type === "text/csv") {
      let data = []
      let bytesRead = 0

      //TODO: refactor loading state
      setLoadingTitle(CSVUploadProgressMessageTypes.PREPARE)
      setShowLoadingSpinner(true)

      Papa.parse(file, {
        header: true,
        chunkSize: Papa.LocalChunkSize,
        skipEmptyLines: true,
        chunk: (chunkResult) => {
          const chunkData = chunkResult.data.map((row, index) => {
            delete row[""]
            return row
          })
          data = data.concat(chunkData)
          bytesRead += Papa.LocalChunkSize
        },
        complete: () => {
          setTimeout(() => {
            const trimmedParsedRows = data.filter((row) => Object.values(row).filter((value) => value !== "").length > 0)

            data.filter((row) => {
              let values = Object.values(row)
              if (values[7] != "") {
                // console.log("remove: ", values)
              }
            })

            handleLoadCSVDataIntoTable(trimmedParsedRows)
          }, 1000)
        },
      })
    } else {
      setShowSnackbarError(true)
      setSnackbarMessage("Cannot upload data: The file type is not supported. Please upload a CSV file.")
    }
  }

  // “Are you sure you want to delete x number of records permanently from your organization’s workload association? This data cannot be restored.”
  const handleSubmitRFIData = async () => {
    submitRFIData().then(() => {
      refreshSelectedOrg()
      pageNumber.current = 0
    })
  }

  // Overwrite any existing csv entries in the DB with the new data
  const submitRFIData = async () => {
    setShowBusyDialog(true)
    let tmpRFI = {}
    if (!selectedRFI?.id) {
      await apiCalls
        .post(`${PEO_PM_Utils.UrlApiTypes.rfi}`, { organizationId: selectedOrg.id, fiscalCycleId: activeFiscalCycle.id, poc: userObj.name })
        .then(async () => {
          tmpRFI = await apiCalls
            .getAll(`${PEO_PM_Utils.UrlApiTypes.rfi}`, {})
            .then((RFIs) => RFIs.find((RFI) => RFI.organizationId === selectedOrg.id && RFI.fiscalCycleId === activeFiscalCycle.id))
        })
    } else {
      tmpRFI = { ...selectedRFI }
    }
    apiCalls
      .put(`${PEO_PM_Utils.UrlApiTypes.rfi}/${tmpRFI.id}`, { ...tmpRFI, status: SubmitStatus.SUBMITTED })
      .then(() => {
        getTableSubmitStatus()
        fetchRFIDashboardObjectsFromDB(rfiOrganizationsMapping, allOrgs, activeFiscalCycle, rfiRecordCountMapping).then((data) => {
          setRfiDashboardOrgRfiMappingContext(data)
        })
      })
      .then(() => persistDBData())
      .catch((e) => {
        setShowSnackbarError(true)
        setSnackbarMessage("Failed to submit RFI data")
      })
    renderingDBRows.current = true
  }

  const findUnassociatedRecords = () => {
    let unassociatedRecords = []
    allPEOPMTableRows.forEach((row) => {
      if (
        !selectedOrg?.dsamsPrepActDataAssociationsList.find(
          (association) =>
            association[PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_LINE_NUMBER_ID] === row[PEO_PM_Utils.PEO_PM_TableFields.LINE_ID] &&
            association[PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_ID] === row[PEO_PM_Utils.PEO_PM_TableFields.USER_CASE_ID]
        )
      ) {
        unassociatedRecords.push(row)
      }
    })
    setUnassociatedRows(unassociatedRecords)
  }

  const requestResubmission = () => {
    setShowBusyDialog(true)
    apiCalls
      .put(`${PEO_PM_Utils.UrlApiTypes.rfi}/${selectedRFI.id}`, { ...selectedRFI, status: SubmitStatus.REQUEST_RESUBMISSION })
      .then(() => {
        getSelectedRFI()
        setShowSnackbarSuccess(true)
        setSnackbarMessage("Resubmission requested for RFI!")
        fetchRFIDashboardObjectsFromDB(rfiOrganizationsMapping, allOrgs, activeFiscalCycle, rfiRecordCountMapping).then((data) => {
          setRfiDashboardOrgRfiMappingContext(data)
        })
      })
      .catch(() => {
        setShowSnackbarError(true)
        setSnackbarMessage("Failed to request resubmission for RFI")
      })
      .finally(() => setShowBusyDialog(false))
  }

  const toggleRemovePeoPmRecord = (params) => {
    let value

    if (!params.value) {
      value = "x"
    } else {
      value = ""
    }
    // apiRef.current.selectRow({
    //   id: params.id,
    //   isSelected: value,
    // })

    params.row.Remove = value
    // const newRows = allPEOPMTableRows.map((tableRow) => {
    //   if (
    //     tableRow[PEO_PM_Utils.PEO_PM_TableFields.USER_CASE_ID] === row[PEO_PM_Utils.PEO_PM_TableFields.USER_CASE_ID] &&
    //     tableRow[PEO_PM_Utils.PEO_PM_TableFields.LINE_ID] === row[PEO_PM_Utils.PEO_PM_TableFields.LINE_ID]
    //   ) {
    //     if (tableRow[PEO_PM_Utils.PEO_PM_TableFields.REMOVE] && tableRow[PEO_PM_Utils.PEO_PM_TableFields.REMOVE].length > 0)
    //       tableRow[PEO_PM_Utils.PEO_PM_TableFields.REMOVE] = ""
    //     else tableRow[PEO_PM_Utils.PEO_PM_TableFields.REMOVE] = "x"
    //   }
    //   return tableRow
    // })
    // setAllPEOPMTableRows(newRows)
  }

  const updateTableForTab = (skip) => {
    let filteredTableRows = []
    filteredTableRows = [...allPEOPMTableRows]
    if (MUIFilter?.value) {
      switch (MUIFilter.operator) {
        case "contains":
          filteredTableRows = filteredTableRows.filter((row) => row[MUIFilter.tableField].toLowerCase().includes(MUIFilter.value.toLowerCase()))
          break
        case "equals":
          filteredTableRows = filteredTableRows.filter((row) => row[MUIFilter.tableField].toLowerCase() === MUIFilter.value.toLowerCase())
          break
        default:
          break
      }
    }
    numTotalTableRows.current = filteredTableRows.length
    let filteredSortedTableRows = [...filteredTableRows]
    if (MUISortModel[0]?.field) {
      filteredSortedTableRows.sort((a, b) => {
        if (MUISortModel[0].sort === "asc") {
          return a[MUISortModel[0].field].localeCompare(b[MUISortModel[0].field])
        } else {
          return b[MUISortModel[0].field].localeCompare(a[MUISortModel[0].field])
        }
      })
    }
    setSelectedPEOPMDataTableRows(() => filteredSortedTableRows.slice(skip, Math.min(skip + pageSize.current, numTotalTableRows.current)))
    setShowBusyDialog(false)
  }

  const updateTableRows = () => {
    if (!selectedOrg?.id || pageNumber?.current == null || pageNumber.current < 0 || pageSize?.current == null) {
      setShowBusyDialog(false)
      return
    }
    const skip = pageNumber.current * pageSize.current
    updateTableForTab(skip)
    getTableSubmitStatus()
  }

  const handleOnPageSizeChange = (pgSize) => {
    pageSize.current = pgSize
    if (pageNumber.current * pgSize + 1 > numTotalTableRows.current) {
      pageNumber.current = 0
    }
    handleOnPageChange(pageNumber.current)
  }

  const handleOnPageChange = (pgNumber) => {
    if (pgNumber < 0) pgNumber = 0
    pageNumber.current = pgNumber
    updateTableRows()
  }

  const handleSortModelChange = (MUISortModel) => {
    const fieldKeyIdx = Object.values(PEO_PM_Utils.PEO_PM_TableFields).indexOf(MUISortModel[0]?.field)
    if (fieldKeyIdx != null && fieldKeyIdx >= 0) {
      const updatedSortModel = [{ ...MUISortModel[0], dataField: Object.values(PEO_PM_Utils.PEO_PM_DataFields)[fieldKeyIdx] }]
      setMUISortModel(updatedSortModel)
    } else {
      setMUISortModel([{}])
    }
  }

  const handleFilterRFIData = (tableField, dataField, operator, value) => {
    setMUIFilter({ tableField, dataField, operator, value })
  }

  const handleExportRFIData = async () => {
    let skip = 0
    const top = 5000
    let exportedTableRowData = []
    setShowLoadingSpinner(true)
    if (renderingDBRows.current) {
      do {
        await apiCalls.getById("Organizations", selectedOrg.id).then((res) => {
          const pageRows = res.dsamsPrepActDataAssociationsList
          let pageTableRows = []
          for (const d of pageRows) {
            const rowRemoved = allPEOPMTableRows.find(
              (row) =>
                row[PEO_PM_Utils.PEO_PM_TableFields.USER_CASE_ID] === d[PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_ID] &&
                row[PEO_PM_Utils.PEO_PM_TableFields.LINE_ID] === d[PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_LINE_NUMBER_ID]
            )?.Remove
            pageTableRows.push({
              Id: d.id ? d.id : uuidv4(),
              [PEO_PM_Utils.PEO_PM_TableFields.USER_CASE_ID]: d[PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_ID],
              [PEO_PM_Utils.PEO_PM_TableFields.LINE_ID]: d[PEO_PM_Utils.PEO_PM_DataFields.USER_CASE_LINE_NUMBER_ID],
              [PEO_PM_Utils.PEO_PM_TableFields.MASL]: d[PEO_PM_Utils.PEO_PM_DataFields.MILITARY_ARTICLE_SERVICE_CODE],
              [PEO_PM_Utils.PEO_PM_TableFields.MASL_DESCRIPTION]: d[PEO_PM_Utils.PEO_PM_DataFields.ARTICLE_DESCRIPTION_TEXT],
              [PEO_PM_Utils.PEO_PM_TableFields.GENERIC_DESCRIPTION]: d[PEO_PM_Utils.PEO_PM_DataFields.GENERIC_DESCRIPTION_TEXT],
              [PEO_PM_Utils.PEO_PM_TableFields.PM]: d[PEO_PM_Utils.PEO_PM_DataFields.SUPPORTING_ORGANIZATION_TITLE_NAME],
              [PEO_PM_Utils.PEO_PM_TableFields.VALIDATED_WORKLOADS]: d[PEO_PM_Utils.PEO_PM_DataFields.VALIDATED_WORKLOADS],
              [PEO_PM_Utils.PEO_PM_TableFields.REMOVE]: rowRemoved ? "x" : "", // Set to if remove checkbox is checked
            })
          }
          exportedTableRowData = exportedTableRowData.concat(pageTableRows)
        })
        skip += top
      } while (skip < numTotalTableRows.current)
      // Filter exported data if MUI filters have been applied
      switch (MUIFilter?.operator) {
        case "equals":
          exportedTableRowData = exportedTableRowData.filter((row) => row[MUIFilter.tableField] === MUIFilter.value)
          break
        case "contains":
          exportedTableRowData = exportedTableRowData.filter((row) => row[MUIFilter.tableField].includes(MUIFilter.value))
          break
        default:
          break
      }
      setCsvData(exportedTableRowData)
    } else {
      // Export error upload rows in MUI table (all or filtered)
      exportedTableRowData = uploadedTableRows
      switch (MUIFilter?.operator) {
        case "equals":
          exportedTableRowData = uploadedTableRows.filter((row) => row[MUIFilter.tableField] === MUIFilter.value)
          break
        case "contains":
          exportedTableRowData = uploadedTableRows.filter((row) => row[MUIFilter.tableField].includes(MUIFilter.value))
          break
        default:
          break
      }
      setCsvData(exportedTableRowData)
    }
  }

  if (loading) {
    return <CircularProgress />
  } else {
    return (
      <Box className="peopmContainer">
        {/* --- TITLE CARD/LOGO --- */}
        <Card
          sx={{
            paddingTop: "20px",
            display: "flex",
            justifyContent: "space-between",
            maxHeight: "150px",
            boxShadow: "none",
            minWidth: "100%",
            marginBottom: "15px",
            "& .MuiCardHeader-title": {
              fontSize: "1.6rem",
              fontWeight: 500,
            },
            "& .MuiCardHeader-subheader": {
              fontSize: "1rem",
              fontWeight: 300,
            },
          }}
        >
          <CardHeader
            title="Workload Validation"
            sx={{
              padding: "0px",
              "& .MuiTypography-root": {
                fontFamily: "Bitter",
                fontWeight: "bold",
              },
            }}
          />
          <Box className="org-display-container">
            <img
              src={logosArr?.find((logo) => logo.name === selectedOrg.name)?.link}
              alt={orgIconPlaceholder}
              width="90"
              height="90"
              style={{ marginRight: "10px" }}
            />
            <Typography style={{ fontWeight: "bold", fontSize: "1.1rem" }}>{selectedOrg?.longName}</Typography>
          </Box>
        </Card>

        {/* --- RFI INFO --- */}
        <Box className="rfiInfoCont">
          {/* RFI INSTRUCTIONS BUTTON */}
          <Button
            variant="outlined"
            color="info"
            startIcon={<Help />}
            onClick={() => setDisclaimerRFIModalOpen(true)}
          >
            VIEW RFI INSTRUCTIONS
          </Button>
          {/* RFI STATUS*/}
          <RfiStatusChip status={PEOPMDataSubmitStatus} />
        </Box>

        {/* --- GRID FILTER/TABLE ACTIONS --- */}
        <Box
          className="peopmActions"
          style={{}}
        >
          {/* 
          <RFIGridToolbar
            csvLinkRef={csvLinkRef}
            csvData={csvData}
            selectedTab="PEOPM"
            selectedFiscalCycle={activeFiscalCycle}
            selectedOrgName={selectedOrg.name}
            headers={selectedOrg.name === "USACE" ? PEO_PM_Utils.ExpectedPEOPMHeaders.slice(0, 4) : PEO_PM_Utils.ExpectedPEOPMHeaders}
            dataHeaders={
              selectedOrg.name === "USACE"
                ? Object.values(PEO_PM_Utils.PEO_PM_DataFields).slice(0, 4)
                : Object.values(PEO_PM_Utils.PEO_PM_DataFields).slice(0, -1)
            }
            handleExportRFIData={handleExportRFIData}
            handleFilterRFIData={handleFilterRFIData}
            canExportRFIData={canExportRFIData}
            reportStatus={PEOPMDataSubmitStatus}
          />
 */}
          <Stack
            direction="row"
            spacing={1}
          >
            {/* RESUBMISSION */}
            <Button
              onClick={() => setShowRequestResubmissionWarningModal(true)}
              hidden={PEOPMDataSubmitStatus !== SubmitStatus.SUBMITTED}
              disableRipple
              sx={{
                minWidth: "180px",
              }}
            >
              REQUEST RESUBMISSION
            </Button>

            {/* UPLOAD CSV */}
            <Button
              variant="outlined"
              disabled={!UploadSubmitEnabledStatuses.includes(PEOPMDataSubmitStatus) && activeFiscalCycle?.id === activeFiscalCycle?.id}
              onClick={() => fileSelector.current.click()}
              disableRipple
            >
              UPLOAD CSV FILE
              <input
                type="file"
                ref={fileSelector}
                onChangeCapture={(e) => handleReadCSVFile(e.target.files[0])}
                hidden
                sx={{ minWidth: "120px" }}
              />
            </Button>

            {/* ASSOCIATE */}
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setShowNewPEOPMDialog(true)}
              disabled={!renderingDBRows.current}
            >
              ASSOCIATE RECORD
            </Button>

            {/* SAVE */}
            <Button
              variant="contained"
              onClick={() => setShowSaveChangesModal(true)}
              disabled={!canSavePEOPMChanges}
            >
              SAVE CHANGES
            </Button>

            {/* SUBMIT RFI */}
            <Button
              variant="contained"
              onClick={() => setShowSubmitRFIWarningModal(true)}
              disabled={!canSubmitRFI}
              disableRipple
              sx={{ minWidth: "100px" }}
            >
              SUBMIT RFI
            </Button>
          </Stack>
        </Box>

        {/* --- DATA GRID --- */}
        <Box sx={{ maxHeight: "70vh" }}>
          <CustomDataGrid
            columns={getGridCols()}
            data={selectedPEOPMTableRows}
            initialState={initialState}
            handleSortModelChange={handleSortModelChange}
          />
        </Box>

        {/* --- CUSTOM PAGINATION --- */}
        <TableControls
          pageNumber={pageNumber.current}
          pageSize={pageSize.current}
          numTotalTableRows={numTotalTableRows.current}
          handleOnPageChange={handleOnPageChange}
        />

        <NewPEO_PMRecord
          showDialog={showNewPEOPMDialog}
          closeDialog={closeNewPEOPMRecordDialog}
          selectedOrg={selectedOrg}
          setShowSnackbarSuccess={setShowSnackbarSuccess}
          setShowSnackbarError={setShowSnackbarError}
          setSnackbarMessage={setSnackbarMessage}
          refreshSelectedOrg={refreshSelectedOrg}
        />
        <UploadProgressModal
          open={showLoadingSpinner}
          title={loadingTitle}
          loadingProgress={null}
        />
        <RequestResubmissionWarningModal
          open={showRequestResubmissionWarningModal}
          setOpen={setShowRequestResubmissionWarningModal}
          requestResubmission={requestResubmission}
        />
        <SnackbarMessages
          showSnackbarSuccess={showSnackbarSuccess}
          setShowSnackbarSuccess={setShowSnackbarSuccess}
          showSnackbarError={showSnackbarError}
          setShowSnackbarError={setShowSnackbarError}
          snackbarMessage={snackbarMessage}
        />
        {showSaveChangesListModal && (
          <SaveResultsDialog
            open={showSaveChangesListModal}
            setOpen={setShowSaveChangesListModal}
            results={results}
          />
        )}
        <PEOPMSATMOSaveChangesWarningModal
          open={showSaveChangesModal}
          setOpen={setShowSaveChangesModal}
          saveChanges={handleSave}
          msg="Save Changes"
        />
        <SubmitRFIWarningModal
          showSubmitRFIWarningModal={showSubmitRFIWarningModal}
          setShowSubmitRFIWarningModal={setShowSubmitRFIWarningModal}
          msg={`If you submit RFI data, you won't be able to reupload data unless an administrator puts the PEO PM table back to a "Not Submitted" state in the ${selectedOrg.name} organization.`}
          unassociatedRows={unassociatedRows}
          submitRFIData={handleSubmitRFIData}
        />
      </Box>
    )
  }
}

export default PEO_PM
