import { useState, useEffect, useContext, useRef } from "react"

// Libraries
import _, { set } from "lodash"
import { v4 as uuidv4 } from "uuid"
import { useLocation } from "react-router-dom"

// MUI
import { Box, Button, Card, CardHeader, CircularProgress, Stack, Tab, Tabs, Typography } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"

// Data
import { QueryParamTypes, apiCalls } from "../../DataService"
import GlobalContext from "../../reducers/GlobalContext"

// Custom
import {
  RFIViewOrgMappings,
  SelectedTabToRFIMapping,
  SubmitStatus,
  UploadSubmitEnabledStatuses,
  calculateFTEValue,
  populateGlobalContext,
} from "../../../Utils"
import Supplemental_Workforce_Utils from "./SupplementalWorkforceUtils"
import SupplementalWorkforce_Instructions_Modal from "./SupplementalWorkforce_Instructions_Modal"
import { SubmitRFIWarningModal } from "../SubmitRFIWarningModal"
import SnackbarMessages from "../../SnackbarMessages"
import { RequestResubmissionWarningModal } from "../RequestResubmissionWarningModal"
import { fetchRFIDashboardObjectsFromDB, getRfiRecordCountMapping } from "../../DashboardMenu"

// Styles
import "../RFI.css"
import CustomCard from "../../custom/CustomCard"
import CustomTabPanel from "../../custom/CustomTabPanel"
import SWRFITab from "./tabs/SWRFITab"
import AppContext from "../../../AppContext"
import { TabContext, TabList } from "@mui/lab"
import RfiStatusChip from "../../custom/RfiStatusChip"
import { Help } from "@mui/icons-material"

const SupplementalWorkforce = () => {
  // *** TABS *** //
  const tabs = ["Supplemental Workforce RFI", "BPC CIV FTE Reduction"]
  const [currentTab, setCurrentTab] = useState({
    num: 0,
    open: false,
    name: tabs[0],
  })
  const tabProps = (index) => {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    }
  }

  const handleTabChange = (e, newValue) => {
    setCurrentTab({
      num: newValue,
      name: tabs[newValue],
    })
  }

  const location = useLocation()
  const globalContext = useContext(GlobalContext)

  const [civPercentagesTotal100, setCivPercentagesTotal100] = useState(false)
  const [submitStatus, setSubmitStatus] = useState({})
  const [canSubmitRFIData, setCanSubmitRFIData] = useState({})
  const [supplementalWorkforceTableRows, setSupplementalWorkforceTableRows] = useState([])
  const [civilianPercentagesTableRows, setCivilianPercentagesTableRows] = useState([])

  const supplementalWorkforceTableCols = [
    {
      field: "Id",
      headerName: "Id",
      hide: true,
      headerClassName: "--RFI-SATFA-grid-header",
    },
    {
      field: Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD,
      headerName: "",
      flex: 1,
      minWidth: 150,
      headerClassName: "--RFI-SATFA-grid-header",
      cellClassName: (params) => {
        if (!params.row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD]) {
          return "--RFI-SATFA-blank-row"
        }
      },
    },
    {
      field: Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CIVILIANS,
      headerName: "Unaccounted for Civilians",
      flex: 1,
      minWidth: 150,
      type: "number",
      headerClassName: "--RFI-SATFA-grid-header",
      editable: true,
      cellClassName: (params) => {
        if (!params.row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD]) {
          return "--RFI-SATFA-blank-row"
        } else if (
          params.row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD] ===
            Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE ||
          params.row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD] ===
            Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE_PERCENTAGE
        ) {
          return "--RFI-SATFA-grid-header"
        } else {
          return "editable-cell"
        }
      },
    },
    {
      field: Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CONTRACTORS,
      headerName: "Contractors",
      flex: 1,
      minWidth: 150,
      type: "number",
      headerClassName: "--RFI-SATFA-grid-header",
      editable: true,
      cellClassName: (params) => {
        if (!params.row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD]) {
          return "--RFI-SATFA-blank-row"
        } else if (
          params.row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD] ===
            Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE ||
          params.row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD] ===
            Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE_PERCENTAGE
        ) {
          return "--RFI-SATFA-grid-header"
        } else {
          return "editable-cell"
        }
      },
    },
    {
      field: Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.MILITARY,
      headerName: "Military",
      flex: 1,
      minWidth: 150,
      type: "number",
      headerClassName: "--RFI-SATFA-grid-header",
      editable: true,
      cellClassName: (params) => {
        if (!params.row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD]) {
          return "--RFI-SATFA-blank-row"
        } else if (
          params.row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD] ===
            Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE ||
          params.row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD] ===
            Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE_PERCENTAGE
        ) {
          return "--RFI-SATFA-grid-header"
        } else {
          return "editable-cell"
        }
      },
    },
  ]

  const [selectedTableCols, setSelectedTableCols] = useState(supplementalWorkforceTableCols)
  const [selectedTableRows, setSelectedTableRows] = useState(supplementalWorkforceTableRows)
  const [bpcFteCivValue, setBpcFteCivValue] = useState(0)
  const [selectedTab, setSelectedTab] = useState(Supplemental_Workforce_Utils.Tabs.SUPPLEMENTAL_WORKFORCE_RFI)
  const [showRequestResubmissionWarningModal, setShowRequestResubmissionWarningModal] = useState(false)

  const [showSnackbarSuccess, setShowSnackbarSuccess] = useState(false)
  const [showSnackbarError, setShowSnackbarError] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [showSubmitRFIWarningModal, setShowSubmitRFIWarningModal] = useState(false)
  const [showBusyDialog, setShowBusyDialog] = useState(false)
  const [selectedRFI, setSelectedRFI] = useState({})

  const [disclaimerRFIModalOpen, setDisclaimerRFIModalOpen] = useState(false)

  const civilianPercentageTableCols = [
    {
      field: "Id",
      headerName: "Id",
      hide: true,
      headerClassName: "--RFI-SATFA-grid-header",
    },
    {
      field: Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.DATA_FIELD,
      headerName: "",
      flex: 1,
      minWidth: 150,
      headerClassName: "--RFI-SATFA-grid-header",
      cellClassName: (params) => {
        if (!params.row[Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.DATA_FIELD]) {
          return "--RFI-SATFA-blank-row"
        }
      },
    },
    {
      field: Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.CIVILIAN_PERCENTAGE,
      headerName: "Civilian Percentage",
      flex: 3,
      minWidth: 150,
      type: "number",
      headerClassName: "--RFI-SATFA-grid-header",
      editable: true,
      valueFormatter: (params) => {
        if (!params.value) return `0%`
        const valueFormatted = Number(params.value).toLocaleString()
        return `${valueFormatted}%`
      },
      cellClassName: (params) => {
        if (!params.row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD]) {
          return "--RFI-SATFA-blank-row"
        } else if (
          params.row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD] ===
            Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE ||
          params.row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD] ===
            Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE_PERCENTAGE
        ) {
          return "--RFI-SATFA-grid-header"
        } else {
          return "editable-cell"
        }
      },
    },
  ]
  const disabledSupplementalWorkforceTableCols = [
    {
      field: "Id",
      headerName: "Id",
      hide: true,
      headerClassName: "--RFI-SATFA-grid-header",
    },
    {
      field: Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD,
      headerName: "",
      flex: 1,
      minWidth: 150,
      headerClassName: "--RFI-SATFA-grid-header",
      cellClassName: (params) => {
        if (!params.row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD]) {
          return "--RFI-SATFA-blank-row"
        }
      },
    },
    {
      field: Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CIVILIANS,
      headerName: "Unaccounted for Civilians",
      flex: 1,
      minWidth: 150,
      type: "number",
      headerClassName: "--RFI-SATFA-grid-header",
      editable: true,
      cellClassName: (params) => {
        if (!params.row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD]) {
          return "--RFI-SATFA-blank-row"
        } else if (
          params.row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD] ===
            Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE ||
          params.row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD] ===
            Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE_PERCENTAGE
        ) {
          return "--RFI-SATFA-grid-header"
        } else {
          return "disabled-editable-cell"
        }
      },
    },
    {
      field: Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CONTRACTORS,
      headerName: "Contractors",
      flex: 1,
      minWidth: 150,
      type: "number",
      headerClassName: "--RFI-SATFA-grid-header",
      editable: true,
      cellClassName: (params) => {
        if (!params.row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD]) {
          return "--RFI-SATFA-blank-row"
        } else if (
          params.row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD] ===
            Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE ||
          params.row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD] ===
            Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE_PERCENTAGE
        ) {
          return "--RFI-SATFA-grid-header"
        } else {
          return "disabled-editable-cell"
        }
      },
    },
    {
      field: Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.MILITARY,
      headerName: "Military",
      flex: 1,
      minWidth: 150,
      type: "number",
      headerClassName: "--RFI-SATFA-grid-header",
      editable: true,
      cellClassName: (params) => {
        if (!params.row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD]) {
          return "--RFI-SATFA-blank-row"
        } else if (
          params.row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD] ===
            Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE ||
          params.row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD] ===
            Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE_PERCENTAGE
        ) {
          return "--RFI-SATFA-grid-header"
        } else {
          return "disabled-editable-cell"
        }
      },
    },
  ]
  const disabledCivilianPercentageTableCols = [
    {
      field: "Id",
      headerName: "Id",
      hide: true,
      headerClassName: "--RFI-SATFA-grid-header",
    },
    {
      field: Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.DATA_FIELD,
      headerName: "",
      flex: 1,
      minWidth: 150,
      headerClassName: "--RFI-SATFA-grid-header",
      cellClassName: (params) => {
        if (!params.row[Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.DATA_FIELD]) {
          return "--RFI-SATFA-blank-row"
        }
      },
    },
    {
      field: Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.CIVILIAN_PERCENTAGE,
      headerName: "Civilian Percentage",
      flex: 3,
      minWidth: 150,
      type: "number",
      headerClassName: "--RFI-SATFA-grid-header",
      editable: true,
      valueFormatter: (params) => {
        if (!params.value) return `0%`
        const valueFormatted = Number(params.value).toLocaleString()
        return `${valueFormatted}%`
      },
      cellClassName: (params) => {
        if (!params.row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD]) {
          return "--RFI-SATFA-blank-row"
        } else if (
          params.row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD] ===
            Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE ||
          params.row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD] ===
            Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE_PERCENTAGE
        ) {
          return "--RFI-SATFA-grid-header"
        } else {
          return "disabled-editable-cell"
        }
      },
    },
  ]

  const {
    activeFiscalCycle,
    setSelectedOrgContext,
    userObj,
    allOrgs,
    setFilteredOrgListContext,
    rfiOrganizationsMapping,
    rfiRecordCountMapping,
    setRfiRecordCountMappingContext,
    setRfiDashboardOrgRfiMappingContext,
    setSelectedTabRfiMappingKeyContext,
  } = globalContext
  const context = useContext(AppContext)
  let { selectedOrg } = context

  const updateTableSubmitStatusAfterSelectedRFIAPICall = (RFI) => {
    if (RFI) {
      setSelectedRFI(RFI)
    } else {
      setSelectedRFI({})
      if (!selectedRFI?.id) {
        getTableSubmitStatus()
      }
    }
  }

  const getSelectedRFI = () => {
    let RFI = {}
    switch (selectedTab) {
      case Supplemental_Workforce_Utils.Tabs.SUPPLEMENTAL_WORKFORCE_RFI:
        apiCalls.getAll(`SupplementalWorkforceRfis`, {}).then((rfis) => {
          RFI = rfis.find(
            (rfi) =>
              rfi.organizationId === selectedOrg.id &&
              rfi.fiscalCycleId === activeFiscalCycle.id &&
              (rfi.group ===
                Object.keys(Supplemental_Workforce_Utils.SupplementalWorkforceGroups)[
                  Supplemental_Workforce_Utils.SupplementalWorkforceGroups.UNACC_CIVILIANS
                ].toString() ||
                rfi.group ===
                  Object.keys(Supplemental_Workforce_Utils.SupplementalWorkforceGroups)[
                    Supplemental_Workforce_Utils.SupplementalWorkforceGroups.CONTRACTORS
                  ].toString() ||
                rfi.group ===
                  Object.keys(Supplemental_Workforce_Utils.SupplementalWorkforceGroups)[
                    Supplemental_Workforce_Utils.SupplementalWorkforceGroups.MILITARY
                  ].toString())
          )
          updateTableSubmitStatusAfterSelectedRFIAPICall(RFI)
        })
        break
      case Supplemental_Workforce_Utils.Tabs.CIVILIAN_PERCENTAGES:
        apiCalls.getAll(`SupplementalWorkforceRfis`, {}).then((rfis) => {
          RFI = rfis.find(
            (rfi) =>
              rfi.organizationId === selectedOrg.id &&
              rfi.fiscalCycleId === activeFiscalCycle.id &&
              rfi.group ===
                Object.keys(Supplemental_Workforce_Utils.SupplementalWorkforceGroups)[
                  Supplemental_Workforce_Utils.SupplementalWorkforceGroups.CIVILIAN_PERCENTAGE
                ].toString()
          )
          updateTableSubmitStatusAfterSelectedRFIAPICall(RFI)
        })
        break
      case Supplemental_Workforce_Utils.Tabs.BPC:
        apiCalls.getAll(`BpcReductionRfis`, {}).then((rfis) => {
          RFI = rfis.find((rfi) => rfi.organizationId === selectedOrg.id && rfi.fiscalCycleId === activeFiscalCycle.id)
          updateTableSubmitStatusAfterSelectedRFIAPICall(RFI)
        })
        break
      default:
        break
    }
  }

  const getTableSubmitStatus = () => {
    setSubmitStatus({
      ...submitStatus,
      [selectedTab]: selectedRFI?.status ? selectedRFI.status : SubmitStatus.NOT_SUBMITTED,
    })
    setCanSubmitRFIData({
      ...canSubmitRFIData,
      [selectedTab]: !selectedRFI?.status || UploadSubmitEnabledStatuses.includes(selectedRFI?.status) ? true : false,
    })
  }

  const initRowUpdate = () => {
    let updatedTableRows = []
    switch (selectedTab) {
      case Supplemental_Workforce_Utils.Tabs.SUPPLEMENTAL_WORKFORCE_RFI:
        let civilianTotal = 0
        let contractorTotal = 0
        let militaryTotal = 0
        let civilianLeave = 0
        let contractorLeave = 0
        let militaryLeave = 0
        updatedTableRows = supplementalWorkforceTableRows.map((row) => {
          if (
            row[Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.DATA_FIELD] ===
              Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE ||
            row[Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.DATA_FIELD] ===
              Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE_PERCENTAGE
          ) {
            civilianTotal = calculateFTEValue(civilianLeave, civilianTotal)
            militaryTotal = calculateFTEValue(militaryLeave, militaryTotal)
            contractorTotal = calculateFTEValue(contractorLeave, contractorTotal)
            return {
              Id: row?.Id ? row.Id : uuidv4(),
              [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD]:
                row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD],
              [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CIVILIANS]: civilianTotal,
              [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CONTRACTORS]: contractorTotal,
              [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.MILITARY]: militaryTotal,
            }
          }
          civilianTotal += row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CIVILIANS]
          contractorTotal += row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CONTRACTORS]
          militaryTotal += row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.MILITARY]
          if (
            row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD] ===
            Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.LEAVE
          ) {
            civilianLeave = row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CIVILIANS]
            militaryLeave = row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.MILITARY]
            contractorLeave = row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CONTRACTORS]
          }
          return row
        })
        setSelectedTableRows(updatedTableRows)
        break
      case Supplemental_Workforce_Utils.Tabs.CIVILIAN_PERCENTAGES:
        let civilianPercentageTotal = 0
        updatedTableRows = civilianPercentagesTableRows.map((row) => {
          if (
            row[Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.DATA_FIELD] ===
              Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE ||
            row[Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.DATA_FIELD] ===
              Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE_PERCENTAGE
          ) {
            return {
              Id: row?.Id ? row.Id : uuidv4(),
              [Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.DATA_FIELD]:
                row[Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.DATA_FIELD],
              [Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.CIVILIAN_PERCENTAGE]: Math.abs(Number(civilianPercentageTotal)),
            }
          }
          if (
            row[Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.DATA_FIELD] &&
            row[Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.DATA_FIELD] !==
              Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE &&
            row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD] !==
              Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE_PERCENTAGE
          ) {
            civilianPercentageTotal += row[Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.CIVILIAN_PERCENTAGE]
          }
          return row
        })
        setSelectedTableRows(updatedTableRows)
        const totalCivilianPercentage = updatedTableRows.find(
          (row) =>
            row[Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.DATA_FIELD] ===
            Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE_PERCENTAGE
        )?.[Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.CIVILIAN_PERCENTAGE]
        if (totalCivilianPercentage) {
          setCivPercentagesTotal100(totalCivilianPercentage === 100)
        }
        break
      default:
        break
    }
  }

  const handleRowUpdate = (params) => {
    let updatedTableRows = []
    switch (selectedTab) {
      case Supplemental_Workforce_Utils.Tabs.SUPPLEMENTAL_WORKFORCE_RFI:
        updatedTableRows = supplementalWorkforceTableRows.map((row) => {
          if (
            params &&
            row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD] ===
              params[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD]
          ) {
            return {
              Id: row?.Id ? row.Id : uuidv4(),
              [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD]:
                params[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD],
              [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CIVILIANS]: Math.abs(
                Number(params[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CIVILIANS])
              ),
              [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CONTRACTORS]: Math.abs(
                Number(params[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CONTRACTORS])
              ),
              [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.MILITARY]: Math.abs(
                Number(params[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.MILITARY])
              ),
            }
          }
          return row
        })
        updatedTableRows = updatedTableRows.map((row) => {
          if (
            row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD] ===
              Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE ||
            row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD] ===
              Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE_PERCENTAGE
          ) {
            let civilianTotal = 0
            let contractorTotal = 0
            let militaryTotal = 0
            let civilianLeave = 0
            let contractorLeave = 0
            let militaryLeave = 0
            updatedTableRows.forEach((tableRow) => {
              if (
                tableRow[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD] &&
                tableRow[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD] !==
                  Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE &&
                tableRow[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD] !==
                  Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE_PERCENTAGE
              ) {
                civilianTotal += tableRow[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CIVILIANS]
                contractorTotal += tableRow[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CONTRACTORS]
                militaryTotal += tableRow[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.MILITARY]
                if (
                  tableRow[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD] ===
                  Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.LEAVE
                ) {
                  civilianLeave = tableRow[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CIVILIANS]
                  militaryLeave = tableRow[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.MILITARY]
                  contractorLeave = tableRow[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CONTRACTORS]
                }
              }
            })
            civilianTotal = calculateFTEValue(civilianLeave, civilianTotal)
            militaryTotal = calculateFTEValue(militaryLeave, militaryTotal)
            contractorTotal = calculateFTEValue(contractorLeave, contractorTotal)

            return {
              Id: row?.Id ? row.Id : uuidv4(),
              [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD]:
                row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD],
              [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CIVILIANS]: civilianTotal,
              [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CONTRACTORS]: contractorTotal,
              [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.MILITARY]: militaryTotal,
            }
          }
          return row
        })
        setSupplementalWorkforceTableRows(updatedTableRows)
        setSelectedTableRows(updatedTableRows)
        break
      case Supplemental_Workforce_Utils.Tabs.CIVILIAN_PERCENTAGES:
        updatedTableRows = civilianPercentagesTableRows.map((row) => {
          if (
            params &&
            row[Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.DATA_FIELD] ===
              params[Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.DATA_FIELD]
          ) {
            return {
              Id: row?.Id ? row.Id : uuidv4(),
              [Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.DATA_FIELD]:
                params[Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.DATA_FIELD],
              [Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.CIVILIAN_PERCENTAGE]: Math.abs(
                Number(params[Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.CIVILIAN_PERCENTAGE])
              ),
            }
          }
          return row
        })
        updatedTableRows = updatedTableRows.map((row) => {
          if (
            row[Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.DATA_FIELD] ===
              Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE ||
            row[Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.DATA_FIELD] ===
              Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE_PERCENTAGE
          ) {
            let civilianPercentageTotal = 0
            updatedTableRows.forEach((tableRow) => {
              if (
                tableRow[Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.DATA_FIELD] &&
                tableRow[Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.DATA_FIELD] !==
                  Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE &&
                tableRow[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD] !==
                  Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE_PERCENTAGE
              ) {
                civilianPercentageTotal += tableRow[Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.CIVILIAN_PERCENTAGE]
              }
            })

            return {
              Id: row?.Id ? row.Id : uuidv4(),
              [Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.DATA_FIELD]:
                row[Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.DATA_FIELD],
              [Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.CIVILIAN_PERCENTAGE]: Math.abs(Number(civilianPercentageTotal)),
            }
          }
          return row
        })
        setCivilianPercentagesTableRows(updatedTableRows)
        setSelectedTableRows(updatedTableRows)
        const totalCivilianPercentage = updatedTableRows.find(
          (row) =>
            row[Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.DATA_FIELD] ===
            Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE_PERCENTAGE
        )?.[Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.CIVILIAN_PERCENTAGE]
        if (totalCivilianPercentage) {
          setCivPercentagesTotal100(totalCivilianPercentage === 100)
        }
        break
      default:
        break
    }
  }

  const makeDBCalls = async (group, entry, existingRfis) => {
    const existingRfi = existingRfis.find(
      (rfi) => rfi.group === Object.keys(Supplemental_Workforce_Utils.SupplementalWorkforceGroups)[group].toString()
    )
    const body =
      selectedTab === Supplemental_Workforce_Utils.Tabs.SUPPLEMENTAL_WORKFORCE_RFI
        ? {
            organizationId: selectedOrg.id,
            fiscalCycleId: activeFiscalCycle.id,
            poc: userObj.name,
            group: group,
            leave: entry[0],
            pre_Lor: entry[1],
            caseDevelopment: entry[2],
            caseExecution: entry[3],
            caseClosure: entry[4],
            businessSustaining: entry[5],
            status: SubmitStatus.SUBMITTED,
          }
        : {
            organizationId: selectedOrg.id,
            fiscalCycleId: activeFiscalCycle.id,
            poc: userObj.name,
            group: group,
            leave: 0,
            pre_Lor: entry[0],
            caseDevelopment: entry[1],
            caseExecution: entry[2],
            caseClosure: entry[3],
            businessSustaining: entry[4],
            status: SubmitStatus.SUBMITTED,
          }
    if (existingRfi?.id) {
      apiCalls
        .put(`SupplementalWorkforceRfis/${existingRfi.id}`, { ...body, id: existingRfi.id })
        .then(() => {
          setShowSnackbarSuccess(true)
          setSnackbarMessage(`Successfully uploaded Supplemental Workforce data!`)
          setSubmitStatus({ ...submitStatus, [selectedTab]: SubmitStatus.SUBMITTED })
        })
        .catch((e) => {
          console.error(e)
          setShowSnackbarError(true)
          setSnackbarMessage(`Unable to load Supplemental Workforce data`)
        })
        .finally(() => {
          setShowBusyDialog(false)
        })
    } else {
      apiCalls
        .post(`SupplementalWorkforceRfis`, body)
        .then(() => {
          setShowSnackbarSuccess(true)
          setSnackbarMessage(`Successfully uploaded Supplemental Workforce data!`)
          setSubmitStatus({ ...submitStatus, [selectedTab]: SubmitStatus.SUBMITTED })
        })
        .catch((e) => {
          console.error(e)
          setShowSnackbarError(true)
          setSnackbarMessage(`Unable to load Supplemental Workforce data`)
        })
        .finally(() => {
          setShowBusyDialog(false)
        })
    }
  }

  const updateRecordCountMapping = () => {
    if (activeFiscalCycle?.id) {
      getRfiRecordCountMapping(activeFiscalCycle).then((mapping) => {
        setRfiRecordCountMappingContext(mapping)
        fetchRFIDashboardObjectsFromDB(rfiOrganizationsMapping, allOrgs, activeFiscalCycle, mapping).then((data) => {
          setRfiDashboardOrgRfiMappingContext(data)
        })
      })
    }
    setShowBusyDialog(false)
  }

  // Overwrite any existing csv entries in the DB with the new data
  const submitRFIData = async () => {
    //Make sure an organization is selected
    if (!selectedOrg.id) {
      setShowSnackbarError(true)
      setSnackbarMessage("Organization not selected, please select an organization from the Organization POCs page")
      return
    }
    // Update Supplemental Workforce RFI table
    setShowBusyDialog(true)
    const existingSupplementalWorkforceRfisWithOrgId = await apiCalls.getAll(`SupplementalWorkforceRfis`, {
      [QueryParamTypes.FILTER]: `organizationId eq ${selectedOrg.id} and fiscalCycleId eq ${activeFiscalCycle.id}`,
    })
    switch (selectedTab) {
      case Supplemental_Workforce_Utils.Tabs.SUPPLEMENTAL_WORKFORCE_RFI:
        const civilianEntry = supplementalWorkforceTableRows.map(
          (row) => row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CIVILIANS]
        )
        const contractorEntry = supplementalWorkforceTableRows.map(
          (row) => row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CONTRACTORS]
        )
        const militaryEntry = supplementalWorkforceTableRows.map(
          (row) => row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.MILITARY]
        )
        await makeDBCalls(
          Supplemental_Workforce_Utils.SupplementalWorkforceGroups.UNACC_CIVILIANS,
          civilianEntry,
          existingSupplementalWorkforceRfisWithOrgId
        )
        await makeDBCalls(
          Supplemental_Workforce_Utils.SupplementalWorkforceGroups.CONTRACTORS,
          contractorEntry,
          existingSupplementalWorkforceRfisWithOrgId
        )
        await makeDBCalls(
          Supplemental_Workforce_Utils.SupplementalWorkforceGroups.MILITARY,
          militaryEntry,
          existingSupplementalWorkforceRfisWithOrgId
        )
        updateTableRowsFromDB()
        updateRecordCountMapping()
        break
      case Supplemental_Workforce_Utils.Tabs.CIVILIAN_PERCENTAGES:
        const civilianPercentageEntry = civilianPercentagesTableRows.map(
          (row) => row[Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.CIVILIAN_PERCENTAGE]
        )
        makeDBCalls(
          Supplemental_Workforce_Utils.SupplementalWorkforceGroups.CIVILIAN_PERCENTAGE,
          civilianPercentageEntry,
          existingSupplementalWorkforceRfisWithOrgId
        ).then(() => {
          setCivPercentagesTotal100(false)
          updateTableRowsFromDB()
          updateRecordCountMapping()
        })
        break
      case Supplemental_Workforce_Utils.Tabs.BPC:
        apiCalls
          .getAll(`BpcReductionRfis`, {})
          .then((allRFIs) => {
            if (allRFIs?.length <= 0) {
              apiCalls.post(`BpcReductionRfis`, { organizationId: selectedOrg.id, fiscalCycleId: activeFiscalCycle.id }).then(() => {
                setShowSnackbarSuccess(true)
                setSnackbarMessage(`Successfully uploaded ${selectedTab} data`)
                submitRFIData()
              })
            } else {
              const orgFCIdPairs = allRFIs.map((data) => {
                return { id: data.id, orgId: data.organizationId, fcId: data.fiscalCycleId }
              })
              let orgFCIdPairExists = false
              let rfiId = null
              for (let i = 0; i < orgFCIdPairs.length; i++) {
                if (orgFCIdPairs[i].orgId === selectedOrg.id && orgFCIdPairs[i].fcId === activeFiscalCycle.id) {
                  orgFCIdPairExists = true
                  rfiId = orgFCIdPairs[i].id
                  break
                }
              }
              if (!orgFCIdPairExists) {
                apiCalls.post(`BpcReductionRfis`, { organizationId: selectedOrg.id, fiscalCycleId: activeFiscalCycle.id }).then(() => {
                  setShowSnackbarSuccess(true)
                  setSnackbarMessage(`Successfully uploaded ${selectedTab} data`)
                  submitRFIData()
                })
              } else {
                const tmpSelectedRFI = allRFIs.find((rfi) => rfi.id === rfiId)
                apiCalls.delete(`BpcReductionRecords/${rfiId}/BulkDelete`).then(() => {
                  const tmpNewRecordData = [
                    {
                      id: uuidv4(),
                      organizationId: selectedOrg.id,
                      bpcFteCiv: bpcFteCivValue,
                      bpcReductionRfiId: tmpSelectedRFI.id,
                    },
                  ]
                  apiCalls
                    .post(`BpcReductionRecords/BulkUpload`, tmpNewRecordData)
                    .then(() => {
                      setShowSnackbarSuccess(true)
                      setSnackbarMessage(`Successfully uploaded ${selectedTab} data`)
                      apiCalls.put(`BpcReductionRfis/${rfiId}`, { ...tmpSelectedRFI, status: SubmitStatus.SUBMITTED }).then(() => {
                        getSelectedRFI()
                      })
                    })
                    .catch((e) => {
                      console.error(e)
                      setShowSnackbarError(true)
                      setSnackbarMessage(`Failed to upload ${selectedTab} data, check for validation errors`)
                    })
                    .finally(() => updateRecordCountMapping())
                })
              }
            }
          })
          .finally(() => {
            setShowBusyDialog(false)
          })
        break
    }
  }

  const handleSubmitRFIData = () => {
    if (selectedTab !== Supplemental_Workforce_Utils.Tabs.CIVILIAN_PERCENTAGES || civPercentagesTotal100) {
      setShowSubmitRFIWarningModal(true)
    } else {
      setShowSnackbarError(true)
      setSnackbarMessage("Civilian percentages must add up to 100%")
    }
  }

  const inCivPercentageOrg = () => {
    const canEdit =
      selectedOrg.name === "DASA DE&C" || selectedOrg.name === "PEO EIS" || selectedOrg.name === "PEO IEW&S" || selectedOrg.name === "PEO STRI"
    return canEdit
  }

  const canEditCell = (params) => {
    if (selectedRFI?.status && selectedRFI.status !== SubmitStatus.NOT_SUBMITTED && selectedRFI.status !== SubmitStatus.REJECTED) return false
    switch (selectedTab) {
      case Supplemental_Workforce_Utils.Tabs.SUPPLEMENTAL_WORKFORCE_RFI:
        return (
          params.row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD] &&
          !(
            params.row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD] ===
              Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.LEAVE &&
            params.field === Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.CIVILIAN_PERCENTAGE
          ) &&
          !(
            params.row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD] ===
            Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE
          )
        )
      case Supplemental_Workforce_Utils.Tabs.CIVILIAN_PERCENTAGES:
        return (
          params.row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD] &&
          !(
            params.row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD] ===
            Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE_PERCENTAGE
          )
        )
      case Supplemental_Workforce_Utils.Tabs.BPC:
        return (
          params.row[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD] ===
          Supplemental_Workforce_Utils.BPCColumnHeaders.BPC_FTE_CIV
        )
      default:
        break
    }
  }

  const updateTableRowFromDB = (dbRow, groupToUpdate, updatedTableRows) => {
    const rows = updatedTableRows.map((row) => {
      let tmpUpdatedTableRows = row
      switch (row?.[Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD]) {
        case Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.LEAVE:
          tmpUpdatedTableRows = { ...tmpUpdatedTableRows, [groupToUpdate]: dbRow?.leave }
          break
        case Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.PRE_LOR:
          tmpUpdatedTableRows = { ...tmpUpdatedTableRows, [groupToUpdate]: dbRow?.pre_Lor }
          break
        case Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.CASE_DEVELOPMENT:
          tmpUpdatedTableRows = { ...tmpUpdatedTableRows, [groupToUpdate]: dbRow?.caseDevelopment }
          break
        case Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.CASE_EXECUTION:
          tmpUpdatedTableRows = { ...tmpUpdatedTableRows, [groupToUpdate]: dbRow?.caseExecution }
          break
        case Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.CASE_CLOSURE:
          tmpUpdatedTableRows = { ...tmpUpdatedTableRows, [groupToUpdate]: dbRow?.caseClosure }
          break
        case Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.BUSINESS_SUSTAINING:
          tmpUpdatedTableRows = { ...tmpUpdatedTableRows, [groupToUpdate]: dbRow?.businessSustaining }
          break
        default:
          return row
      }
      return tmpUpdatedTableRows
    })
    return rows
  }

  const updateTableRowsFromDB = () => {
    switch (selectedTab) {
      case Supplemental_Workforce_Utils.Tabs.SUPPLEMENTAL_WORKFORCE_RFI:
        apiCalls
          .getAll(`SupplementalWorkforceRfis`, {
            [QueryParamTypes.FILTER]: `organizationId eq ${selectedOrg.id} and fiscalCycleId eq ${activeFiscalCycle.id}`,
          })
          .then((allRFIs) => {
            const supplementalWorkforceRFIsForOrg = allRFIs.filter(
              (RFI) =>
                RFI.group !==
                Object.keys(Supplemental_Workforce_Utils.SupplementalWorkforceGroups)[
                  Supplemental_Workforce_Utils.SupplementalWorkforceGroups.CIVILIAN_PERCENTAGE
                ]
            )
            if (supplementalWorkforceRFIsForOrg.length > 0) {
              let updatedTableRows = supplementalWorkforceTableRows
              for (let RFI of supplementalWorkforceRFIsForOrg) {
                let groupToUpdate = ""
                switch (RFI.group) {
                  case Object.keys(Supplemental_Workforce_Utils.SupplementalWorkforceGroups)[
                    Supplemental_Workforce_Utils.SupplementalWorkforceGroups.UNACC_CIVILIANS
                  ]:
                    groupToUpdate = Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CIVILIANS
                    break
                  case Object.keys(Supplemental_Workforce_Utils.SupplementalWorkforceGroups)[
                    Supplemental_Workforce_Utils.SupplementalWorkforceGroups.CONTRACTORS
                  ]:
                    groupToUpdate = Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CONTRACTORS
                    break
                  case Object.keys(Supplemental_Workforce_Utils.SupplementalWorkforceGroups)[
                    Supplemental_Workforce_Utils.SupplementalWorkforceGroups.MILITARY
                  ]:
                    groupToUpdate = Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.MILITARY
                    break
                }
                updatedTableRows = updateTableRowFromDB(RFI, groupToUpdate, updatedTableRows)
              }
              setSupplementalWorkforceTableRows(updatedTableRows)
              updateTableCols()
            } else {
              resetTableRows()
            }
          })
        break
      case Supplemental_Workforce_Utils.Tabs.CIVILIAN_PERCENTAGES:
        apiCalls
          .getAll(`SupplementalWorkforceRfis`, {
            [QueryParamTypes.FILTER]: `organizationId eq ${selectedOrg.id} and fiscalCycleId eq ${activeFiscalCycle.id}`,
          })
          .then((allRFIs) => {
            const civilianPercentageRFIForOrg = allRFIs.find(
              (RFI) =>
                RFI.group ===
                Object.keys(Supplemental_Workforce_Utils.SupplementalWorkforceGroups)[
                  Supplemental_Workforce_Utils.SupplementalWorkforceGroups.CIVILIAN_PERCENTAGE
                ]
            )
            let updatedTableRows = civilianPercentagesTableRows
            updatedTableRows = updateTableRowFromDB(
              civilianPercentageRFIForOrg,
              Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.CIVILIAN_PERCENTAGE,
              updatedTableRows
            )
            setCivilianPercentagesTableRows(updatedTableRows)
            updateTableCols()
          })
        break
      case Supplemental_Workforce_Utils.Tabs.BPC:
        apiCalls.getAll(`BpcReductionRecords`, { [QueryParamTypes.FILTER]: `bpcReductionRfiId eq ${selectedRFI.id}` }).then((records) => {
          setBpcFteCivValue(records[0]?.bpcFteCiv || 0)
        })
        break
      default:
        break
    }
  }

  const resetTableRows = () => {
    const initialSupplementalWorkforceTableRows = [
      {
        Id: uuidv4(),
        [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD]:
          Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.LEAVE,
        [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CIVILIANS]: 0,
        [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CONTRACTORS]: 0,
        [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.MILITARY]: 0,
      },
      {
        Id: uuidv4(),
        [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD]:
          Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.PRE_LOR,
        [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CIVILIANS]: 0,
        [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CONTRACTORS]: 0,
        [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.MILITARY]: 0,
      },
      {
        Id: uuidv4(),
        [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD]:
          Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.CASE_DEVELOPMENT,
        [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CIVILIANS]: 0,
        [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CONTRACTORS]: 0,
        [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.MILITARY]: 0,
      },
      {
        Id: uuidv4(),
        [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD]:
          Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.CASE_EXECUTION,
        [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CIVILIANS]: 0,
        [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CONTRACTORS]: 0,
        [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.MILITARY]: 0,
      },
      {
        Id: uuidv4(),
        [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD]:
          Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.CASE_CLOSURE,
        [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CIVILIANS]: 0,
        [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CONTRACTORS]: 0,
        [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.MILITARY]: 0,
      },
      {
        Id: uuidv4(),
        [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD]:
          Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.BUSINESS_SUSTAINING,
        [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CIVILIANS]: 0,
        [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CONTRACTORS]: 0,
        [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.MILITARY]: 0,
      },
      {
        Id: uuidv4(),
        [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD]: null,
        [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CIVILIANS]: null,
        [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CONTRACTORS]: null,
        [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.MILITARY]: null,
      },
      {
        Id: uuidv4(),
        [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.DATA_FIELD]:
          Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE,
        [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CIVILIANS]: 0,
        [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.CONTRACTORS]: 0,
        [Supplemental_Workforce_Utils.SupplementalWorkforceColumnHeaders.MILITARY]: 0,
      },
    ]
    const initialCivilianPercentageTableRows = [
      {
        Id: uuidv4(),
        [Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.DATA_FIELD]:
          Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.PRE_LOR,
        [Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.CIVILIAN_PERCENTAGE]: 0,
      },
      {
        Id: uuidv4(),
        [Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.DATA_FIELD]:
          Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.CASE_DEVELOPMENT,
        [Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.CIVILIAN_PERCENTAGE]: 0,
      },
      {
        Id: uuidv4(),
        [Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.DATA_FIELD]:
          Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.CASE_EXECUTION,
        [Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.CIVILIAN_PERCENTAGE]: 0,
      },
      {
        Id: uuidv4(),
        [Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.DATA_FIELD]:
          Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.CASE_CLOSURE,
        [Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.CIVILIAN_PERCENTAGE]: 0,
      },
      {
        Id: uuidv4(),
        [Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.DATA_FIELD]:
          Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.BUSINESS_SUSTAINING,
        [Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.CIVILIAN_PERCENTAGE]: 0,
      },
      {
        Id: uuidv4(),
        [Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.DATA_FIELD]: null,
        [Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.CIVILIAN_PERCENTAGE]: null,
      },
      {
        Id: uuidv4(),
        [Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.DATA_FIELD]:
          Supplemental_Workforce_Utils.SupplementalWorkforceDataFields.TOTAL_FTE_PERCENTAGE,
        [Supplemental_Workforce_Utils.CivilianPercentagesColumnHeaders.CIVILIAN_PERCENTAGE]: 0,
      },
    ]
    setSupplementalWorkforceTableRows(initialSupplementalWorkforceTableRows)
    setCivilianPercentagesTableRows(initialCivilianPercentageTableRows)
    switch (selectedTab) {
      case Supplemental_Workforce_Utils.Tabs.SUPPLEMENTAL_WORKFORCE_RFI:
        setSelectedTableRows(initialSupplementalWorkforceTableRows)
        break
      case Supplemental_Workforce_Utils.Tabs.CIVILIAN_PERCENTAGES:
        setSelectedTableRows(initialCivilianPercentageTableRows)
        break
      case Supplemental_Workforce_Utils.Tabs.BPC:
        setBpcFteCivValue(0)
        break
      default:
        break
    }
  }

  const updateTableCols = () => {
    switch (selectedTab) {
      case Supplemental_Workforce_Utils.Tabs.SUPPLEMENTAL_WORKFORCE_RFI:
        if (selectedRFI?.status && selectedRFI.status !== SubmitStatus.NOT_SUBMITTED && selectedRFI.status !== SubmitStatus.REJECTED) {
          setSelectedTableCols(disabledSupplementalWorkforceTableCols)
        } else setSelectedTableCols(supplementalWorkforceTableCols)
        break
      case Supplemental_Workforce_Utils.Tabs.CIVILIAN_PERCENTAGES:
        if (selectedRFI?.status && selectedRFI.status !== SubmitStatus.NOT_SUBMITTED && selectedRFI.status !== SubmitStatus.REJECTED) {
          setSelectedTableCols(disabledCivilianPercentageTableCols)
        } else setSelectedTableCols(civilianPercentageTableCols)
        break
      default:
        break
    }
  }

  const requestResubmission = async () => {
    setShowBusyDialog(true)
    const BASE_URL = selectedTab === Supplemental_Workforce_Utils.Tabs.BPC ? "BpcReductionRfis" : "SupplementalWorkforceRfis"
    if (selectedTab === Supplemental_Workforce_Utils.Tabs.SUPPLEMENTAL_WORKFORCE_RFI) {
      await apiCalls
        .getAll(`${BASE_URL}`, { [QueryParamTypes.FILTER]: `organizationId eq ${selectedOrg.id} and fiscalCycleId eq ${activeFiscalCycle.id}` })
        .then(async (allRFIs) => {
          const supplementalWorkforceRFIsForOrg = allRFIs.filter(
            (RFI) =>
              RFI.group !==
              Object.keys(Supplemental_Workforce_Utils.SupplementalWorkforceGroups)[
                Supplemental_Workforce_Utils.SupplementalWorkforceGroups.CIVILIAN_PERCENTAGE
              ]
          )
          if (supplementalWorkforceRFIsForOrg.length <= 0) return
          for (let RFI of supplementalWorkforceRFIsForOrg) {
            await apiCalls
              .put(`${BASE_URL}/${RFI.id}`, { ...RFI, status: SubmitStatus.REQUEST_RESUBMISSION })
              .then(() => {
                getSelectedRFI()
                setShowSnackbarSuccess(true)
                setSnackbarMessage("Resubmission requested for RFI!")
                fetchRFIDashboardObjectsFromDB(rfiOrganizationsMapping, allOrgs, activeFiscalCycle, rfiRecordCountMapping).then((data) => {
                  setRfiDashboardOrgRfiMappingContext(data)
                })
              })
              .catch(() => {
                setShowSnackbarError(true)
                setSnackbarMessage("Failed to request resubmission for RFI")
              })
              .finally(() => setShowBusyDialog(false))
          }
        })
      return
    }
    let body = selectedRFI
    delete body.organization
    delete body.fiscalCycle
    apiCalls
      .put(`${BASE_URL}/${selectedRFI.id}`, { ...body, status: SubmitStatus.REQUEST_RESUBMISSION })
      .then(() => {
        getSelectedRFI()
        setShowSnackbarSuccess(true)
        setSnackbarMessage("Resubmission requested for RFI!")
        fetchRFIDashboardObjectsFromDB(rfiOrganizationsMapping, allOrgs, activeFiscalCycle, rfiRecordCountMapping).then((data) => {
          setRfiDashboardOrgRfiMappingContext(data)
        })
      })
      .catch(() => {
        setShowSnackbarError(true)
        setSnackbarMessage("Failed to request resubmission for RFI")
      })
      .finally(() => setShowBusyDialog(false))
  }

  const handleInstModalClick = () => {
    // if (selectedTab === Supplemental_Workforce_Utils.Tabs.BPC) {
    setDisclaimerRFIModalOpen(true)
    // }
  }

  useEffect(() => {
    populateGlobalContext(globalContext)
  }, [])

  useEffect(() => {
    if (location.state?.selectedTab) {
      setSelectedTab(location.state.selectedTab)
    }
    if (location.state?.organization) {
      setSelectedOrgContext(location.state.organization)
    }
  }, [location])

  useEffect(() => {
    if (selectedOrg?.id) {
      getSelectedRFI()
      resetTableRows()
    }
  }, [selectedOrg])

  useEffect(() => {
    setSelectedTabRfiMappingKeyContext(SelectedTabToRFIMapping[selectedTab])
  }, [selectedTab])

  useEffect(() => {
    getSelectedRFI()
    if (selectedTab === Supplemental_Workforce_Utils.Tabs.CIVILIAN_PERCENTAGES) {
      setFilteredOrgListContext(allOrgs.filter((org) => RFIViewOrgMappings["/CivilianPercentages"].includes(org.name)))
    } else {
      setFilteredOrgListContext(allOrgs)
    }
    resetTableRows()
  }, [selectedTab, activeFiscalCycle])

  useEffect(() => {
    if (supplementalWorkforceTableRows.length > 0 && civilianPercentagesTableRows.length > 0) {
      initRowUpdate()
    }
  }, [supplementalWorkforceTableRows, civilianPercentagesTableRows])

  useEffect(() => {
    getTableSubmitStatus()
    updateTableCols()
    if (selectedOrg?.id && selectedRFI?.id) {
      updateTableRowsFromDB()
    }
    if (!inCivPercentageOrg() && selectedTab === Supplemental_Workforce_Utils.Tabs.CIVILIAN_PERCENTAGES) {
      setSelectedTab(Supplemental_Workforce_Utils.Tabs.SUPPLEMENTAL_WORKFORCE_RFI)
    }
  }, [selectedRFI])

  useEffect(() => {
    getSelectedRFI()
  }, [])

  // There's a handleTabChange already but not sure if it is used
  const handleSetSelectedTab = (event, newValue) => {
    setSelectedTab(newValue)
  }

  return (
    <Box
      container
      margin={2}
      spacing={2}
    >
      <Typography
        align="left"
        variant="h4"
      >
        Supplemental Workforce
      </Typography>
      <TabContext value={selectedTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleSetSelectedTab}
          >
            <Tab
              label={Supplemental_Workforce_Utils.Tabs.SUPPLEMENTAL_WORKFORCE_RFI}
              value={Supplemental_Workforce_Utils.Tabs.SUPPLEMENTAL_WORKFORCE_RFI}
            />
            {/* There's a bug with inCivPercentageOrg. Commenting this out just for the Oct 17 demo */}
            {/* <Tab
              label={Supplemental_Workforce_Utils.Tabs.CIVILIAN_PERCENTAGES}
              value={Supplemental_Workforce_Utils.Tabs.CIVILIAN_PERCENTAGES}
              hidden={!inCivPercentageOrg()}
            /> */}
            <Tab
              label={Supplemental_Workforce_Utils.Tabs.BPC}
              value={Supplemental_Workforce_Utils.Tabs.BPC}
            />
          </TabList>
        </Box>
      </TabContext>
      <div className="--RFI-table-actions">
        {/************************************************ [RFI Instructions Modal] ************************************************/}
        <SupplementalWorkforce_Instructions_Modal
          disclaimerRFIModalOpen={disclaimerRFIModalOpen}
          setDisclaimerRFIModalOpen={setDisclaimerRFIModalOpen}
        />
        <div>
          {selectedTab === Supplemental_Workforce_Utils.Tabs.BPC && (
            <Button
              variant="outlined"
              color="info"
              startIcon={<Help />}
              onClick={handleInstModalClick}
            >
              RFI Instructions
            </Button>
          )}
        </div>
        {/************************************** Import CSV file here ********************************************************/}
        <Stack
          direction="row"
          spacing={1}
        >
          <Button
            onClick={() => setShowRequestResubmissionWarningModal(true)}
            variant="outlined"
            hidden={submitStatus[selectedTab] !== SubmitStatus.SUBMITTED}
          >
            REQUEST RESUBMISSION
          </Button>
          <Button
            onClick={() => handleSubmitRFIData()}
            variant="contained"
            disabled={!canSubmitRFIData[selectedTab]}
          >
            SUBMIT RFI DATA
          </Button>
          <RfiStatusChip status={submitStatus[selectedTab] ? submitStatus[selectedTab] : SubmitStatus.NOT_SUBMITTED} />
        </Stack>
      </div>
      <div className="--RFI-table-container">
        {/* DataGrid */}
        {selectedTab !== Supplemental_Workforce_Utils.Tabs.BPC ? (
          <SWRFITab
            gridTitle={Supplemental_Workforce_Utils.TableNames[selectedTab]}
            tableNames={Supplemental_Workforce_Utils.TableNames[selectedTab]}
            selectedRows={selectedTableRows}
            selectedCols={selectedTableCols}
            showBusyDialog={showBusyDialog}
            handleRowUpdate={handleRowUpdate}
            canEditCell={canEditCell}
          />
        ) : (
          <div className="--RFI-table-container">
            <div className="satfa-special-case-container">
              <div className="field-container">
                <div className="satfa-special-case-enter-field">
                  <label
                    className="satfa-label"
                    htmlFor="-bpcFteCiv"
                  >
                    # BPC CIV FTEs to Reduce
                  </label>
                  <input
                    type="number"
                    disabled={selectedRFI?.status && !UploadSubmitEnabledStatuses.includes(selectedRFI.status)}
                    min={0}
                    value={bpcFteCivValue}
                    id="-bpcFteCiv"
                    className="satfa-input"
                    onChange={(e) => setBpcFteCivValue(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <RequestResubmissionWarningModal
        open={showRequestResubmissionWarningModal}
        setOpen={setShowRequestResubmissionWarningModal}
        requestResubmission={requestResubmission}
      />
      <SnackbarMessages
        showSnackbarSuccess={showSnackbarSuccess}
        setShowSnackbarSuccess={setShowSnackbarSuccess}
        showSnackbarError={showSnackbarError}
        setShowSnackbarError={setShowSnackbarError}
        snackbarMessage={snackbarMessage}
      />
      <SubmitRFIWarningModal
        showSubmitRFIWarningModal={showSubmitRFIWarningModal}
        setShowSubmitRFIWarningModal={setShowSubmitRFIWarningModal}
        msg={`If you submit RFI data, you won't be able to reupload data unless an administrator puts the ${selectedTab} ${
          selectedTab === Supplemental_Workforce_Utils.Tabs.BPC ? "RFI" : ""
        } back to a "Not Submitted" state in the ${selectedOrg.name} organization.`}
        submitRFIData={() => submitRFIData().then(() => getSelectedRFI())}
      />
    </Box>
  )
}

export default SupplementalWorkforce
