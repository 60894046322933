import { useState, useEffect, useRef } from "react"

// Libraries
import dayjs from "dayjs"
import { useQueries } from "@tanstack/react-query"

// Data
import { apiCalls } from "../../DataService"
import { useGet } from "../../../apis/query-test"

// MUI
import { DataGrid, GridToolbar } from "@mui/x-data-grid"
import { Close, Edit, AssignmentInd } from "@mui/icons-material"
import { Tooltip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Box, Typography, Divider } from "@mui/material"

// Custom
import NewUser from "./NewUser"
import SnackbarMessages from "../../SnackbarMessages"
import { UserOrgRoleMultipleSelect } from "./UserOrgRoleMultipleSelect"

// Styles
import { withStyles } from "@mui/styles"
import "./Users.css"
import Base from "../../elements/layout/Base"

const FMSOrganizationsTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "white",
    fontSize: 12,
  },
})(Tooltip)

const Users = () => {
  // ** --------------***     [ ~ API QUERIES ~ ]     ***-------------- **
  // ** --------------***     [ ~ ! QUERY TEST ! ~ ]     ***-------------- **
  const results = useQueries({
    queries: [
      { queryKey: ["Accounts"], queryFn: () => apiCalls.fetchData("Accounts", `?$filter=status eq Active`) },
      { queryKey: ["Pocs"], queryFn: () => apiCalls.fetchData("Pocs", "?$orderby=lastName asc") },
    ],
  })

  let accountsResponse = results[0]
  let pocsResponse = results[1]

  const getTest = useGet("FiscalCycles", "FiscalCycles")

  const [data, setData] = useState("")

  useEffect(() => {
    if (getTest.status == "success") {
      console.log("getTest: ", getTest)
      setData(getTest.data)
    }
  }, [getTest])

  // ** --------------***     [ ~ HOOKS ~ ]     ***-------------- **

  const selectedUserIdToDelete = useRef("")
  const [userTableRows, setUserTableRows] = useState([])
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isMultSelectModalOpen, setIsMultSelectModalOpen] = useState(false)
  const [editParams, setEditParams] = useState({})
  const [userParams, setUserParams] = useState({})
  const [userList, setUserList] = useState([])
  const [pocList, setPocList] = useState([])
  const [selectedUserId, setSelectedUserId] = useState("")
  const [userPocInput, setUserPocInput] = useState({})
  const [showDeleteUserWarningModal, setShowDeleteUserWarningModal] = useState(false)
  const [showSnackbarSuccess, setShowSnackbarSuccess] = useState(false)
  const [showSnackbarError, setShowSnackbarError] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")

  const [userData, setUserData] = useState("")

  // ** --------------***     [ ~ VARS ~ ]     ***-------------- **
  const userTableCols = [
    {
      field: "id",
      hide: true,
      headerClassName: "--grid-header",
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 2,
      headerClassName: "--grid-header",
      minWidth: 125,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 2,
      headerClassName: "--grid-header",
      minWidth: 125,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
      headerClassName: "--grid-header",
      minWidth: 250,
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 2,
      headerClassName: "--grid-header",
      minWidth: 125,
    },
    {
      field: "fmsOrganization",
      headerName: "FMS Organization",
      flex: 2,
      headerClassName: "--grid-header",
      minWidth: 175,
      renderCell: (params) => {
        // matchingPOC?.organizationList[0]?.organization?.name
        // params.value.forEach((error, index) => tooltipMsg += `${index + 1}) ${error}\n`)
        let tooltipMsg = `${params.row.fmsOrganization}`
        return (
          <FMSOrganizationsTooltip
            title={tooltipMsg}
            placement="bottom"
          >
            <div>{params?.row?.fmsOrganization ? params.row.fmsOrganization : ""}</div>
          </FMSOrganizationsTooltip>
        )
      },
    },
    {
      field: "lastLogin",
      headerName: "Last Login",
      flex: 2,
      headerClassName: "--grid-header",
      minWidth: 250,
      renderCell: (params) => {
        if (!params.value) return null
        return <div className="MuiDataGrid-cellContent">{dayjs(params.value).format("MM/DD/YYYY, h:mm:ss a")}</div>
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerClassName: "--grid-header",
      minWidth: 100,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      flex: 1,
      headerClassName: "--grid-header",
      minWidth: 150,
      renderCell: (params) => {
        return (
          <div className="edit-delete-buttons">
            <button style={params.id % 2 === 0 ? { backgroundColor: "#ddd" } : { backgroundColor: "white" }}>
              <Edit onClick={() => handleEditUser(params)} />
            </button>
            <button style={params.id % 2 === 0 ? { backgroundColor: "#ddd" } : { backgroundColor: "white" }}>
              <AssignmentInd onClick={() => handleUserOrgRoleMultipleSelect(params)} />
            </button>
            <button style={params.id % 2 === 0 ? { backgroundColor: "#ddd" } : { backgroundColor: "white" }}>
              <Close onClick={() => enableDeleteUserWarningModal(params.row.id)} />
            </button>
          </div>
        )
      },
    },
  ]

  // ** --------------***     [ ~ EFFECTS ~ ]     ***-------------- **
  useEffect(() => {
    !accountsResponse.isLoading && setUserData(accountsResponse.data)
  }, [accountsResponse.isLoading])

  useEffect(() => {
    userData && getAllUsers()
  }, [userData])

  useEffect(() => {
    !pocsResponse.isLoading && getAllOrgPOCs()
  }, [pocsResponse.isLoading])

  useEffect(() => {
    if (!pocsResponse.isLoading && userList) {
      getTableRows()
    }
  }, [userList])

  // ** --------------***     [ ~ FUNCTIONS ~ ]     ***-------------- **
  const getTableRows = () => {
    let rows = []
    userList.forEach((user) => {
      let poc = pocList.find((item) => item.userName === user.userName)
      if (poc) {
        let fmsOrgStr = ""
        if (poc?.organizationList) {
          poc.organizationList.forEach((fmsOrg, idx) => {
            if (idx >= poc.organizationList.length - 1) {
              fmsOrgStr += `${fmsOrg.organization.name}`
            } else {
              fmsOrgStr += `${fmsOrg.organization.name}, `
            }
          })
        }
        let row = {
          id: user.id,
          firstName: poc?.firstName,
          lastName: poc?.lastName,
          email: user?.email,
          phone: user?.phoneNumber,
          fmsOrganization: fmsOrgStr,
          lastLogin: user?.lastLoginDate,
          status: poc?.activeState ? "Active" : "Not Active",
          actions: null,
        }
        rows.push(row)
      }
    })
    setUserTableRows(rows)
  }

  const toggleEditModal = () => {
    if (isEditModalOpen) {
      setSelectedUserId("")
    }
    setIsEditModalOpen(!isEditModalOpen)
  }

  const toggleMultSelectModal = () => {
    if (isMultSelectModalOpen) {
      setSelectedUserId("")
    }
    setIsMultSelectModalOpen(!isMultSelectModalOpen)
  }

  const enableDeleteUserWarningModal = (userId) => {
    selectedUserIdToDelete.current = userId
    setShowDeleteUserWarningModal(true)
  }

  const handleEditUser = (params) => {
    setSelectedUserId(params.row.id)
    // Set edit params to autopopulate fields here
    setEditParams(params.row)
    setUserPocInput({
      firstName: params.row.firstName,
      lastName: params.row.lastName,
      email: params.row.email,
      phone: params.row.phone,
      disabled: params.row.status === "Active" ? false : true,
      role: "",
    })
    toggleEditModal()
  }

  const handleUserOrgRoleMultipleSelect = (params) => {
    setSelectedUserId(params.row.id)
    setUserParams(params.row)
    toggleMultSelectModal()
  }

  const handleDeleteUser = async (userId) => {
    await apiCalls.delete(`Accounts/${userId}`)
    getAllUsers()
  }

  const getAllUsers = () => {
    if (userData) {
      let allUsers = userData.data.map((item) => {
        let {
          id,
          userName,
          normalizedUserName,
          email,
          normalizedEmail,
          emailConfirmed,
          passwordHash,
          securityStamp,
          concurrencyStamp,
          phoneNumber,
          phoneNumberConfirmed,
          twoFactorEnabled,
          lockoutEnd,
          lockoutEnabled,
          accessFailedCount,
          lastLoginDate,
        } = item

        return {
          id,
          userName,
          normalizedUserName,
          email,
          normalizedEmail,
          emailConfirmed,
          passwordHash,
          securityStamp,
          concurrencyStamp,
          phoneNumber,
          phoneNumberConfirmed,
          twoFactorEnabled,
          lockoutEnd,
          lockoutEnabled,
          accessFailedCount,
          lastLoginDate,
        }
      })
      setUserList(allUsers)
    }
  }

  const getAllOrgPOCs = async () => {
    if (!pocsResponse.isLoading) {
      setPocList(pocsResponse.data.data)
    }
  }

  const refresh = async () => {
    let [allAccounts, allPOCs] = await Promise.all([accountsResponse.refetch(), pocsResponse.refetch()])

    setUserList(allAccounts.data.data)
    setPocList(allPOCs.data.data)

    getAllUsers()
  }

  // ** --------------***     [ ~ RENDER ~ ]     ***-------------- **
  return (
    <Box
      container
      margin={2}
      spacing={2}
    >
      <Typography
        align="left"
        variant="h4"
      >
        User Account Management
      </Typography>
      <Divider sx={{ padding: "15px" }} />
      <DataGrid
        rows={userTableRows}
        columns={userTableCols}
        density="compact"
        components={{ Toolbar: GridToolbar }}
        sx={{
          border: 2,
          borderColor: "black",
        }}
        getRowClassName={(params) => {
          if (params.indexRelativeToCurrentPage % 2 === 0) {
            return "even-row"
          } else return "odd-row"
        }}
        initialState={{
          columns: {
            columnVisibilityModel: { id: false },
          },
        }}
      />
      {/* Add/Edit User Modal */}
      <NewUser
        userPocInput={userPocInput}
        setUserPocInput={setUserPocInput}
        isEditModalOpen={isEditModalOpen}
        editParams={editParams}
        userList={userList}
        pocList={pocList}
        selectedUserId={selectedUserId}
        getAllUsers={getAllUsers}
        toggleEditModal={toggleEditModal}
        setShowSnackbarError={setShowSnackbarError}
        setShowSnackbarSuccess={setShowSnackbarSuccess}
        setSnackbarMessage={setSnackbarMessage}
        refetch={accountsResponse.refetch}
        getRows={getTableRows}
        refresh={refresh}
      />
      {/* Org/Role multiple select Modal */}
      <UserOrgRoleMultipleSelect
        isMultSelectModalOpen={isMultSelectModalOpen}
        toggleMultSelectModal={toggleMultSelectModal}
        userParams={userParams}
        selectedUserId={selectedUserId}
      />
      {/************************************************ [Replace CSV Data WARNING DIALOG WINDOW] ************************************************/}
      <Dialog
        open={showDeleteUserWarningModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete User Account</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Are you sure you wish to delete this user account? This action cannot be undone.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteUserWarningModal(false)}>Cancel</Button>
          <Button
            onClick={() => {
              handleDeleteUser(selectedUserIdToDelete.current)
              setShowDeleteUserWarningModal(false)
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarMessages
        showSnackbarSuccess={showSnackbarSuccess}
        setShowSnackbarSuccess={setShowSnackbarSuccess}
        showSnackbarError={showSnackbarError}
        setShowSnackbarError={setShowSnackbarError}
        snackbarMessage={snackbarMessage}
      />
    </Box>
  )
}

export default Users
