import React, { useState, useEffect, useContext } from "react"
import { useLocation } from "react-router-dom"
import "../RFI.css"
import "./css/SATFA.css"
import { Backdrop, CircularProgress, Typography, Box, Button, Stack, Divider } from "@mui/material"
import SATFA_Utils from "./SATFA_Utils"
import { apiCalls, QueryParamTypes } from "../../DataService"
import GlobalContext from "../../reducers/GlobalContext"
import _, { set } from "lodash"
import { SelectedTabToRFIMapping, SubmitStatus, UploadSubmitEnabledStatuses, populateGlobalContext } from "../../../Utils"
import { SubmitRFIWarningModal } from "../SubmitRFIWarningModal"
import SnackbarMessages from "../../SnackbarMessages"
import { RequestResubmissionWarningModal } from "../RequestResubmissionWarningModal"
import { fetchRFIDashboardObjectsFromDB, getRfiRecordCountMapping } from "../../DashboardMenu"
import AppContext from "../../../AppContext"
import RfiStatusChip from "../../custom/RfiStatusChip"

const SATFA = () => {
  const [submitStatus, setSubmitStatus] = useState(SubmitStatus.NOT_SUBMITTED)
  const [SATFASpecialCaseFormInput, setSATFASpecialCaseFormInput] = useState({})

  const [showSnackbarSuccess, setShowSnackbarSuccess] = useState(false)
  const [showSnackbarError, setShowSnackbarError] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [showSubmitRFIWarningModal, setShowSubmitRFIWarningModal] = useState(false)
  const [showRequestResubmissionWarningModal, setShowRequestResubmissionWarningModal] = useState(false)
  const [showBusyDialog, setShowBusyDialog] = useState(false)
  const [selectedRFI, setSelectedRFI] = useState({})
  const location = useLocation()

  const [disclaimerRFIModalOpen, setDisclaimerRFIModalOpen] = useState(false)

  const globalContext = useContext(GlobalContext)
  const {
    activeFiscalCycle,
    setSelectedOrgContext,
    userObj,
    allOrgs,
    rfiOrganizationsMapping,
    rfiRecordCountMapping,
    setRfiRecordCountMappingContext,
    setRfiDashboardOrgRfiMappingContext,
    setSelectedTabRfiMappingKeyContext,
  } = globalContext
  const context = useContext(AppContext)
  let { selectedOrg } = context

  const resetFormInput = () => {
    setSATFASpecialCaseFormInput({
      [SATFA_Utils.SATFADataFields.NUMBER_OF_IMS_STUDENTS]: 0,
      [SATFA_Utils.SATFADataFields.NUMBER_OF_SEATS]: 0,
      [SATFA_Utils.SATFADataFields.NUMBER_OF_SUFFIXES]: 0,
    })
  }

  const getTableSubmitStatus = () => {
    if (selectedOrg.id) {
      apiCalls
        .getAll(`SatfaSpecialCaseRfis`, {
          [QueryParamTypes.FILTER]: `OrganizationId eq ${selectedOrg.id} and FiscalCycleId eq ${activeFiscalCycle.id}`,
        })
        .then((data) => {
          if (data.length > 0) {
            setSubmitStatus(data[0]?.status ? data[0].status : SubmitStatus.NOT_SUBMITTED)
            setSelectedRFI(data[0])
          } else {
            setSubmitStatus(SubmitStatus.NOT_SUBMITTED)
            setSelectedRFI({})
            resetFormInput()
          }
        })
    }
  }

  const handleSATFASpecialCaseChange = (e, field) => {
    switch (field) {
      case SATFA_Utils.SATFADataFields.NUMBER_OF_IMS_STUDENTS:
        setSATFASpecialCaseFormInput({ ...SATFASpecialCaseFormInput, [SATFA_Utils.SATFADataFields.NUMBER_OF_IMS_STUDENTS]: Number(e.target.value) })
        break
      case SATFA_Utils.SATFADataFields.NUMBER_OF_SEATS:
        setSATFASpecialCaseFormInput({ ...SATFASpecialCaseFormInput, [SATFA_Utils.SATFADataFields.NUMBER_OF_SEATS]: Number(e.target.value) })
        break
      case SATFA_Utils.SATFADataFields.NUMBER_OF_SUFFIXES:
        setSATFASpecialCaseFormInput({ ...SATFASpecialCaseFormInput, [SATFA_Utils.SATFADataFields.NUMBER_OF_SUFFIXES]: Number(e.target.value) })
        break
    }
  }

  const makeDBCalls = async () => {
    apiCalls
      .getAll(`SatfaSpecialCaseRfis`, {
        [QueryParamTypes.FILTER]: `OrganizationId eq ${selectedOrg.id} and FiscalCycleId eq ${activeFiscalCycle.id}`,
      })
      .then((data) => {
        const SATFASpecialCaseBody = {
          organizationId: selectedOrg.id,
          fiscalCycleId: activeFiscalCycle.id,
          poc: userObj.name,
          [SATFA_Utils.SATFADataFields.NUMBER_OF_IMS_STUDENTS]: SATFASpecialCaseFormInput[SATFA_Utils.SATFADataFields.NUMBER_OF_IMS_STUDENTS],
          [SATFA_Utils.SATFADataFields.NUMBER_OF_SEATS]: SATFASpecialCaseFormInput[SATFA_Utils.SATFADataFields.NUMBER_OF_SEATS],
          [SATFA_Utils.SATFADataFields.NUMBER_OF_SUFFIXES]: SATFASpecialCaseFormInput[SATFA_Utils.SATFADataFields.NUMBER_OF_SUFFIXES],
          status: SubmitStatus.SUBMITTED,
        }
        if (data.length > 0) {
          apiCalls.put(`SatfaSpecialCaseRfis/${selectedRFI.id}`, { ...SATFASpecialCaseBody, id: selectedRFI.id }).then(() => {})
        } else {
          apiCalls.post(`SatfaSpecialCaseRfis`, SATFASpecialCaseBody).then(() => {})
        }
      })
  }

  const getRFIDataFromDB = () => {
    apiCalls.getById(`SatfaSpecialCaseRfis`, selectedRFI.id).then((data) => {
      setSATFASpecialCaseFormInput({
        [SATFA_Utils.SATFADataFields.NUMBER_OF_IMS_STUDENTS]: data.numberOfIms_Students,
        [SATFA_Utils.SATFADataFields.NUMBER_OF_SEATS]: data.numberOfSeats,
        [SATFA_Utils.SATFADataFields.NUMBER_OF_SUFFIXES]: data.numberOfSuffixes,
      })
    })
  }

  // Overwrite any existing csv entries in the DB with the new data
  const submitRFIData = async () => {
    //Make sure an organization is selected
    if (!selectedOrg.id) {
      setShowSnackbarError(true)
      setSnackbarMessage("Organization not selected, please select an organization from the Organization POCs page")
      return
    }
    // Update SATFA Special Case RFI table
    setShowBusyDialog(true)
    try {
      await makeDBCalls()
      setSubmitStatus(SubmitStatus.SUBMITTED)
      setShowSnackbarSuccess(true)
      setSnackbarMessage(`Successfully uploaded SATFA Special Case data!`)
      if (activeFiscalCycle?.id) {
        getRfiRecordCountMapping(activeFiscalCycle).then((mapping) => {
          setRfiRecordCountMappingContext(mapping)
          fetchRFIDashboardObjectsFromDB(rfiOrganizationsMapping, allOrgs, activeFiscalCycle, mapping).then((data) => {
            setRfiDashboardOrgRfiMappingContext(data)
          })
        })
      }
    } catch {
      setShowSnackbarError(true)
      setSnackbarMessage(`Unable to submit SATFA Special Case data`)
    } finally {
      setShowBusyDialog(false)
    }
  }

  const requestResubmission = async () => {
    // Update SATFA Special Case RFI table
    apiCalls.getById(`SatfaSpecialCaseRfis`, selectedRFI.id).then((data) => {
      delete data.fiscalCycle
      delete data.organization
      apiCalls
        .put(`SatfaSpecialCaseRfis/${selectedRFI.id}`, { ...data, status: SubmitStatus.REQUEST_RESUBMISSION })
        .then(() => {
          setSubmitStatus(SubmitStatus.REQUEST_RESUBMISSION)
          setShowSnackbarSuccess(true)
          setSnackbarMessage(`Successfully requested resubmission of SATFA Special Case data!`)
          fetchRFIDashboardObjectsFromDB(rfiOrganizationsMapping, allOrgs, activeFiscalCycle, rfiRecordCountMapping).then((data) => {
            setRfiDashboardOrgRfiMappingContext(data)
          })
        })
        .catch((e) => {
          console.error(e)
          setShowSnackbarError(true)
          setSnackbarMessage(`Unable to request resubmission of SATFA Special Case data`)
        })
    })
  }

  useEffect(() => {
    populateGlobalContext(globalContext)
  }, [])

  useEffect(() => {
    if (location.state?.organization) {
      setSelectedOrgContext(location.state.organization)
    }
  }, [location])

  useEffect(() => {
    if (activeFiscalCycle?.id && selectedOrg?.id) {
      getTableSubmitStatus()
    }
  }, [activeFiscalCycle, selectedOrg])

  useEffect(() => {
    if (selectedRFI.id) {
      getRFIDataFromDB()
    }
  }, [selectedRFI])

  useEffect(() => {
    resetFormInput()
    setSelectedTabRfiMappingKeyContext(SelectedTabToRFIMapping.SATFA)
  }, [])

  return (
    <Box
      container
      margin={2}
      spacing={2}
    >
      <Typography
        align="center"
        variant="h4"
      >
        SATFA Special Case
      </Typography>
      <Divider />
      <div className="--RFI-table-actions">
        <div></div>
        <Stack
          direction="row"
          spacing={1}
        >
          <Button
            onClick={() => setShowRequestResubmissionWarningModal(true)}
            hidden={submitStatus !== SubmitStatus.SUBMITTED}
            variant="outlined"
          >
            REQUEST RESUBMISSION
          </Button>
          <Button
            onClick={() => setShowSubmitRFIWarningModal(true)}
            className="--RFI-table-action-button"
            disabled={!UploadSubmitEnabledStatuses.includes(submitStatus)}
            variant="outlined"
          >
            SUBMIT RFI DATA
          </Button>
          <RfiStatusChip status={submitStatus ? submitStatus : SubmitStatus.NOT_SUBMITTED} />
        </Stack>
      </div>
      <Divider />
      <div className="--RFI-table-container">
        <div className="satfa-special-case-enter-field">
          <label
            className="satfa-label"
            htmlFor="-imsStudents"
          >
            # IMS / Students:
          </label>
          <input
            type="number"
            disabled={submitStatus !== SubmitStatus.NOT_SUBMITTED && submitStatus !== SubmitStatus.REJECTED}
            min={0}
            value={Number(Math.abs(SATFASpecialCaseFormInput[SATFA_Utils.SATFADataFields.NUMBER_OF_IMS_STUDENTS])).toString()}
            id="-imsStudents"
            className="satfa-input"
            onChange={(e) => handleSATFASpecialCaseChange(e, SATFA_Utils.SATFADataFields.NUMBER_OF_IMS_STUDENTS)}
          />
        </div>
        <div className="satfa-special-case-enter-field">
          <label
            className="satfa-label"
            htmlFor="-imsStudents"
          >
            # Seats:
          </label>
          <input
            type="number"
            disabled={submitStatus !== SubmitStatus.NOT_SUBMITTED && submitStatus !== SubmitStatus.REJECTED}
            min={0}
            value={Number(Math.abs(SATFASpecialCaseFormInput[SATFA_Utils.SATFADataFields.NUMBER_OF_SEATS])).toString()}
            id="-imsStudents"
            className="satfa-input"
            onChange={(e) => handleSATFASpecialCaseChange(e, SATFA_Utils.SATFADataFields.NUMBER_OF_SEATS)}
          />
        </div>
        <div className="satfa-special-case-enter-field">
          <label
            className="satfa-label"
            htmlFor="-imsStudents"
          >
            # Suffixes:
          </label>
          <input
            type="number"
            disabled={submitStatus !== SubmitStatus.NOT_SUBMITTED && submitStatus !== SubmitStatus.REJECTED}
            min={0}
            value={Number(Math.abs(SATFASpecialCaseFormInput[SATFA_Utils.SATFADataFields.NUMBER_OF_SUFFIXES])).toString()}
            id="-imsStudents"
            className="satfa-input"
            onChange={(e) => handleSATFASpecialCaseChange(e, SATFA_Utils.SATFADataFields.NUMBER_OF_SUFFIXES)}
          />
        </div>
      </div>
      <SnackbarMessages
        showSnackbarSuccess={showSnackbarSuccess}
        setShowSnackbarSuccess={setShowSnackbarSuccess}
        showSnackbarError={showSnackbarError}
        setShowSnackbarError={setShowSnackbarError}
        snackbarMessage={snackbarMessage}
      />
      {/********************************************** [LOADING INDICATOR] ************************************************************/}
      <Backdrop
        style={{ zIndex: 1, color: "#fff" }}
        open={showBusyDialog}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <RequestResubmissionWarningModal
        open={showRequestResubmissionWarningModal}
        setOpen={setShowRequestResubmissionWarningModal}
        requestResubmission={requestResubmission}
      />
      <SubmitRFIWarningModal
        showSubmitRFIWarningModal={showSubmitRFIWarningModal}
        setShowSubmitRFIWarningModal={setShowSubmitRFIWarningModal}
        msg={`If you submit RFI data, you won't be able to reupload data unless an administrator puts the SATFA table back to a "Not Submitted" state in the ${selectedOrg.name} organization.`}
        submitRFIData={submitRFIData}
      />
    </Box>
  )
}

export default SATFA
