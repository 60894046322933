import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  typography: {
    pageHeader: {
      fontFamily: "Zilla Slab",
      fontWeight: 300,
      fontSize: "1.3rem",
      display: "flex",
    },
    pageSubheader: {
      color: "white",
      fontFamily: "Roboto",
    },
    pageText: {
      color: "rgba(0, 0, 0, 0.6)",
      fontFamily: "Roboto",
    },
    dialogTitle: {
      fontFamily: "Manrope",
      fontWeight: "bold",
    },
    dialogSubtitle: {
      fontSize: ".9em",
      fontWeight: "bold",
    },
    navbarBtn: {
      fontSize: ".8rem",
      color: "white",
      textOverflow: "ellipsis",
    },
    navbarMenuItem: {
      textOverflow: "ellipsis",
    },
    navbarMenuHeader: {
      fontSize: ".9rem",
      fontFamily: "Manrope",
      fontWeight: "800",
      padding: "12px",
      letterSpacing: ".4px",
    },
    dataGridTitle: {
      fontSize: "1rem",
      fontWeight: 600,
      fontFamily: "Roboto",
    },
  },
  palette: {
    primary: {
      main: "#225782",
    },
    secondary: {
      main: "#f44336",
    },
    cwat: {
      light: "#f4ecd6",
      // main: "#7C90B3",
      // main: "#1c2892",
      main: "#95ADB6",
      dark: "#202b29",
    },
  },
  components: {
    MuiDataGrid: {
      variants: [
        {
          props: { variant: "styledDataGrid" },
          style: {
            fontFamily: "Roboto",
            minHeight: "100px",
            height: "100%",
            maxHeight: "75vh",
            fontSize: ".8rem",
            "& .MuiDataGrid-columnHeaders": {},
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
            "& .MuiDataGrid-cell": {
              padding: "10px",
            },
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            "& .MuiTablePagination-selectLabel": {
              marginBottom: "0px",
            },
          },
        },
      ],
    },
    MuiDivider: {
      variants: [
        {
          props: { variant: "navMenu" },
          style: {
            backgroundColor: "gray",
            marginTop: "4px",
          },
        },
      ],
    },
    MuiMenuItem: {
      variants: [
        {
          props: { variant: "navMenu" },
          style: {
            fontFamily: "Manrope",
            fontSize: ".9rem",
            paddingRight: "15px",
            paddingLeft: "15px",
            minWidth: "250px",
            ".MuiListItemIcon-root": {
              minWidth: "0px",
              width: "7%",
              fontSize: "5px",
            },
          },
        },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "styledBtn" },
          style: {
            width: "100%",
            color: "white",
            backgroundColor: "#225782f4",
            "&:hover": {
              backgroundColor: "#225782",
            },
            "&:disabled": {
              backgroundColor: "#e4e4e4",
              color: "gray",
            },
          },
        },
        {
          props: { variant: "submitBtn" },
          style: {
            color: "white",
            backgroundColor: "#225782",
            "&:hover": {
              backgroundColor: "#1d486c",
            },
            "&:disabled": {
              backgroundColor: "#e4e4e4",
              color: "gray",
            },
          },
        },

        {
          props: { variant: "peopm" },
          style: {
            fontSize: ".8rem",
            color: "white",
            padding: "3px 6px",
            backgroundColor: "#225782",
            "&:hover": {
              backgroundColor: "#1d486c",
            },
            "&:disabled": {
              backgroundColor: "#e4e4e4",
              color: "gray",
            },
          },
        },
      ],
    },
    MuiIconButton: {
      variants: [
        {
          props: { variant: "gridAddBtn" },
          style: {
            fontSize: "1.2rem",
            color: "white",
            height: "35px",
            borderRadius: "5px",
            backgroundColor: "#225782f4",
            "&:hover": {
              backgroundColor: "#225782",
            },
            "&:disabled": {
              backgroundColor: "#e4e4e4",
              color: "gray",
            },
          },
        },
      ],
    },
    MuiTab: {
      variants: [
        {
          props: { variant: "tabbedView" },
          style: {
            fontFamily: "Bitter",
            fontSize: "1rem",
            fontWeight: "bold",
          },
        },
      ],
    },
  },
})

export default theme
