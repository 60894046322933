// MUI Icons
import { AdminPanelSettings } from "@mui/icons-material"
import ChecklistIcon from "@mui/icons-material/Checklist"
import ContentPasteIcon from "@mui/icons-material/ContentPaste"
import PeopleIcon from "@mui/icons-material/People"
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart"
import CircleIcon from "@mui/icons-material/Circle"
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material"

export const menuOptions = [
  {
    title: "Claimant Dashboard",
    icon: <ContentPasteIcon />,
    pathname: "/dashboard",
    expand: false,
  },
  {
    title: "Workload",
    icon: <StackedBarChartIcon />,
    expand: true,
    expandOptions: [
      {
        title: "DSAMS/CISIL",
        pathname: "/DSAMS-CISIL-RFIs",
      },
      {
        title: "ACC",
        pathname: "/ACC-RFIs",
      },
      {
        title: "SATFA",
        pathname: "/SATFA-RFIs",
      },
    ],
  },
  {
    title: "Workload Validation",
    icon: <ChecklistIcon />,
    expand: true,
    expandOptions: [
      {
        title: "USACE",
        pathname: "PEO-PM-RFIs",
      },
      {
        title: "PEO PM",
        pathname: "PEO-PM-RFIs",
      },
      {
        title: "SATMO",
        pathname: "PEO-PM-RFIs",
      },
    ],
  },
  {
    title: "Workforce",
    icon: <PeopleIcon />,
    expand: true,
    expandOptions: [
      {
        title: "GFEBS/CFEMS",
        pathname: "GFEBS-CEFMS-RFIs",
      },
      {
        title: "Supplemental Workforce",
        pathname: "SupplementalWorkforce-RFIs",
      },
    ],
  },
  {
    title: "Admin",
    icon: <AdminPanelSettings />,
    expand: true,
    expandOptions: [
      {
        title: "FMS Org POCs",
        pathname: "organization-POCs",
      },
      {
        org: "DASA DE&C",
        pathname: "users",
        title: "Users",
      },
      {
        org: "DASA DE&C",
        pathname: "SAPBR",
        title: "SAPBR",
      },
      {
        org: "DASA DE&C",
        pathname: "TDA-RFIs",
        title: "TDA",
      },
      {
        org: "DASA DE&C",
        pathname: "FiscalCycles",
        title: "Fiscal Cycles",
      },
      {
        org: "DASA DE&C",
        pathname: "Dashboards",
        title: "Dashboards",
      },
      {
        org: "DASA DE&C",
        pathname: "Models",
        title: "All Models",
      },
      {
        org: "DASA DE&C",
        pathname: "Reference",
        title: "Reference Tables",
      },
    ],
  },
]

export const getExpandMenuItems = (options, handleNavigate) => {
  return options.map((item) => {
    return (
      <ListItemButton onClick={() => handleNavigate(item?.pathname)}>
        <ListItemIcon sx={{ display: "flex", justifyContent: "flex-end", marginRight: "8px" }}>
          <CircleIcon sx={{ fontSize: "5px" }} />
        </ListItemIcon>
        <ListItemText primary={item.title} />
      </ListItemButton>
    )
  })
}
