// DEPRECATED: This code should be unused

import { useState, useEffect, useContext, useRef } from "react"

// Libraries
import { v4 as uuidv4 } from "uuid"
import { Link, useHistory } from "react-router-dom"

// Data
import GlobalContext from "./reducers/GlobalContext"
import { apiCalls, QueryParamTypes } from "./DataService"

// Context
import AppContext from "../AppContext"

// MUI
import { IconButton, Button, Box, Typography, FormControl, InputLabel, OutlinedInput } from "@mui/material"

// Custom
import GFEBS_CEFMS_Table_Utils from "./RFIs/GFEBS-CEFMS/GFEBS_CEFMS_Table_Utils"
import logo from "../CWATImages/dasadec-logo.png"
import { logosArr } from "./Organizations/OrganizationPOCs"
import { OrganizationSelectDropdown } from "./Organizations/OrganizationSelectDropdown"
import { ClaimantRfisMenu } from "./navbar/ClaimaintRfisMenu"
import { AdminMenu } from "./navbar/AdminMenu"
import { DataUpdateBtn } from "./navbar/DataUpdateBtn"

// Styles
import "../css/index.css"
import "./navbar/styles/DashboardMenuStyles.css"

// Vars
import { ApplicationPaths } from "./api-authorization/ApiAuthorizationConstants"
import {
  CurrentFiscalYear,
  ActiveState,
  RFIViewOrgMappings,
  RFIOrgMappingAPITypes,
  RFIGroupings,
  SubmitStatusArr,
  RFIFieldCheckStatus,
  RFIGroupingAPITypes,
  SelectedTabToRFIMapping,
  SubmitStatus,
  SubmitStatusIdx,
} from "../Utils"

export async function getRfiRecordCountMapping(selectedFiscalCycle) {
  if (selectedFiscalCycle?.id) {
    const rfiRecordCountMapping = await apiCalls.getById(`RfiDashboard`, selectedFiscalCycle.id).then((dashboard) => {
      return dashboard
    })
    return rfiRecordCountMapping
  }
  return null
}

export const fetchRFIDashboardObjectsFromDB = async (rfiOrganizationsMapping, allOrgs, selectedFiscalCycle, rfiRecordCountMapping) => {
  let tmpRfiDashboardOrgRfiMapping = {}
  for (const rfiGrouping of Object.values(RFIGroupings)) {
    let tmpRfiGrouping = []
    for (const rfiMappingObj of RFIGroupingAPITypes[rfiGrouping]) {
      const rfiApiType = rfiMappingObj.apiType
      const rfiMappingName = rfiMappingObj.rfiMappingName
      let queryParams = {
        [QueryParamTypes.EXPAND]: "organization",
        [QueryParamTypes.FILTER]: `fiscalCycleId eq ${selectedFiscalCycle.id}`,
      }
      let rfiMappingOrgs = []
      Object.keys(rfiOrganizationsMapping).forEach((table) => {
        if (table === rfiMappingName) {
          rfiMappingOrgs = rfiOrganizationsMapping[table]
        }
      })
      if (rfiMappingName === "gfebsReport1")
        queryParams = {
          ...queryParams,
          [QueryParamTypes.FILTER]: queryParams[QueryParamTypes.FILTER].concat(` and reportType eq '${GFEBS_CEFMS_Table_Utils.ReportType.GFEBS_R1}'`),
        }
      else if (rfiMappingName === "gfebsReport2")
        queryParams = {
          ...queryParams,
          [QueryParamTypes.FILTER]: queryParams[QueryParamTypes.FILTER].concat(` and reportType eq '${GFEBS_CEFMS_Table_Utils.ReportType.GFEBS_R2}'`),
        }
      else if (rfiMappingName === "civilianPercentages")
        queryParams = { ...queryParams, [QueryParamTypes.FILTER]: queryParams[QueryParamTypes.FILTER].concat(` and group eq 'CIVILIAN_PERCENTAGE'`) }
      else if (rfiMappingName === "supplementalWorkforce")
        queryParams = { ...queryParams, [QueryParamTypes.FILTER]: queryParams[QueryParamTypes.FILTER].concat(` and group ne 'CIVILIAN_PERCENTAGE'`) }
      await apiCalls
        .getAll(`${rfiApiType}`, queryParams)
        .then(async (data) => {
          if (data) {
            let rows = []
            for (const org of rfiMappingOrgs) {
              let associationsList = []
              if (rfiGrouping === RFIGroupings.PEOPM_SATMO_USACE) {
                const orgId = allOrgs.find((o) => o.name === org)?.id
                const organization = await apiCalls.getById(`Organizations`, orgId)
                associationsList =
                  rfiMappingName === "usace"
                    ? organization?.dsamsPrepActDataAssociationsList?.concat(organization?.cisilDataDescriptionWorkloadList)
                    : organization?.dsamsPrepActDataAssociationsList
              }
              const orgRfi = data.find((rfi) => rfi.organization.name === org)
              let row = {
                organization: org,
                Id: orgRfi?.id ? orgRfi.id : uuidv4(),
                rfiMappingName: rfiMappingName,
                poc: null,
                status: null,
                dateLastModified: null,
                rowCount: null,
                check1: null,
                check2: null,
                notes: null,
              }
              if (orgRfi) {
                let rowCount = 0
                let prevStatus = null
                let status = null
                if (rfiGrouping === RFIGroupings.PEOPM_SATMO_USACE) {
                  rowCount = associationsList ? associationsList?.length : 0
                  if (orgRfi?.status !== SubmitStatusIdx.NOT_GENERATED && orgRfi?.status !== SubmitStatusIdx.GENERATED) {
                    prevStatus = SubmitStatusArr[orgRfi.status]
                    status = SubmitStatusArr[orgRfi.status]
                  } else {
                    prevStatus = rowCount > 0 ? SubmitStatus.GENERATED : SubmitStatus.NOT_GENERATED
                    status = rowCount > 0 ? SubmitStatus.GENERATED : SubmitStatus.NOT_GENERATED
                  }
                } else {
                  rowCount = rfiRecordCountMapping[rfiMappingName].find((rfi) => rfi.organization === org)?.records
                    ? rfiRecordCountMapping[rfiMappingName].find((rfi) => rfi.organization === org).records
                    : 0
                  prevStatus = SubmitStatusArr[orgRfi.status]
                  status = SubmitStatusArr[orgRfi.status]
                }
                row = {
                  ...row,
                  Id: orgRfi.id,
                  poc: orgRfi?.poc ? orgRfi.poc : null,
                  prevStatus,
                  status,
                  dateLastModified: rowCount > 0 ? orgRfi.modifiedDate : null,
                  rowCount,
                  check1: RFIFieldCheckStatus.NOT_STARTED,
                  check2: RFIFieldCheckStatus.NOT_STARTED,
                  prevNotes: orgRfi.comments,
                  notes: orgRfi.comments,
                }
              }
              rows?.push(row)
            }
            tmpRfiGrouping.push({ ...rfiMappingObj, rows })
          } else tmpRfiGrouping.push(rfiMappingObj)
        })
        .catch((e) => {
          console.error(e)
          tmpRfiGrouping.push(rfiMappingObj)
        })
        .finally(() => {
          tmpRfiDashboardOrgRfiMapping[rfiGrouping] = tmpRfiGrouping
        })
    }
  }
  return tmpRfiDashboardOrgRfiMapping
}

export const fetchFTEDashboardObjectsFromDB = async (initObj, allOrgs, selectedFiscalCycle) => {
  const tmpOrgRfiMappingRFIObjects = { ...initObj }

  for (const rfi of Object.keys(tmpOrgRfiMappingRFIObjects)) {
    const orgs = Object.keys(tmpOrgRfiMappingRFIObjects[rfi])

    for (const org of orgs) {
      if (tmpOrgRfiMappingRFIObjects[rfi][org]) {
        let apiTableName = RFIOrgMappingAPITypes[rfi]

        // ** NEW
        if (rfi === "usaceHCaseException") {
          apiTableName = "UsaceHCaseExceptionRfis"
        }
        if (rfi === "usaceScip") {
          apiTableName = "UsaceScipRfis"
        }
        // ** END NEW ** //

        const orgId = allOrgs.find((orgObj) => orgObj.name === org).id
        let queryParams = {
          [QueryParamTypes.EXPAND]: "organization",
          [QueryParamTypes.FILTER]: `organizationId eq ${orgId} and fiscalCycleId eq ${selectedFiscalCycle.id}`,
        }

        if (rfi === "gfebsReport1")
          queryParams = {
            ...queryParams,
            [QueryParamTypes.FILTER]: queryParams[QueryParamTypes.FILTER] + ` and reportType eq '${GFEBS_CEFMS_Table_Utils.ReportType.GFEBS_R1}'`,
          }
        else if (rfi === "gfebsReport2")
          queryParams = {
            ...queryParams,
            [QueryParamTypes.FILTER]: queryParams[QueryParamTypes.FILTER] + ` and reportType eq '${GFEBS_CEFMS_Table_Utils.ReportType.GFEBS_R2}'`,
          }
        else if (rfi === "civilianPercentages")
          queryParams = { ...queryParams, [QueryParamTypes.FILTER]: queryParams[QueryParamTypes.FILTER] + ` and group eq 'CIVILIAN_PERCENTAGE'` }
        else if (rfi === "supplementalWorkforce")
          queryParams = { ...queryParams, [QueryParamTypes.FILTER]: queryParams[QueryParamTypes.FILTER] + ` and group ne 'CIVILIAN_PERCENTAGE'` }
        await apiCalls.getAll(`${apiTableName}`, queryParams).then((data) => {
          tmpOrgRfiMappingRFIObjects[rfi][org] = data[0] ? data[0] : null
        })
      }
    }
  }
  return tmpOrgRfiMappingRFIObjects
}

export const createFTEDashboardInitObj = (rfiOrganizationsMapping) => {
  if (Object.keys(rfiOrganizationsMapping).length > 0) {
    // Initialize the global fteDashboardOrgRfiMapping object
    const tmpOrgRfiMappingRFIObjects = {}
    for (const key of Object.keys(rfiOrganizationsMapping)) {
      tmpOrgRfiMappingRFIObjects[key] = {}
      const rfiOrgs = rfiOrganizationsMapping[key]
      for (const orgName of rfiOrgs) {
        if (!tmpOrgRfiMappingRFIObjects[key][orgName]) {
          tmpOrgRfiMappingRFIObjects[key][orgName] = {}
        }
      }
    }
    return tmpOrgRfiMappingRFIObjects
  }
}

// DASHBOARD MENU START
const DashboardMenu = ({ clickTarget }) => {
  const history = useHistory()
  const context = useContext(AppContext)
  let { selectedFY } = context
  const globalContext = useContext(GlobalContext)
  const {
    userObj,
    allOrgs,
    rfiOrganizationsMapping,
    rfiRecordCountMapping,
    selectedOrg,
    setUserObjectContext,
    setIsLoggedInContext,
    setSelectedFiscalCycleContext,
    setRfiOrganizationsMappingContext,
    setFteDashboardOrgRfiMappingContext,
    setRfiDashboardOrgRfiMappingContext,
    setAllOrgsContext,
    setSelectedOrgContext,
    setRfiRecordCountMappingContext,
    setSelectedTabRfiMappingKeyContext,
  } = globalContext

  const [showClaimantWorkloadRFIsDropdown, setShowClaimantWorkloadRFIsDropdown] = useState(false)
  const [showClaimantWorkforceRFIsDropdown, setShowClaimantWorkforceRFIsDropdown] = useState(false)
  const [showAdminDropdown, setShowAdminDropdown] = useState(false)

  // ** --------------***     [ ~ EFFECTS ~ ]     ***-------------- **
  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    const tmpOrgRfiMappingRFIObjects = createFTEDashboardInitObj(rfiOrganizationsMapping)

    if (allOrgs.length > 0 && selectedFY?.id && Object.keys(rfiOrganizationsMapping).length > 0 && Object.keys(rfiRecordCountMapping).length > 0) {
      populateOrgRfiMappingRFIObjects(tmpOrgRfiMappingRFIObjects)
      fetchRFIDashboardObjectsFromDB(rfiOrganizationsMapping, allOrgs, selectedFY, rfiRecordCountMapping).then((data) => {
        setRfiDashboardOrgRfiMappingContext(data)
      })
    }
  }, [rfiOrganizationsMapping, allOrgs, selectedFY, rfiRecordCountMapping])

  useEffect(() => {
    if (clickTarget?.className !== "navbar-button") {
      if (showClaimantWorkforceRFIsDropdown) {
        setShowClaimantWorkforceRFIsDropdown(false)
      }
      if (showClaimantWorkloadRFIsDropdown) {
        setShowClaimantWorkloadRFIsDropdown(false)
      }
      if (showAdminDropdown) {
        setShowAdminDropdown(false)
      }
    }
  }, [clickTarget])

  // ** --------------***     [ ~ FUNCTIONS ~ ]     ***-------------- **
  const init = async () => {
    // Get user object / redirect to login
    getUser()

    // Get all organizations
    if (allOrgs.length <= 0 || !selectedOrg?.id) {
      apiCalls.getAll(`Organizations`, { [QueryParamTypes.ORDER_BY]: "name" }).then((tempOrgList) => {
        // Add org logo relative path to data object
        tempOrgList = tempOrgList.map((org) => {
          return { ...org, logo: logosArr.find((logo) => logo.name === org.name) }
        })
        setAllOrgsContext(tempOrgList)
      })
    }

    if (!selectedOrg?.id && localStorage.getItem("selectedOrg") != null) {
      setTargetOrganizationInGlobalContext(localStorage.getItem("selectedOrg"))
    }

    apiCalls.getAll(`RfiOrganizationsMappings`, {}).then((data) => {
      setRfiOrganizationsMappingContext(data)
    })
  }

  const getUser = async () => {
    if (!userObj?.sub) {
      apiCalls
        .getAll(`Accounts/me`, {})
        .then((loggedInUser) => {
          setUserObjectContext(loggedInUser)
          setIsLoggedInContext(loggedInUser?.sub ? true : false)
          if (!loggedInUser?.sub) {
            window.location.replace(ApplicationPaths.IdentityLoginPath)
          }
        })
        .catch(() => {
          window.location.replace(ApplicationPaths.IdentityLoginPath)
        })
    }
  }

  const setTargetOrganizationInGlobalContext = (targetOrg) => {
    apiCalls.getAll(`Organizations`, {}).then((orgs) => {
      const targetOrgAPI = orgs.find((org) => org.name === targetOrg)
      if (targetOrgAPI?.id) {
        apiCalls.getById(`Organizations`, targetOrgAPI.id).then((org) => {
          setSelectedOrgContext({ ...org, logo: logosArr.find((logo) => logo.name === targetOrgAPI.name) })
        })
      }
    })
  }

  const populateOrgRfiMappingRFIObjects = (initObj) => {
    fetchFTEDashboardObjectsFromDB(initObj, allOrgs, selectedFY).then((data) => {
      setFteDashboardOrgRfiMappingContext(data)
    })
  }

  const handleWorkloadClick = (org, pathname) => {
    setTargetOrganizationInGlobalContext(org)
    history.push({
      pathname: pathname,
      state: { organization: org },
    })
  }

  const handleWorkforceClick = (pathname) => {
    setShowClaimantWorkforceRFIsDropdown(false)
    history.push(pathname)
  }

  const handleAdminClick = (org, pathname) => {
    if (!org) {
      history.push({
        pathname: pathname,
      })
      setTargetOrganizationInGlobalContext(org)
    } else {
      setTargetOrganizationInGlobalContext(org)
      history.push({
        pathname: pathname,
        state: { organization: org },
      })
    }
  }

  return (
    <Box className="topnav">
      {/* LEFT-SIDE NAV */}
      <Box className="topNavImgBox">
        <IconButton
          component={Link}
          to="/"
        >
          <img
            className="topNavBtn"
            style={{
              minHeight: "65px",
            }}
            src={logo}
            alt="#"
          />
        </IconButton>
      </Box>

      <Box
        className="navBox"
        style={{ display: "flex", flexDirection: "row", width: "95vw" }}
      >
        <Box
          className="topNavBox"
          style={{
            justifyContent: "flex-start",
          }}
          sx={{}}
        >
          {/* FMS ORG POCS BUTTON */}
          <Button
            className="topNavBtn dashBtn"
            variant="outlined"
            onClick={() => history.push("/organization-POCs")}
            sx={{
              borderColor: "white",
              marginRight: "5px",
            }}
          >
            <Typography
              variant="navbarBtn"
              noWrap
            >
              FMS Org POCs
            </Typography>
          </Button>

          {/* CLAIMAINT RFIs MENU */}
          <ClaimantRfisMenu
            handleWorkloadClick={handleWorkloadClick}
            handleWorkforceClick={handleWorkforceClick}
            setTargetOrganizationInGlobalContext={setTargetOrganizationInGlobalContext}
            setSelectedTabRfiMappingKeyContext={setSelectedTabRfiMappingKeyContext}
            SelectedTabToRFIMapping={SelectedTabToRFIMapping}
            RFIViewOrgMappings={RFIViewOrgMappings}
            selectedOrg={selectedOrg}
          />

          {/* ADMIN MENU DROPDOWN */}
          <AdminMenu handleClick={handleAdminClick} />
        </Box>

        {/* RIGHT-SIDE NAV */}
        <Box
          className="topNavBox"
          style={{
            justifyContent: "flex-end",
          }}
          sx={{}}
        >
          {/* NEW DATA UPDATE BUTTON */}
          <DataUpdateBtn />

          {/* SAPBR FY */}
          <FormControl className="topNavBtn">
            <InputLabel
              id="plz"
              className="navButton"
            >
              SAPBR
            </InputLabel>
            <OutlinedInput
              id="sapbrInput"
              className="navButton topNavBtn"
              disabled
              label="SAPBR"
              // value={sapbrFY}
              value={context.selectedFY.SAPBR}
              sx={{
                fontSize: ".8rem",
                maxWidth: "100px",
                color: "white",
                marginRight: "5px",
                padding: "5px 15px",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
                ".MuiOutlinedInput-input": {
                  padding: "0px",
                },
                ".MuiInputBase-root": {
                  color: "white",
                },
                ".Mui-disabled": {
                  color: "white",
                },
              }}
            />
          </FormControl>

          {/* ORG SELECT */}
          <OrganizationSelectDropdown
            clickTarget={clickTarget}
            pathname={history.location.pathname}
          />

          {/* LOGOUT BUTTON */}
          <Button
            className="logoutBtn topNavBtn"
            variant="outlined"
            onClick={() => window.location.replace(ApplicationPaths.IdentityLogoutPath)}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderColor: "white",
              marginRight: "5px",
            }}
          >
            <Typography variant="navbarBtn">LOGOUT</Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default DashboardMenu
