import * as React from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

// MUI
import { styled, useTheme } from "@mui/material/styles"
import Box from "@mui/material/Box"
import MuiDrawer from "@mui/material/Drawer"
import MuiAppBar from "@mui/material/AppBar"
import List from "@mui/material/List"
import CssBaseline from "@mui/material/CssBaseline"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { Collapse } from "@mui/material"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"

// CUSTOM
import { getExpandMenuItems, menuOptions } from "./layoutUtils"
import AppContext from "../../../AppContext"
import CustomToolbar from "./CustomToolbar"

const drawerWidth = 280

const getLocalStorageData = () => {
  let localStorageData = localStorage.getItem("ORG")

  if (localStorageData == undefined) {
    localStorage.setItem("ORG", JSON.stringify(context.allOrganizations[0]))
    return context.allOrganizations[0]
  } else {
    return JSON.parse(localStorage.getItem("ORG"))
  }
}

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
      },
    },
  ],
}))

export default function CustomDrawer(props) {
  const theme = useTheme()
  const history = useHistory()
  const selectedPathname = history.location.pathname
  const context = React.useContext(AppContext)

  const { setDrawerOpen } = context

  const [open, setOpen] = React.useState(false)
  const [navItemExpand, setNavItemExpand] = React.useState({
    Workload: false,
    "Workload Validation": false,
    Workforce: false,
  })

  const handleMenuItemClick = (title, pathname) => {
    closeSubMenus()
    if (pathname) {
      return handleNavigate(pathname)
    }
    setNavItemExpand((prevState) => {
      return {
        ...prevState,
        [title]: !navItemExpand[title],
      }
    })
  }

  const closeSubMenus = () => {
    setNavItemExpand({
      Workload: false,
      "Workload Validation": false,
      Workforce: false,
    })
  }

  const getExpandIcon = (title) => {
    if (!open) {
      return
    }
    return navItemExpand[title] ? <ExpandLess /> : <ExpandMore />
  }

  const handleNavigate = (pathname) => {
    closeSubMenus()
    setDrawerOpen(false)

    history.push({
      pathname: pathname,
    })
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  if (history.location.pathname == "/landingPage") {
    return props.children
  } else {
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          open={open}
        >
          <CustomToolbar
            open={open}
            handleDrawerOpen={handleDrawerOpen}
            getLocalStorageData={getLocalStorageData}
          />
        </AppBar>
        <Drawer
          variant="permanent"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>{theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {menuOptions.map((item, index) => (
              <ListItem
                key={item.title}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  onClick={() => handleMenuItemClick(item.title, item.pathname)}
                  selected={item.pathname == selectedPathname}
                  sx={[
                    {
                      minHeight: 48,
                      px: 2.5,
                    },
                    open
                      ? {
                          justifyContent: "initial",
                        }
                      : {
                          justifyContent: "center",
                        },
                  ]}
                >
                  <ListItemIcon
                    sx={[
                      {
                        minWidth: 0,
                        justifyContent: "center",
                      },
                      open
                        ? {
                            mr: 3,
                          }
                        : {
                            mr: "auto",
                          },
                    ]}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.title}
                    sx={[
                      open
                        ? {
                            opacity: 1,
                          }
                        : {
                            opacity: 0,
                          },
                    ]}
                  />
                  {item.expand && getExpandIcon(item.title)}
                </ListItemButton>
                {item.expand && (
                  <Collapse
                    in={navItemExpand[item.title]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List
                      component="div"
                      disablePadding
                    >
                      {getExpandMenuItems(item.expandOptions, handleNavigate)}
                    </List>
                  </Collapse>
                )}
              </ListItem>
            ))}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3 }}
        >
          <DrawerHeader />
          {props.children}
        </Box>
      </Box>
    )
  }
}
