import React, { useState, useEffect, useContext, useRef } from "react"
import dayjs from "dayjs"
import { useLocation } from "react-router-dom"
import "./OrganizationPOCs.css"
import EditIcon from "@mui/icons-material/Edit"
import CloseIcon from "@mui/icons-material/Close"
import orgIconPlaceholder from "../../CWATImages/OrganizationIconPlaceholder.png"
import pdfIcon from "../../CWATImages/pdf.png"
import csvIcon from "../../CWATImages/csv.png"
import { DataGrid, GridToolbar } from "@mui/x-data-grid"
import AddOrganizationPOCModal from "./AddOrganizationPOCModal"
import { apiCalls, QueryParamTypes } from "../DataService"
import { CSVLink } from "react-csv"
import AppContext from "../../AppContext"
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Box, CardHeader } from "@mui/material"

import ACC from "./FMS_Organization_Icons/ACC.png"
import AMCOM from "./FMS_Organization_Icons/AMCOM.png"
import CECOM from "./FMS_Organization_Icons/CECOM.png"
import DASA_DEC from "./FMS_Organization_Icons/DASA_DE&C.png"
import JMC from "./FMS_Organization_Icons/JMC.png"
import JPEO_A_A from "./FMS_Organization_Icons/JPEO_A&A.png"
import JPEO_CBRND from "./FMS_Organization_Icons/JPEO_CBRND.png"
import MASPO from "./FMS_Organization_Icons/MASPO.png"
import PEO_AVN from "./FMS_Organization_Icons/PEO_AVN.png"
import PEO_CS_CSS from "./FMS_Organization_Icons/PEO_CS&CSS.png"
import PEO_EIS from "./FMS_Organization_Icons/PEO_EIS.png"
import PEO_GCS from "./FMS_Organization_Icons/PEO_GCS.png"
import PEO_IEW_S from "./FMS_Organization_Icons/PEO_IEW&S.png"
import PEO_M_S from "./FMS_Organization_Icons/PEO_M&S.png"
import PEO_SOLDIER from "./FMS_Organization_Icons/PEO_Soldier.png"
import PEO_STRI from "./FMS_Organization_Icons/PEO_STRI.png"
import PM_LAV from "./FMS_Organization_Icons/PM_LAV.png"
import SATFA from "./FMS_Organization_Icons/SATFA.png"
import SATMO from "./FMS_Organization_Icons/SATMO.png"
import TACOM from "./FMS_Organization_Icons/TACOM.png"
import USACE from "./FMS_Organization_Icons/USACE.png"
import USAMMA from "./FMS_Organization_Icons/USAMMA.png"
import USASAC from "./FMS_Organization_Icons/USASAC.png"
import Base from "../elements/layout/Base"

export const logosArr = [
  { name: "ACC", link: ACC },
  { name: "AMCOM", link: AMCOM },
  { name: "CECOM", link: CECOM },
  { name: "DASA DE&C", link: DASA_DEC },
  { name: "JMC", link: JMC },
  { name: "JPEO A&A", link: JPEO_A_A },
  { name: "JPEO CBRND", link: JPEO_CBRND },
  { name: "PEO MASPO", link: MASPO },
  { name: "PEO AVN", link: PEO_AVN },
  { name: "PEO CS&CSS", link: PEO_CS_CSS },
  { name: "PEO EIS", link: PEO_EIS },
  { name: "PEO GCS", link: PEO_GCS },
  { name: "PEO IEW&S", link: PEO_IEW_S },
  { name: "PEO M&S", link: PEO_M_S },
  { name: "PEO Soldier", link: PEO_SOLDIER },
  { name: "PEO STRI", link: PEO_STRI },
  { name: "PM LAV", link: PM_LAV },
  { name: "SATFA", link: SATFA },
  { name: "SATMO", link: SATMO },
  { name: "TACOM", link: TACOM },
  { name: "USACE", link: USACE },
  { name: "USAMMA", link: USAMMA },
  { name: "USASAC", link: USASAC },
]

const OrganizationPOCs = () => {
  const [users, setUsers] = useState([])
  const [orgPOCRows, setOrgPOCRows] = useState([])
  const [isPOCModalOpen, setIsPOCModalOpen] = useState(false)
  const [csvData, setCsvData] = useState([])
  const [showDeletePOCWarningModal, setShowDeleteUserWarningModal] = useState(false)
  const [pocList, setPocList] = useState([])
  const [editModalParams, setEditModalParams] = useState(null)
  const [POCModalType, setPOCModalType] = useState("assign")

  const selectedPOCToRemoveFromOrganization = useRef("")

  const context = useContext(AppContext)
  const { selectedOrg } = context

  const orgPOCCols = [
    {
      field: "id",
      hide: true,
      headerClassName: "--grid-header",
    },
    {
      field: "firstName",
      headerName: "First name",
      flex: 2,
      headerClassName: "--grid-header",
      minWidth: 150,
    },
    { field: "lastName", headerName: "Last name", flex: 2, headerClassName: "--grid-header", minWidth: 150 },
    {
      field: "role",
      headerName: "Role",
      flex: 2,
      headerClassName: "--grid-header",
      minWidth: 250,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
      headerClassName: "--grid-header",
      minWidth: 250,
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 2,
      headerClassName: "--grid-header",
      minWidth: 150,
    },
    {
      field: "lastLogin",
      headerName: "Last Login",
      flex: 2,
      headerClassName: "--grid-header",
      minWidth: 250,
      renderCell: (params) => {
        if (!params.value) return null
        return <div className="MuiDataGrid-cellContent">{dayjs(params.value).format("MM/DD/YYYY, h:mm:ss a")}</div>
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 2,
      headerClassName: "--grid-header",
      minWidth: 150,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      flex: 1,
      headerClassName: "--grid-header",
      minWidth: 100,
      renderCell: (params) => {
        return (
          <div className="edit-delete-buttons">
            <button style={params.id % 2 === 0 ? { backgroundColor: "#ddd" } : { backgroundColor: "white" }}>
              <EditIcon onClick={() => handleEditPOC(params)} />
            </button>
            <button style={params.id % 2 === 0 ? { backgroundColor: "#ddd" } : { backgroundColor: "white" }}>
              <CloseIcon
                onClick={() => {
                  openPOCDeleteWarningModal(params.row)
                }}
              />
            </button>
          </div>
        )
      },
    },
  ]

  // Set CSV headers
  const csvHeaders = [
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Role", key: "role" },
    { label: "Email", key: "email" },
    { label: "Phone", key: "phone" },
    { label: "Last Login", key: "lastLogin" },
    { label: "Status", key: "status" },
  ]

  const openPOCDeleteWarningModal = (row) => {
    selectedPOCToRemoveFromOrganization.current = row
    setShowDeleteUserWarningModal(true)
  }

  const handleAssignPOC = () => {
    setPOCModalType("assign")
    setEditModalParams({})
    setIsPOCModalOpen(true)
  }

  const handleEditPOC = (params) => {
    setPOCModalType("edit")
    setEditModalParams(params.row)
    setIsPOCModalOpen(true)
  }

  const handleDeletePOC = (pocId) => {
    apiCalls.delete(`Organizations/${selectedOrg.id}/Pocs/${pocId}`).then(() => {
      refreshData().then(setShowDeleteUserWarningModal(false))
    })
  }

  const closePOCModal = (dataChanged) => {
    if (dataChanged) {
      refreshData().then(setIsPOCModalOpen(false))
    } else {
      setIsPOCModalOpen(false)
    }
  }

  const refreshData = async () => {
    apiCalls.getAll(`Organizations/${selectedOrg.id}/Pocs`).then((data) => {
      setPocList(data)
    })
    apiCalls.getAll(`Accounts`, {}).then((data) => {
      setUsers(data)
    })
  }

  const getAllPOCs = () => {
    if (selectedOrg.id) {
      let tempOrgPOCRows = []
      setOrgPOCRows([])
      pocList.map((poc) => {
        const correspondingUser = users.find((user) => user.userName === poc.userName)
        tempOrgPOCRows.push({
          id: poc.id,
          firstName: poc.firstName,
          lastName: poc.lastName,
          email: poc.email,
          phone: poc.phoneNumber,
          role: poc.role ? poc.role : "",
          lastLogin: correspondingUser && correspondingUser.lastLoginDate ? correspondingUser.lastLoginDate : "",
          status: poc.activeState ? "Active" : "Not Active",
          actions: null,
        })
      })
      setOrgPOCRows(tempOrgPOCRows)
      // Get CSV data from orgPOCRows
      setCsvData(
        tempOrgPOCRows.map((poc) => {
          const { firstName, lastName, role, email, phone, lastLogin, status } = poc
          return { firstName, lastName, role, email, phone, lastLogin, status }
        })
      )
    }
  }

  // TODO: confirm this works. Can't test due to org selection bug.
  useEffect(() => {
    refreshData()
  }, [selectedOrg])

  useEffect(() => {
    if (pocList.length > 0 && users.length > 0) {
      getAllPOCs()
    }
  }, [pocList, users])

  return (
    <div className="org-page-container">
      <div className="org-select">
        <CardHeader
          title="FMS Organization POCs"
          sx={{
            padding: "0px",
            "& .MuiTypography-root": {
              fontFamily: "Bitter",
              fontWeight: "bold",
            },
          }}
        />
        <div className="data-download">
          <Button onClick={() => window.print()}>
            <img
              src={pdfIcon}
              alt="PDF"
              width="50"
              height="50"
            />
          </Button>
          <Button>
            <CSVLink
              data={csvData}
              headers={csvHeaders}
              filename={`${selectedOrg.name}-pocs.csv`}
            >
              <img
                src={csvIcon}
                alt="CSV"
                width="50"
                height="50"
              />
            </CSVLink>
          </Button>
        </div>
      </div>
      <AddOrganizationPOCModal
        isOpen={isPOCModalOpen}
        onClose={closePOCModal}
        editParams={editModalParams}
        selectedOrg={selectedOrg}
        type={POCModalType}
        users={users}
        currentPocList={pocList}
      />
      <div className="org-display">
        <div className="org-title">
          {selectedOrg.logo ? (
            <img
              src={selectedOrg.logo.link}
              alt=""
              width="80"
              height="80"
              className="org-icon"
            />
          ) : (
            <img
              src={orgIconPlaceholder}
              alt=""
              width="89"
              height="80"
              className="org-icon"
            />
          )}
          <h2 className="org-title-text">
            <strong>
              {selectedOrg.name} - {selectedOrg.longName}
            </strong>
          </h2>
        </div>
        <div className="org-table-contatiner">
          <div className="table-top"></div>
          <Box
            container
            margin={2}
            spacing={2}
          >
            <Button
              variant="contained"
              onClick={handleAssignPOC}
            >
              Assign POC
            </Button>
            <DataGrid
              rows={orgPOCRows}
              columns={orgPOCCols}
              components={{ Toolbar: GridToolbar }}
              density="compact"
              pageSize={25}
              sx={{
                border: 2,
                borderColor: "black",
                marginTop: 2,
              }}
              getRowClassName={(params) => {
                if (params.indexRelativeToCurrentPage % 2 === 0) {
                  return "even-row"
                } else return "odd-row"
              }}
              initialState={{
                columns: {
                  columnVisibilityModel: { id: false },
                },
              }}
            />
          </Box>
        </div>
      </div>
      {/************************************************ [Replace CSV Data WARNING DIALOG WINDOW] ************************************************/}
      <Dialog
        open={showDeletePOCWarningModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Remove POC from {selectedOrg.name}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you would like remove{" "}
            {selectedPOCToRemoveFromOrganization.current.firstName && selectedPOCToRemoveFromOrganization.current.lastName
              ? `${selectedPOCToRemoveFromOrganization.current.firstName} ${selectedPOCToRemoveFromOrganization.current.lastName}`
              : "this POC"}{" "}
            from the {selectedOrg.name} organization?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteUserWarningModal(false)}>Cancel</Button>
          <Button
            onClick={() => {
              handleDeletePOC(selectedPOCToRemoveFromOrganization.current.id)
              setShowDeleteUserWarningModal(false)
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default OrganizationPOCs
