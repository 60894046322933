/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext, useRef } from "react"

// Context
import GlobalContext from "../reducers/GlobalContext"

// Data
import { apiCalls, QueryParamTypes } from "../DataService"
import { fetchRFIDashboardObjectsFromDB, getRfiRecordCountMapping } from "../DashboardMenu"

// Libraries
import { useHistory } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"
import dayjs from "dayjs"
import { ToastContainer, toast } from "react-toastify"
import { Box, Button, Grid2, Stack, Tab, Typography } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"

// Custom
import RFIStatusManagementConfirmationModal from "./RFIStatusManagementConfirmationModal"
import OrganizationRFIColumnTableModal from "./OrganizationRFIColumnTableModal"
import { ArrowCircleRight } from "@mui/icons-material"
import Supplemental_Workforce_Utils from "../RFIs/Supplemental-Workforce/SupplementalWorkforceUtils"
import UploadProgressModal from "../RFIs/UploadProgressModal"
import RunWorkforceWorkloadConfirmationModal from "../RunWorkforceWorkloadConfirmationModal"

// Vars
import SnackbarMessages from "../SnackbarMessages"
import {
  RFIGroupings,
  RFI_API_TableNames,
  RFIGroupTypes,
  Record_API_TableNames,
  SubmitStatus,
  returnErrorMessageFromAPIError,
  populateGlobalContext,
} from "../../Utils"
import DSAMSTableUtils from "../RFIs/DSAMS-CISIL/DSAMS_Table_Utils"
import GFEBS_CEFMS_Table_Utils from "../RFIs/GFEBS-CEFMS/GFEBS_CEFMS_Table_Utils"
import SAPBR_Utils from "../Admin/SAPBR/SAPBR_Utils"
import PEO_PM_Utils from "../RFIs/PEO-PM/PEO_PM_Utils"
import ACC_Utils from "../RFIs/ACC/ACC_Utils"
import SATFA_Utils from "../RFIs/SATFA/SATFA_Utils"
import TDAUtils from "../RFIs/TDA/TDAUtils"

// Styles
import "react-toastify/dist/ReactToastify.css"
import AppContext from "../../AppContext"
import RfiStatusChip from "../custom/RfiStatusChip"
import RfiCard from "../custom/RfiCard"
import { DataGridPremium } from "@mui/x-data-grid-premium"
import { TabContext, TabList } from "@mui/lab"

const RFIStatusManagement = () => {
  // ** --------------***     [ ~ HOOKS ~ ]     ***-------------- **
  const history = useHistory()
  const globalContext = useContext(GlobalContext)

  const [selectedTab, setSelectedTab] = useState(RFIGroupings.DSAMS_CISIL)
  const [showSnackbarSuccess, setShowSnackbarSuccess] = useState(false)
  const [showSnackbarError, setShowSnackbarError] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const [openRunWorkloadConfirmationDialog, setOpenRunWorkloadConfirmationDialog] = useState(false)
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false)
  const [loadingSpinnerTitle, setLoadingSpinnerTitle] = useState("")
  const [orgRFIFieldCheckTableRows, setOrgRFIFieldCheckTableRows] = useState([])
  const refreshingForPEOPMChanges = useRef(false)
  const clickSimulator = useRef(null)
  const [openOrgRFIColTableModal, setOpenOrgRFIColTableModal] = useState(false)
  const [selectedTableName, setSelectedTableName] = useState("")
  const [modifiedCells, setModifiedCells] = useState([])

  // ** --------------***     [ ~ VARS ~ ]     ***-------------- **
  const {
    activeFiscalCycle,
    userObj,
    rfiOrganizationsMapping,
    rfiDashboardOrgRfiMapping,
    allOrgs,
    rfiRecordCountMapping,
    setRfiRecordCountMappingContext,
    setRfiDashboardOrgRfiMappingContext,
    canRunWorkloadProcedures,
    setCanRunWorkloadProceduresContext,
  } = globalContext

  const RFIStatusManagementTables = useRef({
    [RFIGroupings.DSAMS_CISIL]: [
      {
        rfiTableName: RFI_API_TableNames[RFIGroupTypes.DSAMS_CISIL].MILESTONES,
        apiType: Record_API_TableNames[RFIGroupTypes.DSAMS_CISIL].MILESTONES,
        fields: Object.values(DSAMSTableUtils.MilestonesFields),
        rfiMappingName: "dsamsMilestone",
        title: "DSAMS Milestones",
        rfiViewPathName: "/DSAMS-CISIL-RFIs",
        rfiViewTab: DSAMSTableUtils.Tabs.MILESTONES,
        rows: [],
      },
      {
        rfiTableName: RFI_API_TableNames[RFIGroupTypes.DSAMS_CISIL].PREPARING_ACTIVITY_DATA,
        apiType: Record_API_TableNames[RFIGroupTypes.DSAMS_CISIL].PREPARING_ACTIVITY_DATA,
        fields: Object.values(DSAMSTableUtils.ActivityDataFields),
        rfiMappingName: "dsamsPreparingActivityData",
        title: "DSAMS Preparing Activity Data",
        rfiViewPathName: "/DSAMS-CISIL-RFIs",
        rfiViewTab: DSAMSTableUtils.Tabs.PREPARING_ACTIVITY_DATA,
        rows: [],
      },
      {
        rfiTableName: RFI_API_TableNames[RFIGroupTypes.DSAMS_CISIL].CISIL_CCOPES_LINE_DATA,
        apiType: Record_API_TableNames[RFIGroupTypes.DSAMS_CISIL].CISIL_CCOPES_LINE_DATA,
        fields: Object.values(DSAMSTableUtils.CcopesLineDataFields),
        rfiMappingName: "cisilCcopesLineData",
        title: "CISIL CCOPES LINE Data",
        rfiViewPathName: "/DSAMS-CISIL-RFIs",
        rfiViewTab: DSAMSTableUtils.Tabs.CISIL_CCOPES_LINE_DATA,
        rows: [],
      },
      {
        rfiTableName: RFI_API_TableNames[RFIGroupTypes.DSAMS_CISIL].CISIL_CASES_CERT_CLOSURE,
        apiType: Record_API_TableNames[RFIGroupTypes.DSAMS_CISIL].CISIL_CASES_CERT_CLOSURE,
        fields: Object.values(DSAMSTableUtils.CisilCasesCertClosureFields),
        rfiMappingName: "cisilCasesCertClosure",
        title: "CISIL CASES CERT Closures",
        rfiViewPathName: "/DSAMS-CISIL-RFIs",
        rfiViewTab: DSAMSTableUtils.Tabs.CISIL_CASES_CERT_CLOSURE,
        rows: [],
      },
      {
        rfiTableName: RFI_API_TableNames[RFIGroupTypes.DSAMS_CISIL].CISIL_CASES_CERT_CLOSURE_ALL,
        apiType: Record_API_TableNames[RFIGroupTypes.DSAMS_CISIL].CISIL_CASES_CERT_CLOSURE_ALL,
        fields: Object.values(DSAMSTableUtils.CisilCasesCertClosureFields),
        rfiMappingName: "cisilCasesCertClosureAll",
        title: "CISIL CASES CERT Closures W/O Priority One Date",
        rfiViewPathName: "/DSAMS-CISIL-RFIs",
        rfiViewTab: DSAMSTableUtils.Tabs.CISIL_CASES_CERT_CLOSURE_ALL,
        rows: [],
      },
      {
        rfiTableName: RFI_API_TableNames[RFIGroupTypes.DSAMS_CISIL].CISIL_REQUISITIONS,
        apiType: Record_API_TableNames[RFIGroupTypes.DSAMS_CISIL].CISIL_REQUISITIONS,
        fields: Object.values(DSAMSTableUtils.CisilRequisitionsFields),
        rfiMappingName: "cisilRequisition",
        title: "CISIL Requisitions",
        rfiViewPathName: "/DSAMS-CISIL-RFIs",
        rfiViewTab: DSAMSTableUtils.Tabs.CISIL_REQUISITIONS,
        rows: [],
      },
      {
        rfiTableName: RFI_API_TableNames[RFIGroupTypes.DSAMS_CISIL].CISIL_SDRs,
        apiType: Record_API_TableNames[RFIGroupTypes.DSAMS_CISIL].CISIL_SDRs,
        fields: Object.values(DSAMSTableUtils.CisilSDRsFields),
        rfiMappingName: "cisilSdr",
        title: "CISIL SDRs",
        rfiViewPathName: "/DSAMS-CISIL-RFIs",
        rfiViewTab: DSAMSTableUtils.Tabs.CISIL_SDRs,
        rows: [],
      },
      {
        rfiTableName: RFI_API_TableNames[RFIGroupTypes.DSAMS_CISIL].CISIL_DATA_DESCRIPTIONS,
        apiType: Record_API_TableNames[RFIGroupTypes.DSAMS_CISIL].CISIL_DATA_DESCRIPTIONS,
        fields: Object.values(DSAMSTableUtils.CisilDataDescriptionFields),
        rfiMappingName: "cisilDataDescription",
        title: "CISIL Data Descriptions",
        rfiViewPathName: "/DSAMS-CISIL-RFIs",
        rfiViewTab: DSAMSTableUtils.Tabs.CISIL_DATA_DESCRIPTIONS,
        rows: [],
      },
    ],
    [RFIGroupings.PEOPM_SATMO_USACE]: [
      {
        rfiTableName: RFI_API_TableNames[RFIGroupTypes.PEO_PM].PEO_PM,
        apiType: Record_API_TableNames[RFIGroupTypes.PEO_PM].PEO_PM,
        fields: Object.values(PEO_PM_Utils.PEO_PM_TableFields),
        rfiMappingName: "peoPm",
        title: "PEO PM",
        rfiViewPathName: "/PEO-PM-RFIs",
        rfiViewTab: null,
        rows: [],
      },
      {
        rfiTableName: RFI_API_TableNames[RFIGroupTypes.PEO_PM].PEO_PM,
        apiType: Record_API_TableNames[RFIGroupTypes.PEO_PM].PEO_PM,
        fields: Object.values(PEO_PM_Utils.PEO_PM_TableFields),
        rfiMappingName: "satmo",
        title: "SATMO",
        rfiViewPathName: "/PEO-PM-RFIs",
        rfiViewTab: "SATMO",
        rows: [],
      },
      {
        rfiTableName: RFI_API_TableNames[RFIGroupTypes.PEO_PM].PEO_PM,
        apiType: Record_API_TableNames[RFIGroupTypes.PEO_PM].PEO_PM,
        fields: Object.values(PEO_PM_Utils.PEO_PM_TableFields).slice(0, 4),
        rfiMappingName: "usace",
        title: "USACE",
        rfiViewPathName: "/PEO-PM-RFIs",
        rfiViewTab: "USACE",
        rows: [],
      },
    ],
    [RFIGroupings.SPECIAL_ORGS]: [
      {
        rfiTableName: RFI_API_TableNames[RFIGroupTypes.ACC].ACC,
        apiType: Record_API_TableNames[RFIGroupTypes.ACC].ACC,
        fields: Object.values(ACC_Utils.ACCDataFields),
        rfiMappingName: "accWorkload",
        title: "ACC",
        rfiViewPathName: "/ACC-RFIs",
        rfiViewTab: null,
        rows: [],
      },
      {
        rfiTableName: RFI_API_TableNames[RFIGroupTypes.SATFA].SATFA,
        apiType: Record_API_TableNames[RFIGroupTypes.SATFA].SATFA,
        fields: Object.values(SATFA_Utils.SATFADataFields),
        rfiMappingName: "satfaSpecialCase",
        title: "SATFA",
        rfiViewPathName: "/SATFA-RFIs",
        rfiViewTab: null,
        rows: [],
      },
    ],
    [RFIGroupings.GFEBS_CEFMS]: [
      {
        rfiTableName: RFI_API_TableNames[RFIGroupTypes.GFEBS_CEFMS].GFEBS,
        apiType: Record_API_TableNames[RFIGroupTypes.GFEBS_CEFMS].GFEBS,
        fields: Object.values(GFEBS_CEFMS_Table_Utils.GFEBSDataFields),
        rfiMappingName: "gfebsReport1",
        title: "GFEBS Report 1",
        rfiViewPathName: "/GFEBS-CEFMS-RFIs",
        rfiViewTab: GFEBS_CEFMS_Table_Utils.Tabs.GFEBS_DATA_REPORT1,
        rows: [],
      },
      {
        rfiTableName: RFI_API_TableNames[RFIGroupTypes.GFEBS_CEFMS].GFEBS,
        apiType: Record_API_TableNames[RFIGroupTypes.GFEBS_CEFMS].GFEBS,
        fields: Object.values(GFEBS_CEFMS_Table_Utils.GFEBSDataFields),
        rfiMappingName: "gfebsReport2",
        title: "GFEBS Report 2",
        rfiViewPathName: "/GFEBS-CEFMS-RFIs",
        rfiViewTab: GFEBS_CEFMS_Table_Utils.Tabs.GFEBS_DATA_REPORT2,
        rows: [],
      },
      {
        rfiTableName: RFI_API_TableNames[RFIGroupTypes.GFEBS_CEFMS].CEFMS,
        apiType: Record_API_TableNames[RFIGroupTypes.GFEBS_CEFMS].CEFMS,
        fields: Object.values(GFEBS_CEFMS_Table_Utils.CEFMSDataFields),
        rfiMappingName: "cefms",
        title: "CEFMS",
        rfiViewPathName: "/GFEBS-CEFMS-RFIs",
        rfiViewTab: GFEBS_CEFMS_Table_Utils.Tabs.CEFMS_DATA,
        rows: [],
      },
    ],
    [RFIGroupings.SUPPLEMENTAL_WORKFORCE]: [
      {
        rfiTableName: RFI_API_TableNames[RFIGroupTypes.SUPPLEMENTAL_WORKFORCE].SUPPLEMENTAL_WORKFORCE,
        apiType: Record_API_TableNames[RFIGroupTypes.SUPPLEMENTAL_WORKFORCE].SUPPLEMENTAL_WORKFORCE,
        fields: Object.values(Supplemental_Workforce_Utils.SupplementalWorkforceDataFields),
        rfiMappingName: "supplementalWorkforce",
        title: "HOURS DISTRIBUTION",
        rfiViewPathName: "/SupplementalWorkforce-RFIs",
        rfiViewTab: Supplemental_Workforce_Utils.Tabs.SUPPLEMENTAL_WORKFORCE_RFI,
        rows: [],
      },
      {
        rfiTableName: RFI_API_TableNames[RFIGroupTypes.SUPPLEMENTAL_WORKFORCE].BPC_REDUCTION,
        apiType: Record_API_TableNames[RFIGroupTypes.SUPPLEMENTAL_WORKFORCE].BPC_REDUCTION,
        fields: Object.values(Supplemental_Workforce_Utils.BPCColumnHeaders).slice(1),
        rfiMappingName: "bpcReduction",
        title: "BPC CIV FTE REDUCTION",
        rfiViewPathName: "/SupplementalWorkforce-RFIs",
        rfiViewTab: Supplemental_Workforce_Utils.Tabs.BPC,
        rows: [],
      },
      {
        rfiTableName: RFI_API_TableNames[RFIGroupTypes.SUPPLEMENTAL_WORKFORCE].SUPPLEMENTAL_WORKFORCE,
        apiType: Record_API_TableNames[RFIGroupTypes.SUPPLEMENTAL_WORKFORCE].SUPPLEMENTAL_WORKFORCE,
        fields: Object.values(Supplemental_Workforce_Utils.SupplementalWorkforceDataFields),
        rfiMappingName: "civilianPercentages",
        title: "CIVILIAN PERCENTAGES",
        rfiViewPathName: "/SupplementalWorkforce-RFIs",
        rfiViewTab: Supplemental_Workforce_Utils.Tabs.CIVILIAN_PERCENTAGES,
        rows: [],
      },
    ],
    [RFIGroupings.SAPBR_TDA]: [
      {
        rfiTableName: RFI_API_TableNames[RFIGroupTypes.SAPBR].SAPBR,
        apiType: Record_API_TableNames[RFIGroupTypes.SAPBR].SAPBR,
        fields: Object.values(SAPBR_Utils.SAPBRTableFields),
        rfiMappingName: "sapbr",
        title: "SAPBR DECISION DATA",
        rfiViewPathName: "/SAPBR",
        rfiViewTab: SAPBR_Utils.Tabs.SAPBR,
        rows: [],
      },
      {
        rfiTableName: RFI_API_TableNames[RFIGroupTypes.SAPBR].RMD,
        apiType: Record_API_TableNames[RFIGroupTypes.SAPBR].RMD,
        fields: Object.values(SAPBR_Utils.RMDTableFields),
        rfiMappingName: "rmd",
        title: "RESOURCE MANAGEMENT DECISION",
        rfiViewPathName: "/SAPBR",
        rfiViewTab: SAPBR_Utils.Tabs.RMD,
        rows: [],
      },
      {
        rfiTableName: RFI_API_TableNames[RFIGroupTypes.TDA].TDA,
        apiType: Record_API_TableNames[RFIGroupTypes.TDA].TDA,
        fields: Object.values(TDAUtils.TDATableFields),
        rfiMappingName: "tda",
        title: "TDA",
        rfiViewPathName: "/TDA-RFIs",
        rfiViewTab: null,
        rows: [],
      },
    ],
  })

  const rfiStatusManagementTableCols = [
    {
      field: "Id",
      headerName: "Id",
      hide: true,
      headerClassName: "--grid-header",
    },
    {
      field: "rfiMappingName",
      headerName: "RFI Table",
      hide: true,
      headerClassName: "--grid-header",
    },
    {
      field: "organization",
      headerName: "Organization",
      flex: 1,
      minWidth: 150,
      headerClassName: "--grid-header",
      renderCell: (params) => {
        if (!params.value) return null
        const rfiGroupingTable = RFIStatusManagementTables.current[selectedTab].find((table) => table.rfiMappingName === params.row.rfiMappingName)
        const updatedSelectedOrg = allOrgs.find((org) => org.name === params.value)
        return params.row.status ? (
          <div className="MuiDataGrid-cellContent">
            <Button
              onClick={() => {
                let selectedTab = rfiGroupingTable.rfiViewTab
                let organization = updatedSelectedOrg
                if (rfiGroupingTable.rfiViewPathName === "/PEO-PM-RFIs") {
                  selectedTab = null
                  switch (rfiGroupingTable.rfiViewTab) {
                    case "SATMO":
                      organization = allOrgs.find((org) => org.name === "SATMO")
                      break
                    case "USACE":
                      organization = allOrgs.find((org) => org.name === "USACE")
                      break
                    default:
                      break
                  }
                }
                history.push({
                  pathname: rfiGroupingTable.rfiViewPathName,
                  state: {
                    selectedTab,
                    organization,
                  },
                })
              }}
            >
              {params.value}
            </Button>
          </div>
        ) : (
          <div className="MuiDataGrid-cellContent">{params.value}</div>
        )
      },
    },
    {
      field: "poc",
      headerName: "POC",
      flex: 1,
      minWidth: 150,
      headerClassName: "--grid-header",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 2,
      minWidth: 200,
      headerClassName: "--grid-header",
      renderCell: (params) => {
        if (!params.value) return null
        return (
          <div className="MuiDataGrid-cellContent">
            <RfiStatusChip status={params.value} />
            <Button
              onClick={() => changeRFIStatus(params)}
              disabled={params.row.status === SubmitStatus.NOT_GENERATED}
            >
              <ArrowCircleRight />
            </Button>
          </div>
        )
      },
    },
    {
      field: "dateLastModified",
      headerName: "Date Last Modified",
      // type: "date",
      flex: 1,
      minWidth: 150,
      headerClassName: "--grid-header",
      renderCell: (params) => {
        if (!params.value) return null
        return <div className="MuiDataGrid-cellContent">{dayjs(params.value).format("MM/DD/YYYY, h:mm:ss a")}</div>
      },
    },
    {
      field: "rowCount",
      headerName: "Rows",
      flex: 1,
      minWidth: 150,
      headerClassName: "--grid-header",
      renderCell: (params) => {
        if (!params.value) return null
        const rfiGroupingTable = RFIStatusManagementTables.current[selectedTab].find((table) => table.rfiMappingName === params.row.rfiMappingName)
        let tmpOrgRfiFieldCheckTableRows = []
        rfiGroupingTable.fields.forEach((field) => {
          tmpOrgRfiFieldCheckTableRows.push({
            Id: uuidv4(),
            rfiColumns: field,
            fieldCheck1: null,
            fieldCheck2: null,
            fieldCheck3: null,
          })
        })
        return (
          <div className="MuiDataGrid-cellContent">
            <Button
              onClick={() => {
                setSelectedTableName(rfiGroupingTable.title)
                setOrgRFIFieldCheckTableRows(tmpOrgRfiFieldCheckTableRows)
                setOpenOrgRFIColTableModal(true)
              }}
            >
              {params.value}
            </Button>
          </div>
        )
      },
    },
    {
      field: "check1",
      headerName: "Check 1",
      flex: 1,
      minWidth: 150,
      headerClassName: "--grid-header",
    },
    {
      field: "check2",
      headerName: "Check 2",
      flex: 1,
      minWidth: 150,
      headerClassName: "--grid-header",
    },
    {
      field: "notes",
      headerName: "Notes",
      flex: 1,
      editable: true,
      minWidth: 150,
      headerClassName: "--grid-header",
      cellClassName: (params) => {
        if (params?.row?.rowCount > 0 && params?.row?.status != null) {
          return "editable-cell"
        }
        return "disabled-editable-cell"
      },
    },
  ]

  // ** --------------***     [ ~ EFFECTS ~ ]     ***-------------- **
  useEffect(() => {
    populateGlobalContext()
    if (Object.keys(rfiDashboardOrgRfiMapping).length <= 0) {
      setShowLoadingSpinner(true)
      setLoadingSpinnerTitle("Loading RFI Status Management Tables...")
    }
    // If there are no PEO PM RFI objects for the selected FY, create them
    refreshingForPEOPMChanges.current = true
    refreshPEOPMSATMOUSACEOrgs()
  }, [])

  useEffect(() => {
    if (
      Object.keys(rfiOrganizationsMapping).length > 0 &&
      Object.keys(rfiRecordCountMapping).length > 0 &&
      Object.keys(rfiDashboardOrgRfiMapping).length > 0
    ) {
      RFIStatusManagementTables.current = rfiDashboardOrgRfiMapping
    }
  }, [rfiOrganizationsMapping, rfiRecordCountMapping, rfiDashboardOrgRfiMapping])

  useEffect(() => {
    refreshPEOPMSATMOUSACEOrgs()
  }, [rfiDashboardOrgRfiMapping, rfiOrganizationsMapping, activeFiscalCycle])

  useEffect(() => {
    if (activeFiscalCycle?.id) {
      refreshRFIStatusManagementTables()
    }
  }, [activeFiscalCycle])

  // ** --------------***     [ ~ FUNCTIONS ~ ]     ***-------------- **
  const changeRFIStatus = (params) => {
    let rfiOrgObj = RFIStatusManagementTables.current[selectedTab].find((rfiOrg) => rfiOrg.rfiMappingName === params.row.rfiMappingName)
    rfiOrgObj.rows = rfiOrgObj.rows.map((row) => {
      if (row.organization === params.row.organization) {
        const prevValue = params?.value ? params.value : null
        let value = null
        switch (row.status) {
          case SubmitStatus.GENERATED:
            value = SubmitStatus.SUBMITTED
            break
          case SubmitStatus.NOT_SUBMITTED:
            value = SubmitStatus.SUBMITTED
            break
          case SubmitStatus.SUBMITTED:
            value = SubmitStatus.APPROVED
            break
          case SubmitStatus.APPROVED:
            value = SubmitStatus.REJECTED
            break
          case SubmitStatus.REJECTED:
            value = selectedTab === RFIGroupings.PEOPM_SATMO_USACE ? SubmitStatus.GENERATED : SubmitStatus.NOT_SUBMITTED
            break
          case SubmitStatus.REQUEST_RESUBMISSION:
            value = SubmitStatus.NOT_SUBMITTED
            break
          default:
            break
        }
        let updatedCells = []
        if (modifiedCells.map((cell) => cell.orgId).includes(params.row.Id)) {
          updatedCells = modifiedCells.map((cell) => {
            if (cell.orgId === params.row.Id) {
              return { ...cell, value }
            } else return cell
          })
        } else {
          updatedCells = [
            ...modifiedCells,
            { orgId: params.row.Id, tableName: rfiOrgObj.title, organization: row.organization, field: params.field, prevValue, value },
          ]
        }
        setModifiedCells(updatedCells.filter((cell) => cell.value !== cell.prevValue || cell.updatedNotes))
        return { ...row, status: value }
      } else return row
    })
    RFIStatusManagementTables.current[selectedTab] = RFIStatusManagementTables.current[selectedTab].map((rfiOrg) => {
      if (rfiOrg.rfiMappingName === rfiOrgObj.rfiMappingName) {
        return rfiOrgObj
      } else return rfiOrg
    })
  }

  // Check the required RFIs for the selected fiscal cycle to determine if we can execute the workload procedures
  const checkCanRunWorkloadProcedures = () => {
    const rfiGroupingsToCheck = [RFIGroupings.DSAMS_CISIL, RFIGroupings.PEOPM_SATMO_USACE, RFIGroupings.SPECIAL_ORGS]
    for (const grouping of rfiGroupingsToCheck) {
      for (const table of RFIStatusManagementTables.current[grouping]) {
        if (table.rows.filter((row) => row.status != null).length <= 0) {
          setCanRunWorkloadProceduresContext(false)
          return
        }
        for (const row of table.rows) {
          if (row?.status && row.status !== SubmitStatus.APPROVED) {
            setCanRunWorkloadProceduresContext(false)
            return
          }
        }
      }
    }
    setCanRunWorkloadProceduresContext(true)
  }

  const saveRFIChanges = async () => {
    setLoadingSpinnerTitle("Saving RFI Changes...")
    setShowLoadingSpinner(true)
    const tmpRfiDashboardOrgRfiMapping = { ...rfiDashboardOrgRfiMapping }
    for (const table of RFIStatusManagementTables.current[selectedTab]) {
      for (const row of table.rows) {
        if ((row.status && row.status !== row.prevStatus) || (row.notes && row.notes !== row.prevNotes)) {
          if (table.rfiTableName === RFI_API_TableNames[RFIGroupTypes.SUPPLEMENTAL_WORKFORCE].SUPPLEMENTAL_WORKFORCE) {
            const updatedOrg = allOrgs.find((org) => org.name === row.organization)
            apiCalls
              .getAll(`SupplementalWorkforceRfis`, {
                [QueryParamTypes.FILTER]: `fiscalCycleId eq ${activeFiscalCycle.id} and organizationId eq ${updatedOrg.id}`,
                [QueryParamTypes.ORDER_BY]: "Group",
              })
              .then(async (rfis) => {
                for (const rfi of rfis) {
                  let body = {
                    id: rfi?.id,
                    group: rfi?.group,
                    leave: rfi?.leave,
                    pre_Lor: rfi?.pre_Lor,
                    caseExecution: rfi?.caseExecution,
                    caseDevelopment: rfi?.caseDevelopment,
                    caseClosure: rfi?.caseClosure,
                    businessSustaining: rfi?.businessSustaining,
                    status: row.status,
                    package: rfi?.package,
                    comments: row.notes,
                    category: rfi?.category,
                    organizationId: rfi?.organizationId,
                    fiscalCycleId: rfi?.fiscalCycleId,
                    poc: rfi?.poc,
                  }
                  // Delete unneeded fields from req body
                  delete body["organization"]
                  delete body["fiscalCycle"]
                  // Change each of the SupplementalWorkforce rfi objects for the selected org & fiscal cycle to the new status (could be 1-3 of them)
                  await apiCalls.put(`${table.apiType}/${rfi?.id}`, body)
                }
                tmpRfiDashboardOrgRfiMapping[selectedTab] = tmpRfiDashboardOrgRfiMapping[selectedTab].map((dashboardTable) => {
                  if (dashboardTable.rfiMappingName === table.rfiMappingName) {
                    return {
                      ...dashboardTable,
                      status: table?.rows?.length > 0 && table.rows.length > 0 ? SubmitStatus.GENERATED : SubmitStatus.NOT_GENERATED,
                      rows: table.rows.map((row) => {
                        return {
                          ...row,
                          prevStatus: row.status,
                        }
                      }),
                    }
                  } else return dashboardTable
                })
                setRfiDashboardOrgRfiMappingContext(tmpRfiDashboardOrgRfiMapping)
              })
          } else {
            apiCalls.getById(`${table.apiType}/${row.Id}`).then((rfi) => {
              let body = {
                ...rfi,
                status: row.status,
                comments: row.notes,
              }
              delete body["organization"]
              delete body["fiscalCycle"]
              apiCalls.put(`${table.apiType}/${row.Id}`, body).then((res) => {
                // Trigger rerender if DSAMS PAD table is set to APPROVED to update PEOPM/SATMO/USACE tab after associations are made
                if (
                  table.rfiTableName === RFI_API_TableNames[RFIGroupTypes.DSAMS_CISIL].PREPARING_ACTIVITY_DATA &&
                  row.status === SubmitStatus.APPROVED
                ) {
                  refreshingForPEOPMChanges.current = true
                  toast.success(res.data)
                }
                const tmpRfiDashboardOrgRfiMapping = { ...rfiDashboardOrgRfiMapping }
                tmpRfiDashboardOrgRfiMapping[selectedTab] = tmpRfiDashboardOrgRfiMapping[selectedTab].map((dashboardTable) => {
                  if (dashboardTable.rfiMappingName === table.rfiMappingName) {
                    return {
                      ...dashboardTable,
                      rows: table.rows.map((row) => {
                        return {
                          ...row,
                          prevStatus: row.status,
                        }
                      }),
                    }
                  } else return dashboardTable
                })
                setRfiDashboardOrgRfiMappingContext(tmpRfiDashboardOrgRfiMapping)
              })
            })
          }
        }
      }
    }
    setShowLoadingSpinner(false)
    setModifiedCells([])
  }

  const canEditCell = (params) => {
    return params.field === "notes" && params.row.status != null && params.row.rowCount > 0
  }

  const handleRowUpdate = (params) => {
    let rfiOrgObj = RFIStatusManagementTables.current[selectedTab].find((rfiOrg) => rfiOrg.rfiMappingName === params.rfiMappingName)
    rfiOrgObj.rows = rfiOrgObj.rows.map((row) => {
      // Update modified cells
      if (row.organization === params.organization) {
        let updatedCells = []
        if (modifiedCells.map((cell) => cell.orgId).includes(params.Id)) {
          updatedCells = modifiedCells.map((cell) => {
            if (cell.orgId === params.Id) {
              return { ...cell, updatedNotes: params.notes !== params.prevNotes }
            } else return cell
          })
        } else {
          updatedCells = [
            ...modifiedCells,
            {
              orgId: params.Id,
              tableName: rfiOrgObj.title,
              organization: row.organization,
              field: "notes",
              value: params.status,
              prevValue: params.prevStatus,
              updatedNotes: params.notes !== params.prevNotes,
            },
          ]
        }
        setModifiedCells(updatedCells.filter((cell) => cell.value !== cell.prevValue || cell.updatedNotes))
        return { ...row, notes: params.notes }
      } else return row
    })
    RFIStatusManagementTables.current[selectedTab] = RFIStatusManagementTables.current[selectedTab].map((rfiOrg) => {
      if (rfiOrg.rfiMappingName === rfiOrgObj.rfiMappingName) {
        return rfiOrgObj
      } else return rfiOrg
    })
  }

  const executeRunWorkloadStoredProcedures = () => {
    setShowLoadingSpinner(true)
    setLoadingSpinnerTitle("Executing Run Workload stored procedures...")
    apiCalls
      .post(`WorkloadWorkforceProcedures/RunWorkload?fiscalYear=${activeFiscalCycle?.fiscalYear}`)
      .then(() => {
        setShowSnackbarSuccess(true)
        setSnackbarMessage("Successfully executed Run Workload stored procedures")
      })
      .catch((e) => {
        setShowSnackbarError(true)
        console.log(e?.response)
        if (e?.response?.status === 504) {
          setSnackbarMessage(
            "There was a server timeout when attempting to execute the Run Workforce stored procedures. The stored procedures may have executed successfully, you can check this by contacting your system administrator."
          )
          return
        }
        const errMsg = returnErrorMessageFromAPIError(e)
        if (errMsg) {
          setSnackbarMessage(errMsg)
        } else {
          setSnackbarMessage(
            "There was a server timeout when attempting to execute the Run Workforce stored procedures. The stored procedures may have executed successfully, you can check this by contacting your system administrator."
          )
        }
      })
      .finally(() => setShowLoadingSpinner(false))
  }

  const refreshRFIStatusManagementTables = () => {
    setShowLoadingSpinner(true)
    setLoadingSpinnerTitle("Refreshing RFI Status Management Tables...")
    getRfiRecordCountMapping(activeFiscalCycle).then((recordCountMapping) => {
      setRfiRecordCountMappingContext(recordCountMapping)
      fetchRFIDashboardObjectsFromDB(rfiOrganizationsMapping, allOrgs, activeFiscalCycle, recordCountMapping)
        .then((data) => {
          setRfiDashboardOrgRfiMappingContext(data)
        })
        .finally(() => {
          setShowLoadingSpinner(false)
        })
    })
  }

  const refreshPEOPMSATMOUSACEOrgs = () => {
    if (Object.keys(rfiDashboardOrgRfiMapping).length > 0) {
      checkCanRunWorkloadProcedures()
    }
    setShowLoadingSpinner(false)

    if (Object.keys(rfiOrganizationsMapping).length <= 0) return

    // Create PEO PM RFI objects
    const peoPmSatmoUsaceOrgNames = rfiOrganizationsMapping.peoPm.concat(rfiOrganizationsMapping.satmo).concat(rfiOrganizationsMapping.usace)
    const peoPmSatmoUsaceOrgs = allOrgs.filter((org) => peoPmSatmoUsaceOrgNames.includes(org.name))
    for (const org of peoPmSatmoUsaceOrgs) {
      apiCalls.getById(`Organizations`, org.id).then((orgIdData) => {
        apiCalls.getAll(`${PEO_PM_Utils.UrlApiTypes.rfi}`, {}).then(async (allRFIs) => {
          const foundRFI = allRFIs.find((data) => data.organizationId === org.id && data.fiscalCycleId === activeFiscalCycle.id)
          const associationList =
            orgIdData.name === "USACE"
              ? orgIdData.dsamsPrepActDataAssociationsList.concat(orgIdData.cisilDataDescriptionWorkloadList)
              : orgIdData.dsamsPrepActDataAssociationsList
          if (foundRFI) {
            let tmpStatus = null
            if (foundRFI.status !== SubmitStatus.NOT_GENERATED && foundRFI.status !== SubmitStatus.GENERATED) {
              tmpStatus = foundRFI.status
            } else {
              tmpStatus = associationList.length > 0 ? SubmitStatus.GENERATED : SubmitStatus.NOT_GENERATED
            }
            await apiCalls.put(`${PEO_PM_Utils.UrlApiTypes.rfi}/${foundRFI.id}`, {
              ...foundRFI,
              organizationId: org.id,
              fiscalCycleId: activeFiscalCycle.id,
              poc: associationList.length > 0 ? userObj.name : null,
              status: tmpStatus,
            })
          } else {
            await apiCalls.post(`${PEO_PM_Utils.UrlApiTypes.rfi}`, {
              organizationId: org.id,
              // fiscalCycleId: activeFiscalCycle.id,
              fiscalCycleId: activeFiscalCycle.id,
              poc: associationList.length > 0 ? userObj.name : null,
              status: associationList.length > 0 ? SubmitStatus.GENERATED : SubmitStatus.NOT_GENERATED,
            })
          }
        })
      })
    }
    if (activeFiscalCycle?.id && refreshingForPEOPMChanges.current) {
      refreshRFIStatusManagementTables()
      refreshingForPEOPMChanges.current = false
    }
  }
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  // ** --------------***     [ ~ RENDER ~ ]     ***-------------- **
  if (Object.keys(RFIStatusManagementTables.current).length <= 0) return null
  return (
    <Box
      container
      margin={2}
      spacing={2}
    >
      <TabContext value={selectedTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleChange}
            aria-label="DSAMS/CISIL RFI Tabs"
          >
            <Tab
              label={RFIGroupings.DSAMS_CISIL}
              value={RFIGroupings.DSAMS_CISIL}
            />
            <Tab
              label={RFIGroupings.PEOPM_SATMO_USACE}
              value={RFIGroupings.PEOPM_SATMO_USACE}
            />
            <Tab
              label={RFIGroupings.SPECIAL_ORGS}
              value={RFIGroupings.SPECIAL_ORGS}
            />
            <Tab
              label={RFIGroupings.GFEBS_CEFMS}
              value={RFIGroupings.GFEBS_CEFMS}
            />
            <Tab
              label={RFIGroupings.SUPPLEMENTAL_WORKFORCE}
              value={RFIGroupings.SUPPLEMENTAL_WORKFORCE}
            />
            <Tab
              label={RFIGroupings.SAPBR_TDA}
              value={RFIGroupings.SAPBR_TDA}
            />
          </TabList>
        </Box>
        {/* TODO: Move the tables to their individual tabs 
        
        <TabPanel value={DSAMSTableUtils.Tabs.MILESTONES}>
          {DSAMSTableUtils.Tabs.MILESTONES}
        </TabPanel>
        <TabPanel value={DSAMSTableUtils.Tabs.PREPARING_ACTIVITY_DATA}>
          {DSAMSTableUtils.Tabs.PREPARING_ACTIVITY_DATA}
        </TabPanel>
        <TabPanel value={DSAMSTableUtils.Tabs.CISIL_CCOPES_LINE_DATA}>
          {DSAMSTableUtils.Tabs.CISIL_CCOPES_LINE_DATA}
        </TabPanel> 
        
        */}
      </TabContext>
      <Grid2
        container
        justifyContent="flex-end"
        margin={1}
      >
        <Stack
          direction="row"
          spacing={1}
        >
          <Button
            variant="contained"
            onClick={() => setOpenConfirmationDialog(true)}
            disabled={modifiedCells.length <= 0}
          >
            SAVE CHANGES
          </Button>
          <Button
            variant="contained"
            onClick={() => setOpenRunWorkloadConfirmationDialog(true)}
            disabled={!canRunWorkloadProcedures}
          >
            RUN WORKLOAD
          </Button>
        </Stack>
      </Grid2>
      {RFIStatusManagementTables.current[selectedTab].map((table, idx) => {
        return (
          <div
            key={idx}
            className="--RFI-table-container"
          >
            {/* <RfiCard table={table} /> */}
            <Typography variant="h5">{table?.title ? table.title : ""}</Typography>
            <div
              style={{
                width: "100%",
                height: table?.rows?.length ? table.rows.length * 36 + 95 : 250,
                alignSelf: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <DataGridPremium
                rows={table?.rows ? table.rows : []}
                columns={rfiStatusManagementTableCols}
                density="compact"
                getRowId={() => uuidv4()}
                experimentalFeatures={{ newEditingApi: true }}
                editMode="cell"
                isCellEditable={(params) => canEditCell(params)}
                processRowUpdate={handleRowUpdate}
                onProcessRowUpdateError={(e) => {
                  if (e) console.error(e)
                }}
                initialState={{
                  columns: {
                    columnVisibilityModel: { Id: false },
                  },
                }}
              />
            </div>
          </div>
        )
      })}
      <UploadProgressModal
        open={showLoadingSpinner}
        title={loadingSpinnerTitle}
        loadingProgress={null}
      />
      <OrganizationRFIColumnTableModal
        open={openOrgRFIColTableModal}
        setOpen={setOpenOrgRFIColTableModal}
        selectedTableRows={orgRFIFieldCheckTableRows}
        tableName={selectedTableName}
      />
      <RunWorkforceWorkloadConfirmationModal
        open={openRunWorkloadConfirmationDialog}
        setOpen={setOpenRunWorkloadConfirmationDialog}
        title="Execute Run Workload"
        message="You are about to execute the run workload stored procedures"
        runWorkloadOrWorkforce={executeRunWorkloadStoredProcedures}
      />
      <RFIStatusManagementConfirmationModal
        open={openConfirmationDialog}
        setOpen={setOpenConfirmationDialog}
        modifiedCells={modifiedCells}
        saveRFIChanges={saveRFIChanges}
      />
      <SnackbarMessages
        showSnackbarSuccess={showSnackbarSuccess}
        setShowSnackbarSuccess={setShowSnackbarSuccess}
        showSnackbarError={showSnackbarError}
        setShowSnackbarError={setShowSnackbarError}
        snackbarMessage={snackbarMessage}
      />
      <ToastContainer
        position="top-center"
        style={{ width: 450 }}
        closeOnClick
        autoClose={false}
      />
    </Box>
  )
}

export default RFIStatusManagement
